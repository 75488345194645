import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/dashboard" // Replace with your API base URL

export const getInvoiceCount = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/count`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		if (response.status === 200) {
			const { count, uploaded } = response.data

			return { count, uploaded }
		} else {
			throw new Error("Failed to retrieve invoice count")
		}
	} catch (error) {
		console.error("Error fetching invoice count:", error)
		throw error
	}
}

export const getWeeklyInvoiceSummary = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/weeklysummary`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		if (response.status === 200) {
			return response.data
		} else {
			throw new Error("Failed to retrieve weekly invoice summary")
		}
	} catch (error) {
		console.error("Error fetching weekly invoice summary:", error)
		throw error
	}
}
