import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"

// Import components
import { Loader } from "../../common/loader/loader"
import { PdfComp } from "../../common/pdfcomp/pdfcomp"

// Import Services
import { getInvoiceByUID } from "../../../services/invoice"
import { getFile } from "../../../services/file"

// Import Helpers
import { DataExtraction } from "../../common/popUps/data_extraction_pop/data_extraction"

// Import Styles
import "./transactionEntity.css"
import Header from "../../common/Header/Header"

export const TransactionEntity = () => {
	const { id } = useParams()
	const [data, setData] = useState("")
	const [fileData, setFileData] = useState(false)
	const [showData, setShowData] = useState([])
	const [timeoutErr, setTimeoutErr] = useState(false)

	const getData = async () => {
		try {
			setShowData(true)
			const response = await getFile(id)
			const fileurl = URL.createObjectURL(response)
			setData(fileurl)

			await handdleGetDocumentKeyValuePairs(id)
		} catch (error) {
			console.error("Error getting workflows:", error)
		}
	}

	const handdleGetDocumentKeyValuePairs = async (id) => {
		try {
			const response = await getInvoiceByUID(id)
			setFileData(response)
			if (!response) {
				setTimeoutErr(true)
			}
			return response
		} catch (error) {
			console.error("Error getting document intelligence:", error)
		}
	}
	useEffect(() => {
		getData()
	}, [id])

	// Refactored logic for rendering based on fileData and timeoutErr
	let content
	if (!fileData) {
		if (timeoutErr) {
			content = (
				<Loader
					text={
						"No ha sido posible abrir el archivo de forma correcta, por favor intenta más tarde o contacta al administrador"
					}
					handleError={true}
				/>
			)
		} else {
			content = <Loader text={"Cargando..."} handleError={false} />
		}
	} else {
		content = (
			<div>
				<h2>{data?.name}</h2>
				<div>
					<div>
						<PdfComp pdfFile={data} />
					</div>
				</div>
				<div className="states">
					<div className="state">
						<DataExtraction show={showData} fileData={fileData} />
					</div>
				</div>
			</div>
		)
	}

	return (
		<>
			<Header
				title={
					fileData.invoiceId ? `Factura nº${fileData.invoiceId}` : "Cargando factura..."
				}
			/>
			<div className="h-20" />
			<div>
				<div>{content}</div>
			</div>
		</>
	)
}
