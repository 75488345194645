import PropTypes from "prop-types"
import { Sidebar } from "../common/SideBar/SideBar"

export default function PrivatePageLayout({ children }) {
	return (
		<div className="w-full flex">
			<Sidebar />
			<main className="relative w-full">{children}</main>
		</div>
	)
}

PrivatePageLayout.propTypes = {
	children: PropTypes.node,
}
