import { useNavigate } from "react-router-dom"
import Button from "../../common/Buttons/Button"

export const ResetConfirmation = () => {
    const navigate= useNavigate()
    return(
        <div className="login-register">
            <div className="custom-container">
                <form>
                    <h1>Restablecer contraseña</h1>
                    <p className="invalid-otp">Tu contraseña ha sido restablecida correctamente.</p>
                    <Button onClick={()=>navigate("/login")} text="login"/>
                </form>
            </div>
        </div>
    )
}