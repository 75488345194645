import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/notification"

export async function getAllNotificationsByFilter(filter) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/all`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				...filter.type,
				...filter.status,
				...filter.date,
				...filter.priority,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error reading notifications:", error)
		throw error
	}
}

export async function setAllNotificationsAsCanceled() {
	try {
		const response = await axios.delete(`${VITE_BASE_URL}/clear`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error clearing notifications:", error)
		throw error
	}
}

export async function setNotificationsAsDone(id) {
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}/${id}/done`,
			{},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error changing notification status:", error)
		throw error
	}
}
export async function setNotificationsAsCanceled(id) {
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}/${id}/cancel`,
			{},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error changing notification status:", error)
		throw error
	}
}

export async function addNotification(name, type, description) {
	try {
		const response = await axios.post(
			`${VITE_BASE_URL}`,
			{
				name,
				metadata: {
					type,
					description,
				},
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error adding the notification:", error)
		throw error
	}
}
