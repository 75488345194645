import { useNavigate } from "react-router-dom"
import { createSelector } from "reselect"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { getSalesGroupByCustomer } from "../../../services/sales"
import { TableComponent } from "../../common/tableComponent/tableComponent" // Si tienes un componente para la tabla
import Header from "../../common/Header/Header"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user
)

const frequencyTranslations = {
	monthly: "Mensual",
	annual: "Anual",
	fortnightly: "Quincenal",
}

export default function ProviderBySales() {
	const user = useSelector(selectUser)
	const navigate = useNavigate()
	let formattedDate = "Sin fecha registrada"

	const [preferences, setPreferences] = useState({
		lastPull: "---",
		pullFrequency: "---",
	})
	const [salesInvoices, setSalesInvoices] = useState([])
	const [paginationPreferences, setPaginationPreferences] = useState({
		currentPage: 1,
		limit: 10,
	})
	const [totalPages, setTotalPages] = useState(1)

	const transformSalesInvoices = (salesInvoices) => {
		return salesInvoices.map((invoice) => {
			return {
				"Nombre Cliente": invoice.providerName,
				"Identificación": invoice._id, // Cambia esto según el nombre del campo agrupado
				"Total": invoice.totalAmount,
				"Facturas": invoice.totalInvoices,
			}
		})
	}

	const [searchQuery, setSearchQuery] = useState("") // Para buscar por nombre de proveedor

	const getSales = async () => {
		try {
			const response = await getSalesGroupByCustomer(paginationPreferences, searchQuery)
			if (response && response.salesByCustomer) {
				setSalesInvoices(response.salesByCustomer)
				setTotalPages(Math.ceil(response.total / paginationPreferences.limit))
			} else {
				setSalesInvoices([])
				setTotalPages(1)
			}
		} catch (error) {
			console.error("Error getting sales invoices:", error)
			setSalesInvoices([])
			setTotalPages(1)
		}
	}

	const handleLimitChange = (event) => {
		const newLimit = parseInt(event.target.value, 10)
		setPaginationPreferences((prev) => ({
			...prev,
			limit: newLimit,
			currentPage: 1, // Reiniciar a la primera página al cambiar el límite
		}))
	}

	const handleNextPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.min(prev.currentPage + 1, totalPages),
		}))
	}

	const handleRowClick = (item) => {
		if (item) {
			navigate(`/sales/${item.Identificación}`)
		}
	}

	const handlePreviousPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.max(prev.currentPage - 1, 1),
		}))
	}

	useEffect(() => {
		const lastSalesPull = user.preferences?.lastSalesPull
		let frequency = user.preferences?.salesPullConcurrency || "---"
		if (lastSalesPull) {
			const date = new Date(lastSalesPull)
			if (!isNaN(date.getTime())) {
				formattedDate = date.toLocaleString("es-ES", {
					year: "numeric",
					month: "long",
					day: "numeric",
				})
			}
		}
		const translatedFrequency = frequencyTranslations[frequency] || "---"

		setPreferences({
			lastPull: formattedDate,
			pullFrequency: translatedFrequency,
		})
	}, [user])

	useEffect(() => {
		getSales()
	}, [paginationPreferences])

	return (
		<>
			<Header title={"Ventas por cliente"} />
			<div className="h-20" />
			<div className="px-12 w-full pt-[3px]">
				<div className="flex justify-between m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all">
					<div className="flex flex-col gap-4">
						<div className="flex flex-col gap-4">
							<h2>Última recolección de información: {preferences.lastPull}</h2>
							<h2>
								Frecuencia de recolección de información:{" "}
								{preferences.pullFrequency}
							</h2>
						</div>
						<div className="w-auto">
							<label
								htmlFor="limit"
								className="block text-sm font-medium text-gray-700"
							>
								Límite de registros por página:
							</label>
							<select
								id="limit"
								value={paginationPreferences.limit}
								onChange={handleLimitChange}
								className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
								style={{ width: "120px" }}
							>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={15}>15</option>
								<option value={20}>20</option>
								<option value={25}>25</option>
							</select>
						</div>
						<div className="flex gap-4 items-center">
							<input
								type="text"
								placeholder="Buscar por nombre de proveedor"
								value={searchQuery}
								onChange={(e) => setSearchQuery(e.target.value)}
								className="border border-gray-300 p-2 rounded-md"
							/>
							{/* <label htmlFor="startDate">Desde:</label>
            <input
                type="date"
                id="startDate"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="border border-gray-300 p-2 rounded-md"
            />
            <label htmlFor="endDate">Hasta:</label>
            <input
                type="date"
                id="endDate"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="border border-gray-300 p-2 rounded-md"
            /> */}
							<button
								onClick={getSales}
								className="bg-blue-500 text-white px-4 py-2 rounded-md"
							>
								Buscar
							</button>
						</div>
					</div>
					<button
						onClick={() => {
							window.scrollTo({ top: 0 })
							navigate("/sales/form")
						}}
						className="bg-green-500/20 h-min px-4 py-2 rounded-md border-green-500/25 text-green-700 border hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out shadow-md hover:shadow-lg"
					>
						Subir Factura
					</button>
				</div>

				<div className="mt-4">
					{salesInvoices.length > 0 ? (
						<TableComponent
							data={transformSalesInvoices(salesInvoices)}
							onClick={handleRowClick}
						/>
					) : (
						<p>No hay facturas disponibles.</p>
					)}
				</div>

				<div className="flex justify-center items-center mt-4 gap-4">
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === 1
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handlePreviousPage}
						disabled={paginationPreferences.currentPage === 1}
					>
						Anterior
					</button>
					<span>
						Página {paginationPreferences.currentPage} de {totalPages}
					</span>
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === totalPages
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handleNextPage}
						disabled={paginationPreferences.currentPage === totalPages}
					>
						Siguiente
					</button>
				</div>
			</div>
		</>
	)
}
