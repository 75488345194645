import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	id: "",
	name: "",
	lastname: "",
	email: "",
	newUser: false,
	preferences: {
		inventoryManagement: "",
	},
	role: 0,
}

export const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		login: (state, action) => {
			const { _id, name, email, lastname, newUser, preferences, activeInventory } =
				action.payload

			state.id = _id
			state.name = name
			state.lastname = lastname
			state.email = email
			state.newUser = newUser
			state.preferences = preferences
			state.role = activeInventory
		},
		logout: (state) => {
			state.id = ""
			state.name = ""
			state.lastname = ""
			state.email = ""
			state.newUser = false
			state.preferences = {
				inventoryManagement: "",
			}
			state.role = 0
		},
		editUser: (state, action) => {
			const { name, lastname } = action.payload
			state.name = name ? name : state.name
			state.lastname = lastname ? lastname : state.lastname
		},
	},
})

export const { login, logout, editUser } = userSlice.actions
export default userSlice.reducer
