import React from "react";
import propTypes from "prop-types";
export const YouTubeVideo = ({ url }) => {
  // Reemplaza la URL en src con la URL de tu video específico
  const videoSrc = url;

  return (
    <div className="video-container">
      <iframe
        width="560"
        height="300"
        src="https://www.youtube.com/embed/bijTt6s5sFs?si=_8lwbixCp6EawvsI"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
};
YouTubeVideo.propTypes = {
  url: propTypes.string.isRequired,
};
