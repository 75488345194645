import propTypes from "prop-types"
import { useState } from "react"
import { TbCheckbox, TbPencil, TbPencilCancel } from "react-icons/tb"

export default function InvoiceFormInput({
	name,
	description,
	value,
	handleChange,
	color,
	handleSave,
	required = false,
	number = false,
	disabled = false,
	options = [],
}) {
	const [edit, setEdit] = useState(false)
	const [savedValue, setSavedValue] = useState(value)
	const [currentValue, setCurrentValue] = useState(value)

	const handleInputChange = (e) => {
		setCurrentValue(e.target.value)
		handleChange(e)
	}

	return (
		<div className="form-group">
			<div className="flex justify-between">
				<label htmlFor={name}>{description}</label>
			</div>
			<div className="group relative form-control p-0">
				{options.length > 0 ? (
					<select
						className="w-full p-2"
						id={name}
						name={name}
						value={currentValue}
						onChange={handleInputChange}
						disabled={!edit}
					>
						{options.map((option, index) => (
							<option key={index} value={option}>
								{option}
							</option>
						))}
					</select>
				) : (
					<input
						className="w-full p-2"
						id={name}
						type={number ? "number" : "text"}
						name={name}
						value={disabled ? value : currentValue}
						onChange={handleInputChange}
						disabled={!edit}
					/>
				)}
				{!disabled && (
					<div className="flex gap-2 absolute top-1/2 right-2 -translate-y-1/2 group-hover:opacity-100 opacity-0">
						{edit ? (
							<>
								<button
									type="button"
									onClick={() => {
										setEdit(false)
										handleSave(name, currentValue, required)
										if (required && currentValue.trim() === "") {
											setCurrentValue(savedValue)
											return
										}
										setSavedValue(currentValue)
									}}
								>
									<TbCheckbox
										className="text-green-600 opacity-50 hover:opacity-100"
										size={24}
									/>
								</button>
								<button
									type="button"
									onClick={() => {
										setCurrentValue(savedValue)
										setEdit(false)
									}}
								>
									<TbPencilCancel
										className="text-red-600 opacity-50 hover:opacity-100"
										size={24}
									/>
								</button>
							</>
						) : (
							<button type="button" onClick={() => setEdit(true)}>
								<TbPencil
									className="text-yellow-600 opacity-50 hover:opacity-100"
									size={24}
								/>
							</button>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

InvoiceFormInput.propTypes = {
	name: propTypes.string.isRequired,
	description: propTypes.string.isRequired,
	value: propTypes.string.isRequired,
	handleChange: propTypes.func.isRequired,
	color: propTypes.string.isRequired,
	handleSave: propTypes.func.isRequired,
	required: propTypes.bool,
	number: propTypes.bool,
	disabled: propTypes.bool,
	options: propTypes.arrayOf(propTypes.string), // Agregamos la validación para las opciones
}
