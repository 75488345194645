import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import FormSelectInput from "../../common/inputs/FormSelectInput"
import FormTextInput from "../../common/inputs/FormTextInput"
import { getData } from "../../../services/extraData"
import {
	createProvider,
	deleteProvider,
	getAllProviders,
	proveedorToProvider,
	providerToProveedor,
	putProvider,
	searchProviders,
} from "../../../services/providers"
import { toggleToast } from "../modules/modules"
import { ToastContainer } from "react-toastify"
import Combobox from "../../common/inputs/Combobox"
import Button from "../../common/Buttons/Button"
import { TbSearch } from "react-icons/tb"
import Header from "../../common/Header/Header"

export default function ProvidersCRUD() {
	const location = useLocation()
	const navigate = useNavigate()
	const [providers, setProviders] = useState([])
	const [page, setPage] = useState(1)
	const [filter, setFilter] = useState("")

	const getProviders = async () => {
		let limit
		if (window.innerWidth >= 1536) {
			limit = 27
		} else if (window.innerWidth >= 1280) {
			limit = 14
		} else {
			limit = 8
		}
		let providers
		if (filter !== "") {
			providers = await searchProviders({ limit, page, search: filter })
		} else {
			providers = await getAllProviders({ limit, page })
		}
		setProviders(providers.map(providerToProveedor).reverse())
	}

	useEffect(() => {
		getProviders()
	}, [page, filter])

	const defaultProvider = {
		tipoDoc: "CC",
		tipo: "Proveedor",
		tipoPersona: "Empresa",
		addresses: [],
		phones: [],
		contacts: [{ firstName: "", lastName: "", email: "" }],
		fiscalResponsibility: [],
		PUC: [],
		concepto: { code: "", description: "" },
		actividad: { code: "", description: "" },
		defaultPUC: null,
	}

	const [selectedProvider, setSelectedProvider] = useState(defaultProvider)
	const [emailErrors] = useState({})
	const [fieldErrors, setFieldErrors] = useState({})
	const [activities, setActivities] = useState([])
	const [concepts, setConcepts] = useState([])
	const [newPuc, setNewPuc] = useState(null) // New PUC code state
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

	const validateFields = () => {
		const errors = {}
		if (selectedProvider.tipoDoc === "CC" && selectedProvider.tipoPersona === "Empresa")
			errors.tipoDoc = "El tipo de documento no puede ser CC para una empresa."
		if (!selectedProvider.numDoc || "") errors.numDoc = "El número de documento es obligatorio."
		if (!selectedProvider.tipo) errors.tipo = "El tipo es obligatorio."
		if (!selectedProvider.nombre) errors.nombre = "El nombre es obligatorio."
		if (!selectedProvider.tipoPersona) errors.tipoPersona = "El tipo de persona es obligatorio."
		selectedProvider.contacts.forEach((contact, index) => {
			if (contact.email && !emailRegex.test(contact.email)) {
				errors[`email-${index}`] = "Email inválido."
			}
		})
		setFieldErrors(errors)
		return Object.keys(errors).length === 0
	}

	const cleanErrors = () => {
		setFieldErrors({})
	}

	const getConceptsAndActivities = async () => {
		try {
			const activity_concepts = await getData()
			setActivities(activity_concepts.Activity)
			setConcepts(activity_concepts.concepts)
		} catch (error) {
			console.error("Error getting concepts and activities:", error)
		}
	}

	const create = async () => {
		if (validateFields()) {
			const newProvider = await createProvider({
				...proveedorToProvider(selectedProvider),
				_id: null,
			})
			setProviders((prev) => [providerToProveedor(newProvider), ...prev.slice(0, -1)])
			toggleToast("Proveedor creado")
			selectProvider(defaultProvider)
			setNewPuc(null)
		}
	}

	const edit = async () => {
		if (validateFields()) {
			let newProvider = await putProvider(proveedorToProvider(selectedProvider))
			newProvider = providerToProveedor(newProvider)
			setProviders((prev) =>
				prev.map((provider) => (provider.id === newProvider.id ? newProvider : provider))
			)
			toggleToast("Proveedor editado")
			selectProvider(defaultProvider)
			setNewPuc(null)
		}
	}

	const remove = async () => {
		await deleteProvider(proveedorToProvider(selectedProvider))
		setProviders((prev) => prev.filter(({ id }) => id !== selectedProvider.id))
		selectProvider(defaultProvider)
		toggleToast("Proveedor eliminado")
		setNewPuc(null)
	}

	const selectProvider = (provider) => {
		setSelectedProvider({
			...provider,
			contacts: provider.contacts || [{ firstName: "", lastName: "", email: "" }],
			addresses: provider.addresses || [],
			phones: provider.phones || [],
			fiscalResponsibility: provider.fiscalResponsibility || [],
			PUC: provider.PUC || [],
			actividad: provider.activity || { code: "", description: "" },
			concepto: provider.concepto || { code: "", description: "" },
		})
		cleanErrors()
	}

	const handleContactChange = (index, e) => {
		const { name, value } = e.target
		const fieldName = name.split("-")[0]

		const updatedContacts = selectedProvider.contacts.map((contact, i) =>
			i === index ? { ...contact, [fieldName]: value } : contact
		)

		setSelectedProvider((prevState) => ({
			...prevState,
			contacts: updatedContacts,
		}))
	}

	const addContact = () => {
		setSelectedProvider((prevState) => ({
			...prevState,
			contacts: [...prevState.contacts, { firstName: "", lastName: "", email: "" }],
		}))
	}

	const removeContact = (index) => {
		if (selectedProvider.contacts.length > 1) {
			const updatedContacts = selectedProvider.contacts.filter((_, i) => i !== index)
			setSelectedProvider((prevState) => ({
				...prevState,
				contacts: updatedContacts,
			}))
		}
	}

	const addPuc = () => {
		if (newPuc) {
			setSelectedProvider((prevState) => ({
				...prevState,
				PUC: [{ code: newPuc }, ...prevState.PUC.filter((puc) => puc.code !== newPuc)],
			}))
		}
	}

	const setDefaultPuc = () => {
		if (newPuc) {
			setSelectedProvider((prevState) => ({
				...prevState,
				defaultPUC: { code: newPuc },
			}))
		}
		addPuc()
	}

	const removePuc = () => {
		const updatedPucList = selectedProvider.PUC.filter((puc) => puc.code !== newPuc)
		setSelectedProvider((prevState) => ({
			...prevState,
			PUC: updatedPucList,
			defaultPUC: newPuc === selectedProvider.defaultPUC?.code ? null : prevState.defaultPUC,
		}))
	}

	useEffect(() => {
		getConceptsAndActivities()
		if (location.state?.provider) {
			const incomingProvider = location.state.provider
			console.log(incomingProvider)
			const transformedProvider = {
				tipoDoc: "NIT",
				nombre: incomingProvider.name,
				numDoc: incomingProvider.id,
				tipo: "Proveedor",
				tipoPersona: "Empresa",
				addresses: [],
				phones: [],
				contacts: [
					{
						firstName: "",
						lastName: "",
						email: incomingProvider.vendorEmail,
					},
				],
				fiscalResponsibility: [],
				PUC: incomingProvider.PUC || [],
				concepto: { code: "", description: "" },
				actividad: { code: "", description: "" },
			}
			setSelectedProvider(transformedProvider)
			navigate(location.pathname, { replace: true })
		}
	}, [location.state, navigate])

	return (
		<>
			<Header title={"Proveedores"} />
			<div className="h-20" />
			<main className="px-12 w-full pt-[3px]">
				<section>
					<div className="flex gap-4">
						<div className="m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none w-1/2 2xl:w-1/3 flex flex-col gap-6 h-min">
							<h2>Información del proveedor</h2>
							<div className="flex flex-col xl:grid xl:grid-cols-2 gap-x-8 gap-y-6">
								<div className="flex flex-col lg:col-span-2">
									<FormSelectInput
										id="tipoDoc"
										label="Tipo de documento"
										value={selectedProvider.tipoDoc}
										className="min-w-12 xl:min-w-24"
										options={["CC", "NIT"]}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												tipoDoc: e.target.value,
											})
										}
									/>
									{fieldErrors.tipoDoc && (
										<span className="text-red-500">{fieldErrors.tipoDoc}</span>
									)}
								</div>
								<div className="flex flex-col lg:col-span-2">
									<FormTextInput
										id="numDocumento"
										label="Documento"
										className="w-full"
										value={selectedProvider.numDoc || ""}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												numDoc: e.target.value,
											})
										}
									/>
									{fieldErrors.numDoc && (
										<span className="text-red-500">{fieldErrors.numDoc}</span>
									)}
								</div>
								<div className="flex flex-col">
									<FormSelectInput
										id="tipo"
										label="Tipo"
										value={selectedProvider.tipo}
										className=""
										options={["Cliente", "Proveedor", "Otro"]}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												tipo: e.target.value,
											})
										}
									/>
									{fieldErrors.tipo && (
										<span className="text-red-500">{fieldErrors.tipo}</span>
									)}
								</div>
								<div className="flex flex-col">
									<FormTextInput
										id="nombre"
										label="Nombre"
										className=""
										value={selectedProvider.nombre || ""}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												nombre: e.target.value,
											})
										}
									/>
									{fieldErrors.nombre && (
										<span className="text-red-500">{fieldErrors.nombre}</span>
									)}
								</div>
								<div className="flex flex-col">
									<FormSelectInput
										id="tipoPersona"
										label="Tipo persona"
										value={selectedProvider.tipoPersona}
										className=""
										options={["Empresa", "Persona"]}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												tipoPersona: e.target.value,
											})
										}
									/>
									{fieldErrors.tipoPersona && (
										<span className="text-red-500">
											{fieldErrors.tipoPersona}
										</span>
									)}
								</div>
								<div className="flex flex-col lg:col-span-2">
									<FormSelectInput
										id="concepto"
										label="Concepto"
										value={selectedProvider.concepto.description}
										className=""
										options={[
											...concepts.map((concept) => concept.description),
										]}
										onChange={(e) => {
											setSelectedProvider({
												...selectedProvider,
												concepto: {
													code:
														concepts.find(
															(concept) =>
																concept.description ===
																e.target.value
														)?.id || "",
													description: e.target.value,
												},
											})
										}}
									/>
								</div>
								<div className="flex flex-col lg:col-span-2">
									<FormSelectInput
										id="actividad"
										label="Actividad"
										value={selectedProvider.actividad.description}
										options={[
											...activities.map((activity) => activity.description),
										]}
										onChange={(e) =>
											setSelectedProvider({
												...selectedProvider,
												actividad: {
													code:
														activities.find(
															(activity) =>
																activity.description ===
																e.target.value
														)?.code || "",
													description: e.target.value,
												},
											})
										}
									/>
								</div>
								<div className="flex flex-col lg:col-span-2">
									<h3 className="pb-4">
										Códigos PUC{" "}
										{selectedProvider.defaultPUC && (
											<span className="text-gray-500">
												- Predeterminado: {selectedProvider.defaultPUC.code}
											</span>
										)}
									</h3>
									<div className="mt-2 flex gap-2 justify-between items-end w-full">
										<button
											className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-xs text-center px-1 w-min bg-gray-500"
											onClick={setDefaultPuc}
											name="pucButton"
										>
											Marcar como Predeterminado
										</button>
										<Combobox
											className="w-full"
											id="combobox"
											label="Código"
											onSelect={(val, create) => {
												if (val === "") return
												setNewPuc(val)
												if (create) {
													setSelectedProvider((prevState) => ({
														...prevState,
														PUC: [
															{ code: val },
															...prevState.PUC.filter(
																(puc) => puc.code !== val
															),
														],
													}))
												}
											}}
											values={selectedProvider.PUC.map((puc) => puc.code)}
											number
										/>
										<div className="flex gap-2">
											<button
												className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-xs text-center px-1 w-14 h-9 bg-green-600"
												onClick={addPuc}
												name="pucButton"
											>
												Agregar
											</button>
											<button
												className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-xs text-center px-1 w-14 h-9 bg-red-500"
												onClick={removePuc}
												name="pucButton"
											>
												Borrar
											</button>
										</div>
									</div>
								</div>
								<div className="col-span-2 flex flex-col gap-4 pt-4">
									<h2>Datos de contacto</h2>
									{selectedProvider.contacts.map((contact, index) => (
										<div key={index} className="flex flex-col gap-4">
											<FormTextInput
												id={`firstName-${index}`}
												name={`firstName-${index}`}
												label="Nombres"
												className=""
												value={contact.firstName}
												onChange={(e) => handleContactChange(index, e)}
											/>
											{fieldErrors[`firstName-${index}`] && (
												<span className="text-red-500">
													{fieldErrors[`firstName-${index}`]}
												</span>
											)}
											<FormTextInput
												id={`lastName-${index}`}
												name={`lastName-${index}`}
												label="Apellidos"
												className=""
												value={contact.lastName}
												onChange={(e) => handleContactChange(index, e)}
											/>
											{fieldErrors[`lastName-${index}`] && (
												<span className="text-red-500">
													{fieldErrors[`lastName-${index}`]}
												</span>
											)}
											<FormTextInput
												id={`email-${index}`}
												name={`email-${index}`}
												label="Email"
												className=""
												value={contact.email}
												onChange={(e) => handleContactChange(index, e)}
											/>
											{fieldErrors[`email-${index}`] && (
												<span className="text-red-500">
													{fieldErrors[`email-${index}`]}
												</span>
											)}
											{emailErrors[index] && (
												<span className="text-red-500">
													{emailErrors[index]}
												</span>
											)}
											{selectedProvider.contacts.length > 1 && (
												<button
													className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-red-500"
													onClick={() => removeContact(index)}
												>
													Borrar Contacto
												</button>
											)}
										</div>
									))}
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-green-600"
										onClick={addContact}
									>
										Añadir Contacto
									</button>
								</div>
								<div className="col-span-2 flex justify-center gap-4">
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-green-600"
										onClick={create}
									>
										Crear
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-yellow-400"
										onClick={edit}
									>
										Guardar
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-red-500"
										onClick={remove}
									>
										Borrar
									</button>
									<button
										className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center w-20 bg-gray-500"
										onClick={() => selectProvider(defaultProvider)}
									>
										Limpiar
									</button>
								</div>
							</div>
						</div>
						<div className="relative w-1/2 2xl:w-2/3 m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all outline-none max-h-full">
							<div className="flex flex-col w-full gap-2">
								<h2>Lista de proveedores</h2>
								<div className="relative">
									<TbSearch className="absolute top-1/2 -translate-y-1/2 left-1" />
									<input
										type="text"
										className="pl-5 border border-gray-600 rounded-md h-full w-full"
										onChange={(e) => {
											setFilter(e.target.value)
											setPage(1)
										}}
									/>
								</div>
								<ul className="grid xl:grid-cols-2 2xl:grid-cols-3 gap-2 overflow-y-scroll mb-10">
									{providers.length === 0 && (
										<span className="col-span-full text-center">
											No tiene proveedores en el momento
										</span>
									)}
									{providers.map((provider) => (
										<li key={provider.numDoc} className="w-full">
											<button
												className="flex flex-col 2xl:flex-row gap-2 p-2 rounded-md border-2 border-gray-200 shadow-sm items-center justify-between text-start w-full"
												onClick={() => selectProvider(provider)}
											>
												<div className="flex flex-col">
													<span className="text-lg text-ellipsis overflow-hidden line-clamp-1 text-center 2xl:text-left">
														{provider.nombre}
													</span>
													<div className="flex gap-1 text-sm text-gray-500 self-center 2xl:self-start">
														<span>{provider.tipoDoc}</span>
														<span>{provider.numDoc}</span>
													</div>
												</div>
												<div className="flex 2xl:flex-col 2xl:gap-2 items-end justify-around 2xl:justify-normal w-full 2xl:w-min">
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{provider.tipo}
													</span>
													<span className="rounded-full bg-green-100 px-2 text-xs w-fit">
														{provider.tipoPersona}
													</span>
												</div>
											</button>
										</li>
									))}
								</ul>
								<div className="flex justify-self-end self-center gap-4 pt-2 absolute bottom-3">
									<Button
										text="Anterior"
										onClick={() => setPage(page - 1)}
										disabled={page <= 1}
									/>
									<button className="bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer">
										{page}
									</button>
									<Button text="Siguiente" onClick={() => setPage(page + 1)} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</main>
			<ToastContainer />
		</>
	)
}
