import { useRef } from "react"
import { useEffect } from "react"
import PropTypes from "prop-types"
import { TbCalendar, TbCategoryPlus, TbCheckbox, TbWeight } from "react-icons/tb"
import { cn } from "../../../../utils/twMerge"
import { useState } from "react"

export default function NotificationsFilterPopUp({ show, setShow, filters, setFilters }) {
	const filterDialogRef = useRef(null)
	const [focused, setFocused] = useState(0)
	const [filter, setFilter] = useState(null)

	useEffect(() => {
		filterDialogRef.current.classList.add("hidden")
		const handleClick = (e) => {
			if (filterDialogRef.current && !filterDialogRef.current.contains(e.target)) {
				if (e.target.id !== "filterDialogBtn") {
					setShow(false)
				}
				setFocused(0)
				setFilter(null)
			}
		}
		document.removeEventListener("mouseup", handleClick)
		document.addEventListener("mouseup", handleClick)
	}, [])
	return (
		<div
			className={cn(
				"flex flex-col w-40 absolute bg-white shadow border border-neutral-300 rounded-lg top-9 left-1/2 -translate-x-1/2 p-1",
				{ hidden: !show }
			)}
			ref={filterDialogRef}
		>
			{!filter && (
				<>
					<button
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 0,
						})}
						onMouseOver={() => setFocused(0)}
						onClick={() => setFilter("type")}
					>
						<TbCategoryPlus className="h-5 w-5" />
						Tipo
					</button>
					<button
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 1,
						})}
						onMouseOver={() => setFocused(1)}
						onClick={() => setFilter("status")}
					>
						<TbCheckbox className="h-5 w-5" />
						Estado
					</button>
					<button
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 2,
						})}
						onMouseOver={() => setFocused(2)}
						onClick={() => setFilter("date")}
					>
						<TbCalendar className="h-5 w-5" />
						Fecha
					</button>
					<button
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 3,
						})}
						onMouseOver={() => setFocused(3)}
						onClick={() => setFilter("priority")}
					>
						<TbWeight className="h-5 w-5" />
						Peso
					</button>
				</>
			)}
			{filter === "type" && (
				<>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 0,
						})}
						onMouseOver={() => setFocused(0)}
					>
						<input
							type="checkbox"
							checked={filters.type.provider}
							onChange={(e) => {
								setFilters({
									...filters,
									type: { ...filters.type, provider: e.target.checked },
								})
							}}
						/>
						Proveedores
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 1,
						})}
						onMouseOver={() => setFocused(1)}
					>
						<input
							type="checkbox"
							checked={filters.type.inventory}
							onChange={(e) => {
								setFilters({
									...filters,
									type: { ...filters.type, inventory: e.target.checked },
								})
							}}
						/>
						Inventario
					</div>
				</>
			)}
			{filter === "status" && (
				<>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 0,
						})}
						onMouseOver={() => setFocused(0)}
					>
						<input
							type="checkbox"
							checked={filters.status.pending}
							onChange={(e) => {
								setFilters({
									...filters,
									status: { ...filters.status, pending: e.target.checked },
								})
							}}
						/>
						Pendiente
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 1,
						})}
						onMouseOver={() => setFocused(1)}
					>
						<input
							type="checkbox"
							checked={filters.status.done}
							onChange={(e) => {
								setFilters({
									...filters,
									status: { ...filters.status, done: e.target.checked },
								})
							}}
						/>
						Completado
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 2,
						})}
						onMouseOver={() => setFocused(2)}
					>
						<input
							type="checkbox"
							checked={filters.status.dismissed}
							onChange={(e) => {
								setFilters({
									...filters,
									status: { ...filters.status, dismissed: e.target.checked },
								})
							}}
						/>
						Borrado
					</div>
				</>
			)}
			{filter === "date" && (
				<>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 flex-col items-center", {
							"bg-neutral-100": focused === 0,
						})}
						onMouseOver={() => setFocused(0)}
					>
						Desde
						<input
							type="date"
							className="bg-transparent"
							value={filters.date.from}
							onChange={(e) => {
								setFilters({
									...filters,
									date: { ...filters.date, from: e.target.value },
								})
							}}
						/>
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 flex-col items-center", {
							"bg-neutral-100": focused === 1,
						})}
						onMouseOver={() => setFocused(1)}
					>
						Hasta
						<input
							type="date"
							className="bg-transparent"
							value={filters.date.to}
							onChange={(e) => {
								setFilters({
									...filters,
									date: { ...filters.date, to: e.target.value },
								})
							}}
						/>
					</div>
				</>
			)}
			{filter === "priority" && (
				<>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 0,
						})}
						onMouseOver={() => setFocused(0)}
					>
						<input
							type="checkbox"
							checked={filters.priority.info}
							onChange={(e) => {
								setFilters({
									...filters,
									priority: { ...filters.priority, info: e.target.checked },
								})
							}}
						/>
						Información
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 1,
						})}
						onMouseOver={() => setFocused(1)}
					>
						<input
							type="checkbox"
							checked={filters.priority.warning}
							onChange={(e) => {
								setFilters({
									...filters,
									priority: { ...filters.priority, warning: e.target.checked },
								})
							}}
						/>
						Advertencia
					</div>
					<div
						className={cn("rounded-md px-2 py-1 flex gap-2 items-center", {
							"bg-neutral-100": focused === 2,
						})}
						onMouseOver={() => setFocused(2)}
					>
						<input
							type="checkbox"
							checked={filters.priority.error}
							onChange={(e) => {
								setFilters({
									...filters,
									priority: { ...filters.priority, error: e.target.checked },
								})
							}}
						/>
						Error
					</div>
				</>
			)}
		</div>
	)
}

NotificationsFilterPopUp.propTypes = {
	show: PropTypes.bool.isRequired,
	setShow: PropTypes.func.isRequired,
	filters: PropTypes.object.isRequired,
	setFilters: PropTypes.func.isRequired,
}
