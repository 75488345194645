// Import Necesary Libraries
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react"

// Import Components
import Button from "../../common/Buttons/Button"

// Import Services
import { verifyUser } from "../../../services/user"

const AccountVerification = () => {
	const [verificationText, setVerificationText] = useState("Verifying Account...")
	const [error, setError] = useState(false)

	const firstLoad = useRef(true)

	const { token } = useParams()

	const navigate = useNavigate()

	const verifyAccount = async () => {
		try {
			const response = await verifyUser(token)
			return response
		} catch (error) {
			console.error("Error verifying account:", error)
			return error.response.status
		}
	}

	useEffect(() => {
		if (firstLoad.current) {
			firstLoad.current = false
			Promise.all([verifyAccount()])
				.then((res) => {
					if (res[0].status === 201) {
						setVerificationText("Cuenta verificada, Porfavor ve a iniciar sesión")
					} else {
						setVerificationText(
							"Verificación de cuenta fallida, Porfavor intenta más tarde"
						)
						setError(true)
					}
				})
				.catch((error) => {
					console.error("Error verifying account:", error)
					setVerificationText(
						"Verificación de cuenta fallida, Porfavor intenta más tarde."
					)
					setError(true)
				})
		}
	}, [])

	return (
		<div className="login-register">
			<div className="custom-container">
				<form>
					<h1>{verificationText}</h1>
					<Button
						onClick={() => navigate("/login")}
						text="Iniciar sesión"
						disabled={error}
					/>
				</form>
			</div>
		</div>
	)
}

export default AccountVerification
