import { useRef, useState } from "react"

// Import components
import Button from "../../Buttons/Button"

// Import services
import {
	createIntegration,
	authorizeIntegration,
	updateIntegration,
} from "../../../../services/integrations"

// Import the toast function from the react-toastify module
import { toast } from "react-toastify"
import { YouTubeVideo } from "../../VideoComp/videoYT"

// Import prop types
import propTypes from "prop-types"

export const RequestConetionsPop = ({ show, onClose, apiKey, apiSecret }) => {
	// State for API keys
	const [apiKeys, setApiKeys] = useState({
		apiKey: apiKey || "",
		apiSecret: apiSecret || "",
	})

	// State for ERP
	const [selectedERP, setSelectedERP] = useState("")

	// State for the clicked module
	const [clickedModule, setClickedModule] = useState({
		Siigo: false,
		onPremise: false,
	})

	// State for integration type selection (basic, medium, full)
	const [integrationType, setIntegrationType] = useState("basic")

	// Create a ref for the popup content
	const popupContentRef = useRef(null)

	// Loading state to disable the button when clicked
	const [loading, setLoading] = useState(false)

	// Handle backdrop click to close the popup
	const handleBackdropClick = (e) => {
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			onClose()
		}
	}

	// Function to handle creation of integration
	const createIntegrations = async () => {
		try {
			const integration = {
				name: clickedModule.Siigo ? "Siigo" : selectedERP,
				onPremise: clickedModule.onPremise ? true : false,
				apiKey: clickedModule.Siigo ? apiKeys.apiKey : null,
				apiSecret: clickedModule.Siigo ? apiKeys.apiSecret : null,
			}
			console.log("Integration:", integration)
			await createIntegration(integration)
		} catch (error) {
			console.error("Error creating integration:", error)
		}
	}

	// Function to handle updating of integration
	const updateIntegrations = async () => {
		try {
			const integration = {
				name: "Siigo",
				apiKey: apiKeys.apiKey,
				apiSecret: apiKeys.apiSecret,
			}
			await updateIntegration(integration)
		} catch (error) {
			console.error("Error updating integration:", error)
		}
	}

	// Handle form submission
	const handleSubmit = async (e) => {
		e.preventDefault()
		setLoading(true) // Disable button immediately after clicking

		try {
			if (clickedModule.Siigo && (!apiKeys.apiKey || !apiKeys.apiSecret)) {
				const authresponse = await authorizeIntegration({
					apiKey: apiKeys.apiKey,
					apiSecret: apiKeys.apiSecret,
				})
				if (authresponse.status === 200) {
					toggleToast("Conexión exitosa")
					toggleToast(
						"Estamos recopilando tu información, en unos minutos estará disponible!"
					)
					if (!apiKey || !apiSecret) {
						await createIntegrations()
					}
					await updateIntegrations()
					onClose()
				} else {
					toggleToast("Usuario o contraseña incorrectos")
				}
			} else {
				await createIntegrations()
				toggleToast("Conexión exitosa")
				onClose()
			}
		} catch (error) {
			console.error("Error during submission:", error)
		} finally {
			setLoading(false) // Re-enable button after process completes
		}
	}

	// Disable button if API key or secret is missing, or if the form is in loading state
	const isButtonDisabled = !apiKeys.apiKey || !apiKeys.apiSecret || loading

	const onPremiseButtonDisabled = !selectedERP || loading

	// Function to show a toast notification
	const toggleToast = (text) => {
		toast(text, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: "light",
		})
	}

	// Handle module click
	const handleModuleClick = (module) => {
		setClickedModule({ [module]: !clickedModule[module] })
	}

	// Handle integration type button click
	const handleTypeClick = (type) => {
		setIntegrationType(type)
	}

	if (!show) {
		return null
	}

	return (
		<div className="popup-backdrop" onClick={handleBackdropClick}>
			<div className="popup-content-no-click" ref={popupContentRef}>
				<h3>ERP y Software contable</h3>
				<div className="flex gap-8 flex-col items-center justify-center w-full p-4">
					<div
						className="w-full min-w-[700px] p-4 rounded-md flex flex-row items-center justify-between shadow-md cursor-pointer hover:-translate-y-1 hover:shadow-lg transition-transform duration-200"
						onClick={() => handleModuleClick("Siigo")}
					>
						<picture>
							<img
								src="./imgs/siigo.png"
								alt="Siigo"
								className="w-full max-w-[100px] h-auto"
							/>
						</picture>
						{clickedModule.Siigo ? (
							<p className="transform rotate-90">{">"}</p>
						) : (
							<p>{">"}</p>
						)}
					</div>
					<div
						className="w-full min-w-[700px] p-4 rounded-md flex flex-row items-center justify-between shadow-md cursor-pointer hover:-translate-y-1 hover:shadow-lg transition-transform duration-200"
						onClick={() => handleModuleClick("onPremise")}
					>
						<picture>
							<img
								src="./imgs/onPremise.png"
								alt="On Premise"
								className="w-full max-w-[100px] h-auto"
							/>
						</picture>
						{clickedModule.WO ? (
							<p className="transform rotate-90">{">"}</p>
						) : (
							<p>{">"}</p>
						)}
					</div>
				</div>
				{clickedModule.onPremise && (
					<div className="flex flex-col items-center justify-center gap-8 p-6 w-full bg-gray-100 rounded-md shadow-md">
						{/* Integration Type Buttons */}
						<div className="text-xl font-bold text-center text-gray-800 mb-4">ERP</div>

						<form
							onSubmit={handleSubmit}
							className="flex flex-col items-center justify-center gap-6 w-full p-8 bg-white rounded-lg shadow-sm"
						>
							<div className="flex flex-col w-[500px] gap-2">
								<label htmlFor="erpSelection" className="text-lg text-gray-800">
									Seleccione el ERP onPremise:
								</label>
								<select
									id="erpSelection"
									name="erpSelection"
									value={selectedERP}
									onChange={(e) => setSelectedERP(e.target.value)}
									required
									className="w-full text-lg p-3 border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:shadow-lg outline-none transition-all duration-200"
								>
									<option value="" disabled>
										-- Seleccione un ERP --
									</option>
									<option value="WorldOffice">World Office</option>
									<option value="Siesa">Siesa</option>
								</select>
							</div>

							<Button
								text={loading ? "Conectando..." : "Conectar"} // Change button text during loading
								onClick={handleSubmit}
								disabled={onPremiseButtonDisabled}
							/>
						</form>
					</div>
				)}

				{clickedModule.Siigo && (
					<div className="flex flex-col items-center justify-center gap-8 p-6 w-full bg-gray-100 rounded-md shadow-md">
						<div className="flex flex-col items-center justify-center p-4 rounded-md text-sm">
							<YouTubeVideo url="https://youtu.be/bijTt6s5sFs?si=M9sZufxQHSkMPW38" />
							<p>
								En el video encontrarás el paso a paso para obtener tus llaves de
								conexión
							</p>
						</div>

						{/* Integration Type Buttons */}
						<div className="text-xl font-bold text-center text-gray-800 mb-4">
							Tipo de Integración
						</div>
						<div className="flex flex-row items-center justify-evenly w-full gap-4">
							<button
								type="button"
								className={`py-2 px-4 border border-gray-300 rounded-md bg-white cursor-pointer transition-colors duration-300 ${integrationType === "basic" ? "bg-green-500 text-white" : ""}`}
								onClick={() => handleTypeClick("basic")}
							>
								Básica
							</button>
							<button
								type="button"
								className={`py-2 px-4 border border-gray-300 rounded-md bg-white cursor-pointer transition-colors duration-300 ${integrationType === "medium" ? "bg-green-500 text-white" : ""}`}
								onClick={() => handleTypeClick("medium")}
							>
								Media
							</button>
							<button
								type="button"
								className={`py-2 px-4 border border-gray-300 rounded-md bg-white cursor-pointer transition-colors duration-300 ${integrationType === "full" ? "bg-green-500 text-white" : ""}`}
								onClick={() => handleTypeClick("full")}
							>
								Completa
							</button>
						</div>

						<form
							onSubmit={handleSubmit}
							className="flex flex-col items-center justify-center gap-6 w-full p-8 bg-white rounded-lg shadow-sm"
						>
							<div className="flex flex-col w-[500px] gap-2">
								<label htmlFor="apiKey" className="text-lg text-gray-800">
									Usuario API:
								</label>
								<input
									type="text"
									id="apiKey"
									name="apiKey"
									value={apiKeys.apiKey}
									onChange={(e) =>
										setApiKeys({ ...apiKeys, apiKey: e.target.value })
									}
									required
									className="w-full text-lg p-3 border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:shadow-lg outline-none transition-all duration-200"
								/>
							</div>

							<div className="flex flex-col w-[500px] gap-2">
								<label htmlFor="apiSecret" className="text-lg text-gray-800">
									API Secret:
								</label>
								<input
									type="text"
									id="apiSecret"
									name="apiSecret"
									value={apiKeys.apiSecret}
									onChange={(e) =>
										setApiKeys({ ...apiKeys, apiSecret: e.target.value })
									}
									required
									className="w-full text-lg p-3 border border-gray-300 rounded-md shadow-sm focus:border-green-500 focus:shadow-lg outline-none transition-all duration-200"
								/>
							</div>

							<Button
								text={loading ? "Conectando..." : "Conectar"} // Change button text during loading
								onClick={handleSubmit}
								disabled={isButtonDisabled}
							/>
						</form>
					</div>
				)}
			</div>
		</div>
	)
}

RequestConetionsPop.propTypes = {
	show: propTypes.bool.isRequired,
	onClose: propTypes.func.isRequired,
	apiKey: propTypes.string,
	apiSecret: propTypes.string,
}
