import { useEffect } from "react"
import { useRef } from "react"
import { useState } from "react"
import { ToastContainer } from "react-toastify"
import { getModules } from "../../../services/modules"
import InvoiceModule from "./InvoiceModule"
import Header from "../../common/Header/Header"

export default function Invoices() {
	const [modules, setModules] = useState([])

	const getInvoicesModules = async () => {
		try {
			const response = await getModules()
			setModules(response)
		} catch (error) {
			console.error("Error getting workspaces:", error)
		}
	}

	const firstLoad = useRef(true)

	useEffect(() => {
		if (firstLoad.current) {
			getInvoicesModules()
			firstLoad.current = false
		}
	}, [])

	return (
		<>
			<Header title={"Facturas no enviadas"} />
			<div className="h-20" />
			<div>
				<div className="workflows w-full p-8 flex flex-col">
					{modules.length === 0 ? (
						<p>No se encontró información consolidada</p>
					) : (
						<>
							{modules.map(({ _id, name }) => (
								<InvoiceModule key={_id} id={_id} name={name} />
							))}
						</>
					)}
				</div>
			</div>
			<ToastContainer />
		</>
	)
}
