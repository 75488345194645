import propTypes from "prop-types"
import "./Button.css"
import { cn } from "../../../utils/twMerge"

const Button = ({ text, onClick, disabled, svg, type = "button", className }) => {
	return (
		<button
			type={type}
			onClick={onClick}
			disabled={disabled}
			className={cn("reusable-btn bg-green-600", className)}
		>
			{svg}
			<p>{text}</p>
		</button>
	)
}

Button.propTypes = {
	text: propTypes.string,
	onClick: propTypes.func,
	disabled: propTypes.bool,
	svg: propTypes.oneOfType([propTypes.string, propTypes.object]),
	type: propTypes.string,
	className: propTypes.string,
}

export default Button
