import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { loginUser } from "../../../services/user"
import Cookies from "js-cookie"
import { PaymentInstructionsPop } from "../../common/popUps/payment_instructionsPopUp/payment_instructions"
import { TbEye, TbEyeClosed, TbClock, TbRouteX } from "react-icons/tb"
import { HiSparkles } from "react-icons/hi2"
import logo from "/logo.svg"
import "./style.css"

export const Login = () => {
	const [loginForm, setLoginForm] = useState({
		email: "",
		password: "",
	})
	const [formError, setFormError] = useState(null)
	const [resendemail, setResendemail] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [showPaymentInstructionsPop, setShowPaymentInstructionsPop] = useState(false)
	const [index, setIndex] = useState(0)

	useEffect(() => {
		const interval = setInterval(() => {
			setIndex((prevIndex) => (prevIndex === 2 ? 0 : prevIndex + 1))
		}, 8000)
		return () => clearInterval(interval)
	}, [])

	const handleLoginFormChange = (event) => {
		setLoginForm({
			...loginForm,
			[event.target.name]: event.target.value,
		})
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		// Verificar campos vacíos
		for (const key in loginForm) {
			if (loginForm[key].trim() === "") {
				setFormError("No dejes espacios en blanco")
				return // Detener la validación si se encuentra un campo vacío
			}
		}
		handleLogin()
		setFormError(null)
	}

	const handleLogin = async () => {
		try {
			const loginData = await loginUser(loginForm)
			if (loginData.status === 200) {
				const { token } = loginData.data
				Cookies.set("token", token, { expires: 7, secure: true })
				window.location.href = "/"
			}
		} catch (error) {
			if (error.response.status === 401) {
				setFormError("El usuario no ha sido autenticado")
				setResendemail(true)
			} else if (error.response.status === 404) {
				setFormError("Credenciales incorrectas, intenta de nuevo!")
			} else {
				setFormError("Error en el servidor")
			}
		}
	}

	const resendEmail = async () => {
		try {
			const resendEmailData = await resendEmail()
			if (resendEmailData.status === 200) {
				console.log(resendEmailData.data)
			}
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className="flex min-h-screen w-full">
			<PaymentInstructionsPop
				show={showPaymentInstructionsPop}
				onClose={() => setShowPaymentInstructionsPop(false)}
			/>
			<div className="flex flex-col justify-center w-full px-[10%] sm:px-[15%] md:px-[25%] bg-[--tertiary] lg:w-1/2 lg:px-[10%]">
				<h1 className="mb-6 text-7xl text-[--secondary] flex gap-8 items-center justify-center font-[FoundersGrotesk]">
					<img src={logo} alt="Bucks" className="w-32 h-32" />{" "}
					<span className="mt-5">Bucks</span>
				</h1>
				<h2 className="mb-6 text-3xl text-balance text-center text-[--secondary] font-bold">
					¡Qué bueno tenerte de vuelta!
				</h2>
				<form className="space-y-4">
					<div className="space-y-2">
						<div className="relative border border-[--separator] rounded-full p-2">
							<input
								id="email"
								name="email"
								type="email"
								placeholder="Correo electrónico"
								className="w-full pl-3 pr-10 outline-none bg-transparent text-[--secondary] placeholder:text-[--separator]"
								onChange={handleLoginFormChange}
							/>
						</div>
					</div>
					<div className="space-y-2">
						<div className="relative border border-[--separator] rounded-full p-2">
							<input
								id="password"
								name="password"
								type={showPassword ? "text" : "password"}
								placeholder="Contraseña"
								className="w-full pl-3 pr-10 outline-none bg-transparent text-[--secondary] placeholder:text-[--separator]"
								onChange={handleLoginFormChange}
							/>
							<button
								type="button"
								className="absolute right-4 top-2"
								onClick={() => setShowPassword(!showPassword)}
							>
								{showPassword ? (
									<TbEye className="h-6 w-6 text-[--separator] hover:text-[--secondary]" />
								) : (
									<TbEyeClosed className="h-6 w-6 text-[--separator] hover:text-[--secondary]" />
								)}
							</button>
						</div>
					</div>
					<p className="error-message w-full h-4">{formError}</p>
					{resendemail && (
						<p className="w-full" onKeyDown={resendEmail} onClick={resendEmail}>
							Reenviar correo de verificación
						</p>
					)}
					<div className="flex justify-between items-center">
						<div className="flex gap-2 items-center">
							<input
								type="checkbox"
								id="remember"
								className="hover:cursor-pointer h-5 w-5 border-2 border-[--secondary] rounded-md checked:bg-[--primary] checked:border-[--primary] checked:before:scale-100 grid place-content-center appearance-none"
							/>
							<label
								htmlFor="remember"
								className="text-sm text-[--secondary] font-bold hover:cursor-pointer"
							>
								Recordar mi cuenta
							</label>
						</div>
						<Link
							to="/forgot-password"
							className="text-sm text-[--separator] hover:underline font-bold"
						>
							¿Olvidaste tu contraseña?
						</Link>
					</div>
					<button
						className="w-full bg-gradient-to-r from-[--glow-blue] to-[--glow-green] text-white rounded-full p-2 font-bold hover:scale-105 transition-all duration-300 ease-in-out"
						onClick={handleSubmit}
					>
						Iniciar sesión
					</button>
				</form>
				<p className="mt-4 text-sm text-center text-[--secondary] font-bold">
					¿Aún no tienes cuenta?{" "}
					<button
						href="#"
						onClick={(e) => {
							e.preventDefault()
							setShowPaymentInstructionsPop(true)
						}}
						className="text-[--primary] hover:underline"
					>
						Regístrate
					</button>
				</p>
			</div>
			<div className="hidden w-1/2 bg-gradient-to-r from-[--secondary] to-[--secondary-light] lg:flex lg:flex-col lg:justify-center lg:items-center overflow-hidden relative">
				<div
					className={`text-center flex absolute left-0 w-[300%] transition-transform ease-in-out duration-1000 -translate-x-${index}/3`}
				>
					<div className="w-1/3 2xl:px-52">
						<HiSparkles className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>Contabilidad</span> en tiempo real y simulaciones{" "}
							<span>financieras con IA</span>.
						</p>
					</div>
					<div className="w-1/3 2xl:px-52">
						<TbClock className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>Genera informes</span> financieros y análisis{" "}
							<span>en minutos</span>.
						</p>
					</div>
					<div className="w-1/3 2xl:px-52">
						<TbRouteX className="w-64 h-64 mx-auto mb-4 text-[--glow-green]" />
						<p className="mb-4 text-4xl text-balance text-[--tertiary] [&>span]:text-[--glow-green]">
							<span>Acelera y optimiza</span> la toma de decisiones.
						</p>
					</div>
				</div>
				<div className="absolute bottom-5">
					<div className="flex justify-center mt-4 space-x-2">
						<span
							className={`w-2 h-2 rounded-full ${index === 0 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
						<span
							className={`w-2 h-2 rounded-full ${index === 1 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
						<span
							className={`w-2 h-2 rounded-full ${index === 2 ? "bg-[--glow-green]" : "bg-[--separator]"}`}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
