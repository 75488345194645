import { useDispatch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import Cookies from "js-cookie"
import { TbCheckbox, TbEye, TbEyeClosed, TbPencil, TbPencilCancel, TbX } from "react-icons/tb"
import { useEffect, useState } from "react"
import { editUser } from "../../../redux/userSlice"
import { updatePassword, updateUser, updatePreferences } from "../../../services/user"
import Button from "../../common/Buttons/Button"
import { useLocation } from "react-router-dom"
import Header from "../../common/Header/Header"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user
)

export default function Profile() {
	const location = useLocation()

	useEffect(() => {
		if (location.state?.scrollTo) {
			const element = document.getElementById(location.state.scrollTo)
			if (element) {
				element.scrollIntoView({ behavior: "smooth" })
			}
		}
	}, [location.state])

	const user = useSelector(selectUser)

	const dispatch = useDispatch()
	const [name, setName] = useState(user.name)
	const [showPasswordModal, setShowPasswordModal] = useState(false)
	const [pass, setPass] = useState("")
	const [confirmPass, setConfirmPass] = useState("")
	const [showPass, setShowPass] = useState(false)
	const [showConfirmPass, setShowConfirmPass] = useState(false)
	const [error, setError] = useState("")
	const [showError, setShowError] = useState(false)
	const [lastname, setLastname] = useState(user.lastname)
	const [editName, setEditName] = useState(false)
	const [editLastname, setEditLastname] = useState(false)
	const [preferences, setPreferences] = useState({
		inventoryManagement: user.preferences?.inventoryManagement || "---",
		salesPullConcurrency: user.preferences?.salesPullConcurrency || "---",
	})

	const handlePreferenceChange = async (event) => {
		const { name, value } = event.target

		setPreferences((prev) => ({
			...prev,
			[name]: value,
		}))

		// Update preferences in the database
		await updatePreferences(Cookies.get("token"), {
			preferences: {
				...preferences,
				[name]: value,
			},
		})
	}

	useEffect(() => {
		if (user?.preferences) {
			setPreferences({
				inventoryManagement: user.preferences.inventoryManagement || "general",
				salesPullConcurrency: user.preferences.salesPullConcurrency || "quincenal",
			})
		}
	}, [user])
	return (
		<>
			<Header title={"Mi perfil"} />
			<div className="h-20" />
			<main className="px-12 w-full pb-8">
				<div
					className={`${showPasswordModal ? "block" : "hidden"} fixed w-screen h-screen bg-neutral-800/10 top-0 left-0 z-20`}
				>
					<div className="relative bg-white border border-neutral-300 p-4 rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-8 w-1/4">
						<button
							className="absolute top-2 right-2"
							onClick={() => setShowPasswordModal(false)}
						>
							<TbX className="text-black hover:text-neutral-700" size={24} />
						</button>
						<h1 className="text-center">Cambiar contraseña</h1>
						<div className="flex flex-col gap-4">
							<div className="border border-neutral-300 rounded-md relative">
								<input
									value={pass}
									onChange={(e) => setPass(e.target.value)}
									className="px-2 py-1 w-full rounded-md"
									type={showPass ? "text" : "password"}
									placeholder="Contraseña nueva"
								/>
								<button
									type="button"
									className="absolute right-2.5 top-1"
									onClick={() => setShowPass(!showPass)}
								>
									{showPass ? (
										<TbEye className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									) : (
										<TbEyeClosed className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									)}
								</button>
							</div>
							<div className="border border-neutral-300 rounded-md relative">
								<input
									value={confirmPass}
									onChange={(e) => setConfirmPass(e.target.value)}
									className="px-2 py-1 w-full rounded-md"
									type={showConfirmPass ? "text" : "password"}
									placeholder="Confirmar contraseña"
								/>
								<button
									type="button"
									className="absolute right-2.5 top-1"
									onClick={() => setShowConfirmPass(!showConfirmPass)}
								>
									{showConfirmPass ? (
										<TbEye className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									) : (
										<TbEyeClosed className="h-6 w-6 text-gray-400 hover:text-gray-600" />
									)}
								</button>
							</div>
						</div>
						<div
							className={`${showError ? "block" : "hidden"} absolute bottom-11 w-full left-0 text-red-600 text-center`}
						>
							{error}
						</div>
						<button
							className="mx-auto bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg px-4 hover:bg-green-500/30"
							onClick={() => {
								if (pass.length < 8) {
									setError("La contraseña debe tener al menos 8 caracteres")
									setShowError(true)
								} else if (pass !== confirmPass) {
									setError("Las contraseñas no coinciden")
									setShowError(true)
								} else {
									setError("")
									setShowError(false)
									updatePassword(Cookies.get("token"), pass)
									setShowPasswordModal(false)
									setPass("")
									setConfirmPass("")
								}
							}}
						>
							Confirmar
						</button>
					</div>
				</div>
				<section className="flex flex-col gap-12 w-full">
					<div className="flex flex-col justify-between items-center">
						<h1 className="text-[2rem] text-neutral-800 mx-auto mt-10">
							Información personal
						</h1>
						<h2 className="text-xl text-neutral-800 mx-auto mt-2">
							Administra tu información
						</h2>
					</div>
					<div className="flex flex-col gap-6 w-2/3 2xl:w-1/2 mx-auto">
						<div className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden shadow">
							<h1 className="text-2xl px-6">Información básica</h1>
							<p className="border-neutral-600 text-sm px-6">
								Bucks utiliza tu información con precaución
							</p>
							<div className="flex flex-col pt-4 relative">
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300 group">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">Nombre</span>
									{editName ? (
										<input
											className="border border-neutral-300 rounded-md px-2"
											type="text"
											value={name}
											onChange={(e) => setName(e.target.value)}
											autoFocus
										/>
									) : (
										<span className="px-2">{user.name}</span>
									)}
									<div className="hidden group-hover:block absolute right-4">
										{editName ? (
											<>
												<button
													type="button"
													onClick={() => {
														dispatch(editUser({ name }))
														updateUser(Cookies.get("token"), { name })
														setEditName(false)
													}}
												>
													<TbCheckbox
														className="text-green-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														setName(user.name)
														setEditName(false)
													}}
												>
													<TbPencilCancel
														className="text-red-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
											</>
										) : (
											<button
												type="button"
												onClick={() => {
													setEditName(true)
													setName(user.name)
												}}
											>
												<TbPencil
													className="text-yellow-600 opacity-50 hover:opacity-100"
													size={24}
												/>
											</button>
										)}
									</div>
								</div>
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300 group">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">
										Apellido
									</span>
									{editLastname ? (
										<input
											className="border border-neutral-300 rounded-md px-2"
											type="text"
											value={lastname}
											onChange={(e) => setLastname(e.target.value)}
											autoFocus
										/>
									) : (
										<span className="px-2">{user.lastname}</span>
									)}
									<div className="hidden group-hover:block absolute right-4">
										{editLastname ? (
											<>
												<button
													type="button"
													onClick={() => {
														dispatch(editUser({ lastname }))
														updateUser(Cookies.get("token"), {
															lastname,
														})
														setEditLastname(false)
													}}
												>
													<TbCheckbox
														className="text-green-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
												<button
													type="button"
													onClick={() => {
														setName(user.lastname)
														setEditLastname(false)
													}}
												>
													<TbPencilCancel
														className="text-red-600 opacity-50 hover:opacity-100"
														size={24}
													/>
												</button>
											</>
										) : (
											<button
												type="button"
												onClick={() => {
													setEditLastname(true)
													setLastname(user.lastname)
												}}
											>
												<TbPencil
													className="text-yellow-600 opacity-50 hover:opacity-100"
													size={24}
												/>
											</button>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow">
							<h1 className="text-2xl border-neutral-800 px-6">
								Información de contacto
							</h1>
							<div className="flex flex-col pt-4">
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">Correo</span>
									<span className="px-2">{user.email}</span>
								</div>
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2">
										Contraseña
									</span>
									<button
										className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg px-4 hover:bg-green-500/30 mx-2"
										onClick={() => setShowPasswordModal(true)}
									>
										Cambiar
									</button>
								</div>
							</div>
						</div>
						<div className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow">
							<h1 className="text-2xl border-neutral-800 px-6">Preferencias</h1>
							<div className="flex flex-col pt-4">
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										Preferencia de Inventario
									</span>
									<select
										value={preferences.inventoryManagement}
										name="inventoryManagement"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="general">General</option>
										<option value="standard">Standard</option>
										<option value="specific">Especifica</option>
									</select>
								</div>
								<div className="flex hover:bg-neutral-50 px-6 py-4 border-t border-neutral-300">
									<span className="text-neutral-600 w-2/5 2xl:w-1/2 my-auto">
										Frecuencia actualización de las ventas
									</span>
									<select
										value={preferences.salesPullConcurrency}
										name="salesPullConcurrency"
										onChange={handlePreferenceChange}
										className="border border-neutral-300 rounded-lg px-4 py-2 bg-white text-neutral-600 mx-2"
									>
										<option value="fortnightly">Quincinal</option>
										<option value="monthly">Mensual</option>
										<option value="quarterly">Trimestral</option>
									</select>
								</div>
							</div>
						</div>
						<div
							id="plans"
							className="border border-neutral-300 rounded-lg pt-4 bg-white overflow-hidden relative shadow"
						>
							<h1 className="text-2xl border-neutral-800 px-6">Planes</h1>
							<div className="grid grid-cols-3 pt-4">
								<div className="flex flex-col hover:bg-neutral-50 px-8 py-4 border-t border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">Básico</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												usuario/mes
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 0 ? "Actual" : "Empezar"}
											disabled={user.role === 0}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										El Plan Básico está diseñado para automatizar las tareas
										contables fundamentales y simplificar la gestión de tu
										empresa. Este plan incluye la subida y procesamiento
										automático de las facturas, así como la integración con tu
										software contable actual. Con este plan, podrás extraer toda
										la información relevante de las facturas y contabilizarla de
										manera precisa y eficiente.
									</p>
								</div>
								<div className="flex flex-col hover:bg-neutral-50 px-8 py-4 border-t border-l border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">Standard</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												usuario/mes
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 1 ? "Actual" : "Empezar"}
											disabled={user.role === 1}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										El Plan Standard amplía las capacidades del Plan Básico al
										incorporar un módulo adicional de gestión de inventarios.
										Este plan no solo te permitirá automatizar el procesamiento
										de facturas y su causación, sino que también te brindará
										herramientas avanzadas para gestionar y contabilizar tu
										inventario de manera integral.
									</p>
								</div>
								<div className="flex flex-col hover:bg-neutral-50 px-6 py-4 border-t border-l border-neutral-300 gap-4">
									<header className="flex flex-col gap-2">
										<h2 className="text-lg">Pro</h2>
										<h2 className="text-xl flex items-center gap-2">
											$0.00
											<span className="text-xs text-neutral-500 my-auto inline-block">
												usuario/mes
											</span>
										</h2>
										<Button
											className="self-center w-min py-1"
											text={user.role === 2 ? "Actual" : "Empezar"}
											disabled={user.role === 2}
										/>
									</header>
									<p className="text-neutral-500 text-balance">
										El Plan Pro está diseñado para empresas que requieren una
										solución completa y personalizada para su gestión contable e
										inventarial. Además de todas las funcionalidades de los
										planes anteriores, este plan incluirá características
										avanzadas y herramientas adicionales adaptadas a tus
										necesidades específicas.
									</p>
								</div>
							</div>
						</div>
						<div className="border border-neutral-300 rounded-lg py-4 bg-white overflow-hidden flex px-6 shadow">
							<h1 className="text-2xl border-neutral-800 w-2/5 2xl:w-1/2">Cuenta</h1>
							<button
								className="bg-red-500/20 text-red-700 border border-red-500/25 rounded-lg px-4 hover:bg-red-500/30 mx-2"
								onClick={() => {
									Cookies.remove("token")
									window.location.reload()
								}}
							>
								Cerrar sesión
							</button>
						</div>
					</div>
				</section>
			</main>
		</>
	)
}
