import { createSlice } from "@reduxjs/toolkit"

let initialState = {
	providers: [],
}
try {
	initialState = {
		// providers: (await getAllProviders()).map(providerToProveedor).reverse(),
	}
} catch (error) {
	console.error(error)
}

export const providersSlice = createSlice({
	name: "providers",
	initialState,
	reducers: {
		addProvider: (state, action) => {
			state.providers.unshift(action.payload)
		},
		editProvider: (state, action) => {
			const index = state.providers.findIndex((provider) => provider.id === action.payload.id)
			state.providers[index] = action.payload
		},
		removeProvider: (state, action) => {
			state.providers = state.providers.filter(
				(notification) => notification.id !== action.payload.id
			)
		},
	},
})

export const { addProvider, editProvider, removeProvider } = providersSlice.actions
export default providersSlice.reducer
