import axios from "axios";
import Cookies from "js-cookie";

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/sellers'; // Replace with your API base URL

// Get
export const getSellers= async ()=> {
    try {
      const response = await axios.get(`${VITE_BASE_URL}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error getting sellers:", error);
      throw error;
    }
  };

export const searchSeller = async ({search=""} = { search:""}) => {
    if (!search) {
        return await getSellers();
    }
    try {
      const response = await axios.get(`${VITE_BASE_URL}/search`, {
        params: {filter: search},
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        });
        return response.data;
    } catch (error) {
        console.error("Error getting sellers:", error);
        throw error;
    }
}










