import { useState, useEffect } from "react"

import Header from "../../common/Header/Header"

// Import Components
import { TableComponent } from "../../common/tableComponent/tableComponent"

// Import Services
import { getDebtSummary, getTaxSummary } from "../../../services/invoice"
import { useNavigate } from "react-router-dom"

const DebtSummary = () => {
	const [data, setData] = useState([])
	const navigator = useNavigate()
	const [, setTaxSummary] = useState([])

	const fetchDebtSummary = async () => {
		try {
			const response = await getDebtSummary()
			const transformedData = response.map((item) => ({
				"Nit": item.vendorId,
				"Nombre": item.vendorName,
				"Deposito Total": item.deposit,
				"Deuda Total": item.debt,
				"Total": item.totalInvoiceAmount,
			}))
			setData(transformedData)
		} catch (error) {
			console.error("Error getting debt summary:", error)
		}
	}
	const fetchTaxSummary = async () => {
		try {
			const response = await getTaxSummary()
			setTaxSummary(response[0])
		} catch (error) {
			console.error("Error getting tax summary:", error)
		}
	}

	const onClick = (item) => {
		navigator(`/modules/data/${item.Nombre}/${item.Nit}`)
	}
	useEffect(() => {
		fetchDebtSummary()
		// fetchTaxSummary()
	}, [])

	return (
		<>
			<Header title={"Deudas"} />
			<div className="h-20" />
			<div className="holder">
				<div className="workflows">
					{data.lenght == 0 ? (
						<p>No se encontró información consolidada</p>
					) : (
						<div className="consolidate-data">
							<TableComponent data={data} onClick={onClick} />
						</div>
					)}
					{/* <div>
						<p>ReteICA total: ${taxSummary.totalReteICA}</p>
						<p>ReteFuente total:${taxSummary.totalReteFuente}</p>
					</div> */}
				</div>
			</div>
		</>
	)
}

export default DebtSummary
