import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    invoices: [],
}

export const invoiceSlice = createSlice({
    name: "invoice",
    initialState,
    reducers: {
        addInvoice: (state, action) => {
            const { status, name, description, extension, blobid, invoiceId, module } = action.payload;
            state.invoices.push({
                status,
                name,
                description,
                extension,
                blobid,
                invoiceId,
                module
            });
        },
        clearInvoices: (state) => {
            state.invoices = [];
        }
    }
});

export const { addInvoice, clearInvoices } = invoiceSlice.actions;
export default invoiceSlice.reducer;