import { Children } from "react";

// Import PropTypes
import propTypes from "prop-types";

export const Each = ({ data, render }) => {
  // Check if data is an array, if not, return null or some fallback UI
  if (!Array.isArray(data)) {
    console.error("Each component expects an array, received:", data);
    return null; // or return <SomeFallbackComponent />;
  }

  return Children.toArray(data.map((item, index) => render(item, index)));
};

Each.propTypes = {
  data: propTypes.array,
  render: propTypes.func,
};
