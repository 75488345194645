import axios from "axios"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/data';

export const getData = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}/activity`)
		
		return response.data;
	} catch (error) {
		console.error("Error reading activities:", error)
		throw error
	}
}
