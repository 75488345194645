import { useState } from "react";
import PropTypes from "prop-types";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import "./Password.css";

export const Password = ({ handleInputChange, text, name }) => {
  const [values, setValues] = useState(false);

  const handleClickShowPassword = () => {
    setValues(!values);
  };

  return (
    <div className="password">
      <input
        className="pinput"
        name={name}
        onChange={handleInputChange}
        placeholder={text}
        type={values ? "text" : "password"}
      />
      <div className="hidden">
          {values ? (
              <IoMdEye onClick={handleClickShowPassword} />
              ) : (
              <IoMdEyeOff onClick={handleClickShowPassword} />
          )}
      </div>
    </div>
  );
};

Password.propTypes = {
  handleInputChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
