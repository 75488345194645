import { useEffect, useState } from "react"

// Import components
import Button from "../Buttons/Button"
import { ToastContainer, toast } from "react-toastify"

// Import Services
import { updateInvoice } from "../../../services/invoice"

// Import Proptypes
import propTypes from "prop-types"

// Import Styles
import "./table.css"
import InvoiceFormInput from "../inputs/InvoiceFormInput"
import ItemRow from "./ItemRow"
import InvoiceCombobox from "../inputs/InvoiceCombobox"
import {
	getAllProviders,
	getProviderById,
	providerToProveedor,
	searchProviders,
} from "../../../services/providers"

export const Table = ({
	Id,
	Items,
	DueDate,
	InvoiceId,
	InvoiceTotal,
	VendorAddress,
	VendorName,
	VendorNit,
	supplierId,
	VendorCity,
	CustomerName,
	CustomerId,
	CustomerAddress,
	CustomerCity,
	IssueDate,
	InvoiceTax,
}) => {
	const [newItems, setNewItems] = useState([])
	const [providers, setProviders] = useState([])

	const cities = ["Bogotá, d.c"]
	const [formData, setFormData] = useState({
		items: Items,
		dueDate: DueDate,
		invoiceId: InvoiceId,
		invoiceTotal: InvoiceTotal,
		supplierId: supplierId,
		vendorAddress: VendorAddress,
		vendorName: VendorName,
		vendorNit: VendorNit,
		vendorCity: VendorCity,
		customerName: CustomerName,
		customerId: CustomerId,
		customerAddress: CustomerAddress,
		customerCity: CustomerCity,
		issueDate: IssueDate,
		invoiceTax: InvoiceTax,
	})

	const getProviders = async () => {
		const providers = await getAllProviders({ limit: 10, page: 1 })
		setProviders(providers.map(providerToProveedor))
		const provider = await getProviderById(supplierId)
		setFormData((prev) => ({
			...prev,
			vendorName: provider.name,
			vendorNit: provider.id,
			vendorAddress: provider.addresses[0]?.address,
		}))
	}

	const updateProvider = (provider) => {
		setFormData((prev) => ({
			...prev,
			supplierId: provider.id,
			vendorName: provider.nombre,
			vendorNit: provider.numDoc,
			vendorAddress: provider.addresses[0]?.address,
		}))

		// Save the supplierId immediately after updating formData
		handleSave("supplierId", provider.id, true)
	}

	const getProvidersByQuery = async (query) => {
		const providers = await searchProviders({ search: query, limit: 10 })
		setProviders(providers.map(providerToProveedor))
	}

	useEffect(() => {
		getProviders()
	}, [])

	useEffect(() => {}, [formData])

	const handleChange = (e) => {
		const { name } = e.target
		setFormData((prevState) => ({
			...prevState,
			[name]: e.target.value,
		}))
	}

	const handleSave = (name, value, required = false) => {
		if (required && value.trim() === "") {
			toastPop("El campo no puede mantenerse vacío", "colored")
			return
		}

		setFormData((prevState) => ({
			...prevState,
			[name]: value,
		}))

		updateinvoice()
	}

	const toastPop = (message, type) => {
		toast(message, {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
			theme: type,
		})
	}

	const updateinvoice = async () => {
		try {
			console.log("formData", formData)
			const response = await updateInvoice(Id, formData)
			if (response.status === 200) {
				toastPop("Información actualizada con éxito", "light")
			} else {
				toastPop("Error al procesar la solicitud", "colored")
			}
		} catch (error) {
			console.error("Error updating invoice:", error)
			toastPop("Error al procesar la solicitud", "colored")
		}
	}

	const validItem = (description, quantity, total, unitPrice, unitTax) => {
		return (
			description &&
			description?.trim() !== "" &&
			quantity > 0 &&
			total > 0 &&
			unitPrice > 0 &&
			unitTax >= 0
		)
	}

	const addNewItemRow = () => {
		setNewItems((prev) => [
			...prev,
			{
				description: null,
				quantity: null,
				total: null,
				unitPrice: null,
				unitTax: null,
			},
		])
	}

	const saveRow = ({ description, quantity, total, unitPrice, tax }, id) => {
		console.log("FUCK")

		if (!validItem(description, quantity, total, unitPrice, tax)) {
			toastPop("Porfavor asegurese de completar todos los datos", "colored")
			return
		}

		setFormData((prev) => {
			const newItems = prev.items.map((item) => (item.id === id ? { ...item } : item))
			updateinvoice()
			return { ...prev, items: newItems }
		})
	}

	const createRow = ({ description, quantity, total, unitPrice, tax }, id) => {
		console.log("FUCKS")
		if (!validItem(description, quantity, total, unitPrice, tax)) {
			toastPop("Porfavor asegurese de completar todos los datos", "colored")
			return
		}
		setNewItems((prev) => prev.filter((item) => id !== item.id))
		setFormData((prev) => {
			const newItems = [...prev.items]
			newItems.push({
				id,
				description: description,
				quantity: quantity,
				total: total,
				unitPrice: unitPrice,
				unitTax: tax,
			})
			updateinvoice()
			return { ...prev, items: newItems }
		})
	}

	const deleteRow = (id) => {
		setNewItems((prev) => prev.filter((item) => item.id !== id))
		setFormData((prev) => {
			const newItems = prev.items.filter((item) => item.id !== id)
			return {
				...prev,
				items: newItems,
			}
		})
	}

	return (
		<form>
			<div>
				<ToastContainer />
				<div className="invoice-data">
					<h2 className="mb-4">Información de la factura</h2>
					<InvoiceFormInput
						name="dueDate"
						description="Fecha de vencimiento:"
						value={formData.dueDate}
						handleChange={handleChange}
						handleSave={handleSave}
						required
					/>
					<InvoiceFormInput
						name="issueDate"
						description="Fecha de emisión:"
						value={formData.issueDate}
						handleChange={handleChange}
						handleSave={handleSave}
						required
					/>
					<InvoiceFormInput
						name="invoiceId"
						description="Factura ID:"
						number
						value={formData.invoiceId}
						handleChange={handleChange}
						handleSave={handleSave}
						required
					/>
					<InvoiceFormInput
						name="invoiceTotal"
						description="Total factura:"
						number
						value={formData.invoiceTotal}
						handleChange={handleChange}
						handleSave={handleSave}
						required
					/>
				</div>

				<div className="invoice-data">
					<h2 className="mb-4">Información del proveedor</h2>
					<InvoiceCombobox
						id="searchProvider"
						label="Buscar proveedor por nombre o proveedor"
						values={providers}
						onSelect={(value, selected) => {
							if (selected) {
								value.addresses = value.addresses || []
								updateProvider(value) // Immediately update provider if selected
							} else {
								getProvidersByQuery(value || "") // Continue querying if user is typing
							}
						}}
					/>
					<InvoiceFormInput
						name="vendorName"
						description="Nombre del proveedor:"
						value={formData.vendorName}
						handleChange={handleChange}
						handleSave={handleSave}
						disabled
					/>
					<InvoiceFormInput
						name="vendorNit"
						description="NIT del proveedor:"
						value={formData.vendorNit}
						handleChange={handleChange}
						handleSave={handleSave}
						required
						disabled
					/>
					<InvoiceFormInput
						name="vendorAddress"
						description="Dirección del proveedor:"
						value={formData.vendorAddress}
						handleChange={handleChange}
						handleSave={handleSave}
						disabled
					/>
					<InvoiceFormInput
						name="vendorCity"
						description="Ciudad del Proveedor:"
						value={formData.vendorCity}
						handleChange={handleChange}
						handleSave={handleSave}
						required
						options={cities}
					/>
				</div>

				<div className="invoice-data">
					<h2 className="mb-4">Información del cliente</h2>
					<InvoiceFormInput
						name="customerName"
						description="Nombre del cliente:"
						value={formData.customerName}
						handleChange={handleChange}
						handleSave={handleSave}
					/>
					<InvoiceFormInput
						name="customerId"
						description="ID del cliente:"
						number
						value={formData.customerId}
						handleChange={handleChange}
						handleSave={handleSave}
					/>
					<InvoiceFormInput
						name="customerAddress"
						description="Dirección del cliente:"
						value={formData.customerAddress}
						handleChange={handleChange}
						handleSave={handleSave}
					/>
					<InvoiceFormInput
						name="customerCity"
						description="Ciudad del cliente:"
						value={formData.customerCity}
						handleChange={handleChange}
						handleSave={handleSave}
						required
						options={cities}
					/>
				</div>
			</div>
			<h2>Productos</h2>
			<table className="table" style={{ marginBottom: "1rem" }}>
				<thead>
					<tr>
						<th>Descripción</th>
						<th>Cantidad</th>
						<th>Total</th>
						<th>Precio Unidad</th>
						<th>Impuesto</th>
					</tr>
				</thead>
				<tbody>
					{formData.items.map(
						({ description, quantity, total, unitPrice, unitTax, id }) => (
							<ItemRow
								key={id}
								description={description}
								quantity={quantity}
								total={total}
								unitPrice={unitPrice}
								tax={unitTax}
								handleSave={(value) => saveRow(value, id)}
								handleDelete={() => deleteRow(id)}
							/>
						)
					)}
					{newItems.map(({ description, quantity, total, unitPrice, unitTax, id }) => (
						<ItemRow
							key={id}
							description={description}
							quantity={quantity}
							total={total}
							unitPrice={unitPrice}
							tax={unitTax}
							handleSave={(value) => createRow(value, id)}
							handleDelete={() => deleteRow(id)}
							enabled
						/>
					))}
				</tbody>
			</table>
			<div
				className="opts"
				style={{
					gap: "2rem",
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
				}}
			>
				<Button type="button" text="Add Item" onClick={addNewItemRow} />
			</div>
		</form>
	)
}

// Define prop types for the Table component
Table.propTypes = {
	Items: propTypes.array.isRequired,
	DueDate: propTypes.object,
	InvoiceId: propTypes.object,
	InvoiceTotal: propTypes.object,
	VendorAddress: propTypes.object,
	VendorName: propTypes.object,
	VendorNit: propTypes.object,
	CustomerName: propTypes.object,
	CustomerId: propTypes.object,
	CustomerAddress: propTypes.object,
	IssueDate: propTypes.object,
	InvoiceTax: propTypes.object,
	Id: propTypes.string.isRequired,
	supplierId: propTypes.string.isRequired,
	VendorCity: propTypes.object,
	CustomerCity: propTypes.object,
}
