import PropTypes from "prop-types"
import { useContext, useEffect, useRef } from "react"
import { ReactReduxContext } from "react-redux"
import { useNavigate } from "react-router-dom"

import Notification from "../../no../../notifications/Notification"

let notifications = []

export default function NotificationsPopup({ trigger, setTriggers }) {
	const popupRef = useRef(null)
	const navigate = useNavigate()

	const { store } = useContext(ReactReduxContext)
	notifications = store.getState().notifications.notifications

	const btn = document.getElementById("showNotifications")

	useEffect(() => {
		const handler = (e) => {
			if (popupRef.current) {
				if (!popupRef.current.contains(e.target) && !btn.contains(e.target)) {
					setTriggers((prevTriggers) => ({
						...prevTriggers,
						bell: false,
					}))
				}
			}
		}
		document.addEventListener("mousedown", handler)
		return () => {
			document.removeEventListener("mousedown", handler)
		}
	}, [setTriggers])
	return (
		<div
			className={`bg-[#C4F7C2] py-1 rounded-md z-10 absolute top-16 -translate-x-8 h-fit flex flex-col items-center shadow-xl ${
				trigger ? "" : "hidden"
			}`}
			ref={popupRef}
		>
			<div className="overflow-y-scroll max-h-[50vh] no-scrollbar px-1 w-72 overflow-x-hidden">
				{notifications.map((data) => (
					<Notification key={data._id} notification={data} />
				))}
			</div>

			<div className="flex m-2 gap-4">
				<button
					className="text-black bg-white rounded-md px-2 hover:bg-neutral-50 shadow"
					onClick={() => {
						navigate("/notifications")
						setTriggers((prevTriggers) => ({
							...prevTriggers,
							bell: false,
						}))
					}}
				>
					Ver todas
				</button>
			</div>
		</div>
	)
}

NotificationsPopup.propTypes = {
	trigger: PropTypes.bool,
	setTriggers: PropTypes.func,
}
