import { getIntegrationbyUID } from "../services/integrations"

export const getIntegrationConfig = async () => {
    try {
        const { onPremise } = await getIntegrationbyUID()
        return onPremise
    } catch (error) {
        console.error('Error getting integration:', error)
        throw error
    }
}