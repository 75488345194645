import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/products"

export async function getAllProducts({ limit, page } = { limit: 10, page: 1 }) {
	try {
		const response = await axios.get(`${VITE_BASE_URL}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				limit,
				page,
			},
		})
		return response.data
	} catch (error) {
		if (error?.response?.status === 403) {
			return
		}

		console.error("Error reading products:", error)
		throw error
	}
}

export async function getAllCategories() {
	try {
		const response = await axios.get(`${import.meta.env.VITE_BASE_URL}/api/categories`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		if (error?.response?.status === 403) {
			return
		}
		console.error("Error reading categories:", error)
		throw error
	}
}

export async function searchProducts(
	{ limit = 10, page = 1, search = "" } = { limit: 10, page: 1, search: "" }
) {
	if (!search) {
		return getAllProducts({ limit })
	}
	try {
		const response = await axios.get(`${VITE_BASE_URL}/search`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
			params: {
				limit,
				page,
				filter: search,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error searching products:", error)
		throw error
	}
}

export async function createProduct(product) {
	try {
		const response = await axios.post(`${VITE_BASE_URL}`, product, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error creating product:", error)
		throw error
	}
}

export async function putProduct(product) {
	try {
		const response = await axios.put(`${VITE_BASE_URL}/${product._id}`, product, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error editing product:", error)
		throw error
	}
}

export async function deleteProduct(product) {
	try {
		const response = await axios.delete(`${VITE_BASE_URL}/${product._id}`, {
			headers: {
				Authorization: `Bearer ${Cookies.get("token")}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error removing product:", error)
		throw error
	}
}

export function productToProducto(product) {
	let type
	if (product.type === "Product") {
		type = "Producto"
	} else if (product.type === "Service") {
		type = "Servicio"
	} else if (product.type === "ConsumerGood") {
		type = "Consumo"
	}

	let tax_classification
	if (product.tax_classification === "Taxed") {
		tax_classification = "Gravado"
	} else if (product.tax_classification === "Exempt") {
		tax_classification = "Exento"
	} else if (product.tax_classification === "Excluded") {
		tax_classification = "Excluido"
	}
	return {
		...product,
		type,
		tax_classification,
	}
}

export function productoToProduct(producto) {
	let type
	if (producto.type === "Producto") {
		type = "Product"
	} else if (producto.type === "Servicio") {
		type = "Service"
	} else if (producto.type === "Consumo") {
		type = "ConsumerGood"
	}

	let tax_classification
	if (producto.tax_classification === "Gravado") {
		tax_classification = "Taxed"
	} else if (producto.tax_classification === "Exento") {
		tax_classification = "Exempt"
	} else if (producto.tax_classification === "Excluido") {
		tax_classification = "Excluded"
	}

	return {
		...producto,
		type,
		tax_classification,
	}
}
