import axios from "axios";
import Cookies from "js-cookie";


const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/file'; // URL for file upload


export const uploadFile = async (file) => {
    try {
        const response = await axios.post(`${VITE_BASE_URL}/upload`, file, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${Cookies.get("token")}`,
            }
        });
        return response;
    } catch (error) {
        console.error("Error uploading file:", error);
        throw error;
    }
}


export const getFile = async (id) => {
    try {
        const response = await axios.get(`${VITE_BASE_URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        console.error("Error getting file:", error);
        throw error;
    }
}


export const deleteFile = async (id) => {
    try {
        const response = await axios.delete(`${VITE_BASE_URL}/${id}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
        return response;
    } catch (error) {
        console.error("Error deleting file:", error);
        throw error;
    }
}