import { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { authOTP, updatePassword } from "../../../services/user";
import { Password } from "../../common/PasswordInput/Password";
import propTypes from "prop-types";
import Button from "../../common/Buttons/Button";

function OtpInputWithValidation({ setPage }) {
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [otpError, setOtpError] = useState(null);
  const otpBoxReference = useRef([]);
  const { token } = useParams();

  function handleChange(value, index) {
    setOtp((prev) => {
      const otpCopy = [...prev];
      otpCopy[index] = value;
      return otpCopy;
    });

    if (value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    } else if (index === 5 && value) {
      otpBoxReference.current[index].blur();
    }
  }

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus();
    }
    if (e.key === "Enter" && e.target.value && index < 5) {
      otpBoxReference.current[index + 1].focus();
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let otpString = otp.join("");
    if (otpString.length < 6) {
      setOtpError("Please enter a valid OTP");
    } else {
      verifyOTP(otpString);
      setOtpError(null);
    }
  };

  const verifyOTP = async (otpString) => {
    try {
      const verifyOTPData = await authOTP(token, otpString);
      if (verifyOTPData.status === 200 && verifyOTPData.data.verification) {
        setPage("reset-form");
      } else {
        setOtpError("Please enter a valid OTP");
      }
    } catch (error) {
      console.log(error);
      if (error.response.status === 402) {
        setOtpError("OTP has expired, please issue a new one");
      } else {
        setOtpError("Please enter a valid OTP");
      }
    }
  };

  return (
    <div className="login-register">
      <div className="custom-container">
        <form onSubmit={handleSubmit}>
          <h2>Enter OTP</h2>
          <div className="otp-container">
            {otp.map((data, index) => (
              <input
                key={index}
                value={data}
                maxLength={1}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                ref={(reference) =>
                  (otpBoxReference.current[index] = reference)
                }
              />
            ))}
          </div>
          {otpError && <p className="error-message">{otpError}</p>}
          <Button text="Submit" />
        </form>
      </div>
    </div>
  );
}

export const ResetForm = ({ setPage }) => {
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState(null);
  const { token } = useParams();

  const handleInputChange = (e) => {
    setPassword({ ...password, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const passwordPattern =
      /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    if (!passwordPattern.test(password.password)) {
      setPasswordError(
        "Password must be at least 8 characters, contain one digit, one special character, one lowercase, and one uppercase character."
      );
      return; // Stop validation if the password does not meet the requirements
    }

    if (password.password !== password.confirmPassword) {
      setPasswordError("The passwords do not coincide");
      return; // Stop validation if the passwords do not coincide
    }

    // Si no hay errores, continuar con el envío del formulario
    updatePass();
    setPasswordError(null);
  };

  const updatePass = async () => {
    try {
      const updatePasswordData = await updatePassword(token, password.password);
      if (updatePasswordData.status === 200) {
        setPage("confirmation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="login-register">
      <div className="custom-container">
        <form onSubmit={handleSubmit}>
          <h1>Reset Password</h1>
          <p>Password</p>
          <Password
            handleInputChange={handleInputChange}
            text="Password"
            name="password"
          />
          <p>Confirm Password</p>
          <Password
            handleInputChange={handleInputChange}
            text="Confirm Password"
            name="confirmPassword"
          />
          {passwordError && <p className="error-message">{passwordError}</p>}
          <Button text="Submit" />
        </form>
      </div>
    </div>
  );
};

export default OtpInputWithValidation;

OtpInputWithValidation.propTypes = {
  setPage: propTypes.func,
};

ResetForm.propTypes = {
  setPage: propTypes.func,
};