import { useEffect, useState } from "react"
import Cookies from "js-cookie"
import Routing from "../navigation/Routes"
import { useDispatch } from "react-redux"
import { login } from "../redux/userSlice"
import { authToken } from "../services/user"

import { pdfjs } from "react-pdf"
import { syncNotifications } from "../redux/notifications.slice"
import { getAllNotificationsByFilter } from "../services/notifications"
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.js",
	import.meta.url
).toString()

const App = () => {
	const [auth, setAuth] = useState(false)
	const dispatch = useDispatch()

	const verifyToken = async () => {
		try {
			const verifyTokenData = await authToken(Cookies.get("token"))
			if (verifyTokenData.status === 200) {
				dispatch(login(verifyTokenData.data))
				dispatch(
					syncNotifications(
						await getAllNotificationsByFilter({
							type: { provider: true, inventory: true },
							status: { pending: true },
							priority: { info: true, warning: true, error: true },
						})
					)
				)
				setAuth(true)
			}
		} catch (error) {
			console.error(error)
			setAuth(false)
			Cookies.remove("token")
			window.location.reload()
		}
	}

	useEffect(() => {
		if (Cookies.get("token")) {
			verifyToken()
		} else {
			setAuth(false)
		}
	}, [auth])

	return <Routing />
}

export default App
