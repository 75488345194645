import "./userObjective.css";
import React, { useState } from "react";
import Button from "../../Buttons/Button";
import { useSelector } from "react-redux";
export const UserObjective = ({ show, onClose }) => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    setSelectedOption(selectedOption === value ? "" : value);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="popup-backdrop">
      <div className="popup-content objective-container">
        <h2>¿Cuál va a ser tu objetivo en bucks?</h2>
        <p className="objective-description">
          El motivo por el cual te preguntamos esto es para mejorar tu
          experiencia en la aplicación. Queremos asegurarnos de personalizar la
          plataforma según tus necesidades, ya sea para reportería o
          contabilización.
        </p>
        <div className="objective-options">
          <div className="checkbox-wrapper-18">
            <div className="round">
              <input
                type="checkbox"
                value="reporteria"
                checked={selectedOption === "reporteria"}
                onChange={handleCheckboxChange}
                id="checkbox-reporteria"
              />
              <label htmlFor="checkbox-reporteria"></label>
            </div>
            <h3>Reporteria</h3>
          </div>
          <div className="checkbox-wrapper-18">
            <div className="round">
              <input
                type="checkbox"
                value="contabilizacion"
                checked={selectedOption === "contabilizacion"}
                onChange={handleCheckboxChange}
                id="checkbox-contabilizacion"
              />
              <label htmlFor="checkbox-contabilizacion"></label>
            </div>
            <h3>Contabilización</h3>
          </div>
        </div>
        <Button onClick={onClose} text="Cerrar" disabled={!selectedOption} />
      </div>
    </div>
  );
};
{
  /* <UserObjective
        show={true}
        onClose={() => {
          setShow(false);
        }}
      /> */
}
