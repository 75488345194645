import axios from "axios";
import Cookies from "js-cookie";

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/sales-invoice'; // Replace with your API base URL

// Get
export const getSalesInvoices = async (query) => {
  try {
    const response = await axios.get(`${VITE_BASE_URL}/byCustomer`,
    {
        params: query,
      headers: {
        Authorization: `Bearer ${Cookies.get("token")}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error getting sales invoices:", error);
    throw error;
  }
};

export const getSalesGroupByCustomer = async (query,searchQuery) => {
    try {
      const response = await axios.get(`${VITE_BASE_URL}`, {
        params:{ query,searchQuery},
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
        });
        return response.data;
    } catch (error) {
        console.error("Error getting sales invoices:", error);
        throw error;
    }
};

export const createSalesInvoice = async (data) => {
    try {
      const response = await axios.post(`${VITE_BASE_URL}`, data, {
        headers: {
          Authorization: `Bearer ${Cookies.get("token")}`,
        },
      });
      return response;
    }
    catch (error) {
      console.error("Error creating sales invoice:", error);
      throw error;
    }
  }