import { useContext, useEffect, useRef, useState } from "react"
import { FaBars, FaTimes } from "react-icons/fa"
import { HeaderPop } from "../popUps/headerPopUp/headerPop.jsx"
import "./Header.css"
import { PaymentInstructionsPop } from "../popUps/payment_instructionsPopUp/payment_instructions.jsx"
import NotificationsPopup from "../popUps/notificationsPopup/NotificationsPopup.jsx"
import { ReactReduxContext } from "react-redux"
import { TbBellFilled, TbUserFilled } from "react-icons/tb"
import PropTypes from "prop-types"
import { getIntegrationbyUID } from "../../../services/integrations.js"
import { RequestConetionsPop } from "../popUps/connectionsPop/requestConectionsPop.jsx"
import { DragNdrop } from "../popUps/dragNdrop/dragNdrop.jsx"
import { FilesProgress } from "../popUps/filesprogress/filesprogress.jsx"

function Header({ title }) {
	const [triggers, setTriggers] = useState({
		header: null,
		bell: null,
	})
	const [apiUser, setApiUser] = useState(null)
	const [apiSecret, setApiSecret] = useState(null)
	const [showKeyRequest, setShowKeyRequest] = useState(false)
	const navRef = useRef()
	const [showPaymentInstructionsPop, setShowPaymentInstructionsPop] = useState(false)
	const [uploadFiles, setUploadFiles] = useState({
		show: false,
		id: "",
	})
	const [showProgress, setShowProgress] = useState(false)
	const [fileStatuses, setFileStatuses] = useState([])

	const showNavBar = () => {
		navRef.current.classList.toggle("responsive_nav")
	}

	const { store } = useContext(ReactReduxContext)
	const notifications = store.getState().notifications.notifications.length

	const [position, setPosition] = useState(window.scrollY)
	const [visible, setVisible] = useState(true)

	useEffect(() => {
		const handleScroll = () => {
			let moving = window.scrollY

			setVisible(position > moving)
			setPosition(moving)
		}
		window.addEventListener("scroll", handleScroll)
		return () => {
			window.removeEventListener("scroll", handleScroll)
		}
	})

	const uploadData = async () => {
		try {
			const response = await getIntegrationbyUID()
			setApiUser(response.apiKey)
			setApiSecret(response.apiSecret)
			setShowKeyRequest(true)
		} catch (error) {
			console.error("Error uploading data:", error)
			if (error.response.status === 404) {
				setApiSecret("")
				setApiUser("")
				setShowKeyRequest(true)
			}
		}
	}

	return (
		<>
			<PaymentInstructionsPop
				show={showPaymentInstructionsPop}
				onClose={() => setShowPaymentInstructionsPop(false)}
			/>
			{showKeyRequest && (
				<RequestConetionsPop
					show={showKeyRequest}
					onClose={() => setShowKeyRequest(false)}
					apiKey={apiUser}
					apiSecret={apiSecret}
				/>
			)}
			<DragNdrop
				show={uploadFiles.show}
				onClose={() => {
					setUploadFiles({
						show: false,
						id: "",
					})
				}}
				setShowProgress={setShowProgress}
				setFileStatuses={setFileStatuses}
			/>
			<FilesProgress
				show={showProgress}
				onClose={() => {
					setShowProgress(false)
				}}
				data={fileStatuses}
				setData={setFileStatuses}
			/>
			<header
				className={`text-[--light-color] flex justify-between fixed bg-[rgba(var(--tertiary),0.8)] backdrop-blur-md z-50 h-20 w-[calc(100%-350px)] px-8 transition-transform duration-300 ease-out shadow-md ${visible ? "translate-y-0" : "-translate-y-20"}`}
			>
				<div className="company flex h-20 gap-4">
					<h1 className="text-[--secondary] text-3xl">{title}</h1>
				</div>
				<nav ref={navRef}>
					<ul className="flex gap-8 mr-2 h-20">
						<div className="user flex justify-center items-center h-20 gap-8">
							<div className="flex gap-4">
								<button
									className="px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
									onClick={() =>
										setUploadFiles({
											show: true,
										})
									}
								>
									Subir facturas
								</button>
								<button
									className="px-4 py-1 bg-[--primary] rounded-full text-[--tertiary] font-bold transition-colors hover:bg-[--red] duration-500"
									onClick={uploadData}
								>
									Mis API Keys
								</button>
							</div>
							<div className="opt relative flex flex-col justify-center items-center cursor-pointer">
								<button
									id="showNotifications"
									className="z-50"
									onClick={() =>
										setTriggers((prevTriggers) => ({
											...prevTriggers,
											bell: !prevTriggers.bell,
										}))
									}
								>
									<TbBellFilled className="h-8 w-8 text-[--secondary]" />
									{notifications > 0 && (
										<div className="absolute h-4 w-4 rounded-full bg-red-400 top-0 right-0 text-center text-xs">
											{notifications}
										</div>
									)}
								</button>
								<NotificationsPopup
									trigger={triggers.bell}
									setTriggers={setTriggers}
								/>
							</div>
							<div className="opt flex flex-col justify-center items-center cursor-pointer">
								<button
									id="showProfile"
									className="h-8 w-8 rounded-full bg-[--primary]"
									onClick={() =>
										setTriggers((prevTriggers) => ({
											...prevTriggers,
											header: !prevTriggers.header,
										}))
									}
								>
									<TbUserFilled className="h-6 w-6 text-[--secondary] mx-auto" />
								</button>
								<HeaderPop trigger={triggers.header} setTriggers={setTriggers} />
							</div>
						</div>
						<button className="nav-btn nav-close-btn hidden" onClick={showNavBar}>
							<FaTimes />
						</button>
					</ul>
				</nav>
				<div className="burger-menu h-full flex items-center md:hidden">
					<button
						className="nav-btn p-1 cursor-pointer bg-transparent text-black"
						onClick={showNavBar}
					>
						<FaBars />
					</button>
				</div>
			</header>
		</>
	)
}

Header.propTypes = {
	title: PropTypes.string,
}

export default Header
