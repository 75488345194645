import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import "./modules.css"
import { TbCalendarDollar, TbInvoice } from "react-icons/tb"
import { uploadTokenFile } from "../../../services/invoice"
import { GrValidate } from "react-icons/gr"
import Header from "../../common/Header/Header"

export const toggleToast = (text) => {
	toast(text, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		progress: undefined,
		theme: "light",
	})
}

export const Modules = () => {
	const [showUpload, setShowUpload] = useState(false)
	const [file, setFile] = useState(null) 
	const [comparisonResult, setComparisonResult] = useState(null) 

	const firstLoad = useRef(true)
	const navigate = useNavigate()

	const handleFileChange = (event) => {
		setFile(event.target.files[0]) 
	}

	const handleFileUpload = async () => {
		try {
			if (!file) {
				toggleToast("Please select a file")
				return
			}
			const result = await uploadTokenFile(file)
			setComparisonResult(result)
			toggleToast("File uploaded successfully!")
		} catch (error) {
			console.error("Error uploading file:", error)
			toggleToast("File upload failed")
		}
	}

	useEffect(() => {
		if (firstLoad.current) {
			firstLoad.current = false
		}
	}, [])

	return (
		<>
			<Header title={"Causación"} />
			<div className="h-20" />
			<div>
				<div className="workflows w-full p-8 flex flex-col">
					<div className="items">
						<div className="flex justify-between items-center w-3/12">
							<p className="flex gap-2 items-center">
								<TbCalendarDollar className="h-8 w-8" /> Cuentas por pagar
							</p>
							<button
								className="px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md"
								onClick={() => navigate("/debt-summary")}
							>
								Ver
							</button>
						</div>
						<div className="flex justify-between items-center w-3/12">
							<p className="flex gap-2 items-center">
								<TbInvoice className="h-8 w-8" /> Facturas no enviadas
							</p>
							<button
								className="px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md"
								onClick={() => navigate("/invoices")}
							>
								Ver
							</button>
						</div>
						<div className="flex justify-between items-center w-3/12">
							<p className="flex gap-2 items-center">
								<GrValidate className="h-8 w-8" /> Consciliar con token DIAN
							</p>
							<button
								className="px-2 py-1 rounded-md bg-green-500/20 border border-green-500/25 hover:bg-green-500/80 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md"
								onClick={() => setShowUpload(true)}
							>
								Subir
							</button>
						</div>
					</div>
				</div>
			</div>

			<ToastContainer />

		
			{showUpload && (
				<div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
					<div className="bg-white p-8 rounded-md max-w-6xl shadow-md w-full">
						<h2 className="text-xl mb-4">Comparación del Token</h2>
						<input
							type="file"
							accept=".xls,.xlsx, csv"
							onChange={handleFileChange}
							className="my-2 border px-2 py-1 rounded w-full"
						/>
						<div className="flex justify-end mt-4">
							<button
								className="px-4 py-2 rounded-md bg-green-500/20 border border-green-500/25 hover:text-white hover:border-green-700 transition-all duration-500 ease-in-out hover:shadow-md mr-2"
								onClick={handleFileUpload}
							>
								Subir y comparar
							</button>
							<button
								className="bg-gray-500 text-white px-4 py-2 rounded-md"
								onClick={() => setShowUpload(false)}
							>
								Cerrar
							</button>
						</div>

						{comparisonResult && (
							<div className="mt-8">
								<h3 className="text-lg font-bold mb-4">CUFEs Encontrados: <p className="text-green-700">{comparisonResult.existingInvoices.length}</p></h3>
								<div className="max-h-48 overflow-y-auto border p-4 rounded bg-gray-50">
									{comparisonResult.existingInvoices.length > 0 ? (
										<ul className="list-disc pl-5 space-y-2">
											{comparisonResult.existingInvoices.map((invoice, index) => (
												<li key={index}>
													<strong>NIT</strong>: {invoice.nit} <br />
													<strong>CUFE</strong>: {invoice.cufe}
												</li>
											))}
										</ul>
									) : (
										<p>No CUFEs found in the database.</p>
									)}
								</div>

								<h3 className="text-lg font-bold mt-6 mb-4">CUFEs Faltantes: <p className="text-red-400">{comparisonResult.missingCufes.length}</p></h3>
								<div className="max-h-48 overflow-y-auto border p-4 rounded bg-gray-50">
									{comparisonResult.missingCufes.length > 0 ? (
										<ul className="list-disc pl-5 space-y-2">
											{comparisonResult.missingCufes.map((cufe, index) => (
												<li key={index}>
													<strong>NIT</strong>: {cufe.nit} <br />
													<strong>CUFE</strong>: {cufe.cufe}
												</li>
											))}
										</ul>
									) : (
										<p>No missing CUFEs found.</p>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	)
}
