import axios from 'axios';
import Cookies from 'js-cookie';


const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/transaction-entity';

// Create
export const createTransactionEntity = async (module, blobID, name, longTermPayment, data) => {
    try {
        const response = await axios.post(`${VITE_BASE_URL}`, {
            name,
            module,
            blobID,
            longTermPayment,
            data
        }, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
};

// Get all workspaces
export const getTransactionEntitiesOnWorkSpaceId = async (id) => {
    try {
        const response = await axios.get(`${VITE_BASE_URL}/${id}`, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        });
        return response;
    }
    catch (error) {
        console.error('Error getting workspaces:', error);
        throw error;
    }
}

export const updateTransactionEntity = async (id,
    Items,
    DueDate,
    InvoiceId,
    InvoiceTotal,
    VendorAddress,
    VendorName,
    VendorTaxId) => {
    try {
        const response = await axios.put(`${VITE_BASE_URL}/${id}`, {
            Items,
            DueDate,
            InvoiceId,
            InvoiceTotal,
            VendorAddress,
            VendorName,
            VendorTaxId
        }, {
            headers: {
                "Authorization": `Bearer ${Cookies.get('token')}`
            }
        });
        return response.data;
    }
    catch (error) {
        console.error('Error updating item:', error);
        throw error;
    }
}

//update transaction entity consolidated status by id
export const updateTransactionEntityConsolidatedStatusById = async (id) => {
    try {
        const response = await axios.put(`${VITE_BASE_URL}/consolidated/${id}`, { consolidated: true }, {
            headers: {
                "Authorization": `Bearer ${Cookies.get('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating item:', error);
        throw error;
    }
}
export const getAllExtractedDataFromWorkSpace = async (id) => {
    try {
        const response = await axios.get(`${VITE_BASE_URL}/${id}/extracted-data`, {
            headers: {
                "Authorization": `Bearer ${Cookies.get('token')}`
            }
        })
        return response.data;
    } catch (error) {
        console.error('Error getting extracted data:', error);
        return error
    }
}
export const deleteTransactionEntity = async (id) => {
    try {
        const response = await axios.delete(`${VITE_BASE_URL}/${id}`, {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
        });
        return response.data;
    }
    catch (error) {
        console.error('Error deleting item:', error);
        throw error;
    }
}

export const getTransactionEntityById = async (id) => {
    try {
        const response = await axios.get(`${VITE_BASE_URL}/one/${id}`, {
            headers: { 'Authorization': `Bearer ${Cookies.get('token')}` }
        });
        return response.data;
    }
    catch (error) {
        console.error('Error getting workspaces:', error);
        throw error;
    }
}