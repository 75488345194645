import { createSlice } from "@reduxjs/toolkit"

let initialState = {
	notifications: [],
}

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState,
	reducers: {
		syncNotifications: (state, action) => {
			state.notifications = action.payload
		},
		addNotification: (state, action) => {
			const { UID, name, date, status, metadata } = action.payload
			state.notifications.unshift({
				UID,
				name,
				date,
				status,
				metadata,
			})
		},
		removeNotification: (state, action) => {
			const { id } = action.payload
			state.notifications = state.notifications.filter(
				(notification) => notification._id !== id
			)
		},
		clearNotifications: (state) => {
			state.notifications = []
		},
	},
})

export const { addNotification, removeNotification, clearNotifications, syncNotifications } =
	notificationsSlice.actions
export default notificationsSlice.reducer
