import React, { useEffect, useState, useCallback } from "react"
import { useSelector } from "react-redux"
import Button from "../../common/Buttons/Button"
import { IoIosSend } from "react-icons/io"
import { IoCloudUploadOutline } from "react-icons/io5"
import { useDropzone } from "react-dropzone"
import websocketService from "../../../services/chatbot"
import { marked } from "marked"
import "./chatbot.css"
import PrivatePageLayout from "../../layouts/PrivatePageLayout"
import Header from "../../common/Header/Header"

const Chatbot = () => {
	const [userMessage, setUserMessage] = useState("")
	const [conversationHistory, setConversationHistory] = useState([])
	const [, setIsLoading] = useState(false)
	const [files, setFiles] = useState([])
	const user = useSelector((state) => state.user)

	useEffect(() => {
		websocketService.connect()

		websocketService.onMessage((data) => {
			if (data.message === "Loading") {
				setIsLoading(true)
			}
			if (data.message) {
				setConversationHistory((prevHistory) => {
					const newHistory = [...prevHistory]
					newHistory[newHistory.length - 1] = {
						...newHistory[newHistory.length - 1],
						bot: data.message,
					}
					return newHistory
				})
			}
			if (data.image) {
				setConversationHistory((prevHistory) => {
					const newHistory = [...prevHistory]
					newHistory[newHistory.length - 1] = {
						...newHistory[newHistory.length - 1],
						image: `data:image/png;base64,${data.image}`,
					}
					return newHistory
				})
			}
			setIsLoading(false)
		})

		websocketService.onError((error) => {
			console.error("WebSocket error:", error)
			setIsLoading(false)
		})

		return () => {
			websocketService.close()
		}
	}, [])

	const handleUserInput = (message, files) => {
		setConversationHistory((prevHistory) => [
			...prevHistory,
			{ user: message, bot: "Cargando...", image: null },
		])
		setIsLoading(true)
		if (files && files.length > 0) {
			files.forEach((file) => {
				const reader = new FileReader()
				reader.onload = () => {
					const fileContent = reader.result.split(",")[1] // Get the base64 part
					websocketService.sendMessage(message, fileContent, file.name)
				}
				reader.readAsDataURL(file)
			})
		} else {
			websocketService.sendMessage(message)
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!userMessage.trim() && files.length === 0) return // Prevent empty messages

		handleUserInput(userMessage, files)
		setUserMessage("") // Clear input after sending
		setFiles([]) // Clear files after sending
	}

	const onDrop = useCallback((acceptedFiles) => {
		setFiles((prevFiles) => [...prevFiles, ...acceptedFiles])
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
	})

	const renderBotMessage = (message) => {
		const parsedMessage = marked(message)
		return <div dangerouslySetInnerHTML={{ __html: parsedMessage }} />
	}

	return (
		<>
			<Header title={"Chatbot"} />
			<div className="h-20" />
			<div className="custom-container">
				<div className="holder">
					<form onSubmit={handleSubmit} className="chatbot">
						<div className="chatbot__header">
							<h1>Bienvenido de vuelta, {user.name}</h1>
							<h2>¿En qué puedo ayudarte hoy?</h2>
						</div>
						<div className="chatbot__body">
							{conversationHistory.map((entry, index) => (
								<React.Fragment key={index}>
									<div className="chatbot__placeholders">
										<div className="chatbot__placeholders__user">
											<p>Tú</p>
										</div>
										<div className="chatbot__body__message__user">
											<p>{entry.user}</p>
										</div>
									</div>
									<div className="chatbot__placeholders">
										<div className="chatbot__placeholders__bot">
											<p>Bot</p>
										</div>
										<div className="chatbot__body__message__bot">
											{entry.bot !== "Cargando..." ? (
												<>
													{renderBotMessage(entry.bot)}
													{entry.image && (
														<img src={entry.image} alt="Bot Response" />
													)}
												</>
											) : (
												<div className="chatbot__loader"></div>
											)}
										</div>
									</div>
								</React.Fragment>
							))}
						</div>
						<div className="chatbot__footer">
							<input
								type="text"
								value={userMessage}
								onChange={(e) => setUserMessage(e.target.value)}
								placeholder="Escribe tu mensaje aquí"
							/>
							<div {...getRootProps()} className="dropzone">
								<input {...getInputProps()} />
								{isDragActive ? (
									<p>Suelta los archivos aquí...</p>
								) : (
									<p>
										Arrastra y suelta algunos archivos aquí, o haz clic para
										seleccionarlos
									</p>
								)}
								<IoCloudUploadOutline size={24} />
							</div>
							<Button onClick={handleSubmit} text={<IoIosSend />} />
						</div>
						{files.length > 0 && (
							<div className="files-list">
								<h4>Archivos seleccionados:</h4>
								<ul>
									{files.map((file, index) => (
										<li key={index}>{file.name}</li>
									))}
								</ul>
							</div>
						)}
					</form>
				</div>
			</div>
		</>
	)
}

export default Chatbot
