// Import components
import ProgressCircle from "./ProgressCircle";
import { IoWarningOutline } from "react-icons/io5";

// Import propTypes
import PropTypes from "prop-types";

// Import Styling
import "./statbox.css";

const StatBox = ({ icon, data, title, subtitle, progress }) => {
  return (
    <div className="stats-box">
      {data >= 0 ? (
        <>
          <div className="info">
            {icon}
            <div className="title">
              <h4>{title}</h4>
            </div>
            <div className="subtitle">
              <p>{subtitle}</p>
            </div>
          </div>
          <div className="graph">
            <ProgressCircle progress={progress} />
            <div className="increase">
              <p>{data}</p>
            </div>
          </div>
        </>
      ) : (
        <div className="warning-no-data">
          <p>No hay datos disponibles aun!</p>
          <IoWarningOutline />
        </div>
      )}
    </div>
  );
};

StatBox.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  progress: PropTypes.number,
  subtitle: PropTypes.string,
  data: PropTypes.number,
};

export default StatBox;
