import { useState, useEffect } from "react"
import Select from "react-select"
import { getAllProviders, providerToProveedor, searchProviders } from "../../../services/providers"
import { getSellers, searchSeller } from "../../../services/sellers"
import { ToastContainer } from "react-toastify"
import { getAllProducts, searchProducts, productToProducto } from "../../../services/products"
import { getAllPaymentTypes } from "../../../services/paymentTypes" // Import the service to fetch payment methods
import { createSalesInvoice } from "../../../services/sales"
import { toggleToast } from "../modules/modules"
import Header from "../../common/Header/Header"

export default function SalesForm() {
	const [page, ] = useState(1)

	// Providers state
	const [providers, setProviders] = useState([])
	const [filter, setFilter] = useState("")
	const [selectedProvider, setSelectedProvider] = useState(null)

	// Sellers state
	const [sellers, setSellers] = useState([])
	const [sellerFilter, setSellerFilter] = useState("")
	const [selectedSeller, setSelectedSeller] = useState(null)

	// Products state
	const [products, setProducts] = useState([])
	const [productFilter, setProductFilter] = useState("")
	const [selectedProduct, setSelectedProduct] = useState(null)

	// List of added products with quantity and price
	const [addedProducts, setAddedProducts] = useState([])

	// Quantity and price for the current product
	const [quantity, setQuantity] = useState(1)
	const [price, setPrice] = useState(0)

	// Payment Methods state
	const [paymentMethods, setPaymentMethods] = useState([])
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
	const [paymentValue, setPaymentValue] = useState(0) // To capture payment value
	const [dueDate, setDueDate] = useState("") // To capture due date

	// Send to DIAN checkbox state
	const [sendToDIAN, setSendToDIAN] = useState(false)

	// Validation states
	const [errors, setErrors] = useState({})

	// Function to get providers based on filter
	const getProviders = async () => {
		let limit = window.innerWidth >= 1536 ? 10 : window.innerWidth >= 1280 ? 7 : 5
		let providersData

		if (filter !== "") {
			providersData = await searchProviders({ limit, page, search: filter })
		} else {
			providersData = await getAllProviders({ limit, page })
		}

		setProviders(providersData.map(providerToProveedor).reverse())
	}

	// Function to get sellers based on filter
	const getSellersData = async () => {
		let sellersData
		if (sellerFilter !== "") {
			sellersData = await searchSeller({ search: sellerFilter })
		} else {
			sellersData = await getSellers()
		}
		setSellers(sellersData.reverse())
	}

	// Function to get products based on filter
	const getProductsData = async () => {
		let productsData
		if (productFilter !== "") {
			productsData = await searchProducts({ search: productFilter, limit: 10, page })
		} else {
			productsData = await getAllProducts({ limit: 10, page })
		}
		setProducts(productsData.map(productToProducto).reverse())
	}

	// Function to get payment methods from the backend
	const getPaymentMethods = async () => {
		try {
			const paymentMethodsData = await getAllPaymentTypes()
			setPaymentMethods(paymentMethodsData) // Assuming the API returns an array
		} catch (error) {
			console.error("Error fetching payment methods:", error)
		}
	}

	// Fetch payment methods on component load
	useEffect(() => {
		getPaymentMethods()
	}, [])

	// Fetch providers when the filter or page changes
	useEffect(() => {
		getProviders()
	}, [filter])

	// Fetch sellers when the sellerFilter changes
	useEffect(() => {
		getSellersData()
	}, [sellerFilter])

	// Fetch products when the productFilter changes
	useEffect(() => {
		getProductsData()
	}, [productFilter])

	// Handlers for changing inputs
	const handleProviderChange = (selectedOption) => setSelectedProvider(selectedOption)
	const handleProviderInputChange = (inputValue) => setFilter(inputValue)

	const handleSellerChange = (selectedOption) => setSelectedSeller(selectedOption)
	const handleSellerInputChange = (inputValue) => setSellerFilter(inputValue)

	const handleProductChange = (selectedOption) => setSelectedProduct(selectedOption)
	const handleProductInputChange = (inputValue) => setProductFilter(inputValue)

	const handlePaymentMethodChange = (selectedOption) => setSelectedPaymentMethod(selectedOption)

	// Add selected product to the list with quantity and price
	const addProduct = () => {
		if (selectedProduct && quantity > 0 && price > 0) {
			setAddedProducts([...addedProducts, { ...selectedProduct, quantity, price }])
			setSelectedProduct(null) // Clear the selected product after adding
			setQuantity(1) // Reset quantity
			setPrice(0) // Reset price
		}
	}

	// Remove product from the list
	const removeProduct = (index) => {
		const newProducts = addedProducts.filter((_, i) => i !== index)
		setAddedProducts(newProducts)
	}

	// Handle DIAN checkbox change
	const handleDIANChange = (e) => {
		setSendToDIAN(e.target.checked)
	}

	// Validate fields before submission
	// Validate fields before submission
	const validateForm = () => {
		const newErrors = {}

		// Check if provider is selected
		if (!selectedProvider) newErrors.provider = "Debe seleccionar un proveedor."

		// Check if seller is selected
		if (!selectedSeller) newErrors.seller = "Debe seleccionar un vendedor."

		// Check if products are added
		if (addedProducts.length === 0) newErrors.products = "Debe agregar al menos un producto."

		// Check if payment method is selected
		if (!selectedPaymentMethod) newErrors.paymentMethod = "Debe seleccionar un método de pago."

		// Check if payment value is greater than zero
		if (paymentValue <= 0) newErrors.paymentValue = "El monto de pago debe ser mayor a cero."

		// Check if due date is provided
		if (!dueDate) newErrors.dueDate = "Debe ingresar una fecha de vencimiento."

		// Calculate the total price of the products
		const totalProductPrice = addedProducts.reduce((total, product) => {
			return total + product.price * product.quantity
		}, 0)

		// Validate if the total product price matches the payment value
		if (totalProductPrice !== paymentValue) {
			newErrors.paymentMismatch = `La suma de los productos ($${totalProductPrice}) no coincide con el monto del pago ($${paymentValue}).`
		}

		setErrors(newErrors)
		return Object.keys(newErrors).length === 0
	}

	// Function to create the sales invoice
	const createFv = async () => {
		if (!validateForm()) return

		const products = addedProducts.map((product) => ({
			code: product.value,
			quantity: product.quantity,
			price: product.price,
		}))

		const data = {
			customer: { identification: selectedProvider.value },
			seller: selectedSeller.code,
			items: products,
			date: new Date().toISOString().split("T")[0],
			payments: [
				{
					id: selectedPaymentMethod.value,
					value: paymentValue, // Capture the value the user entered
					due_date: dueDate, // Capture the due date
				},
			],
			stamp: {
				send: sendToDIAN,
			},
		}

		try {
			const response = await createSalesInvoice(data)
			console.log("Sale created:", response)
			toggleToast("Factura de venta creada con éxito")
		} catch (error) {
			console.error("Error creating sale:", error)
			throw error
		}
	}

	return (
		<>
			<Header title={"Crear factura de venta"} />
			<div className="h-20" />
			<div className="px-12 w-full pt-[3px] flex flex-col gap-4">
				<div className="mt-6">
					<label
						htmlFor="providerSelect"
						className="block text-sm font-medium text-gray-700"
					>
						Seleccionar Proveedor
					</label>

					<Select
						id="providerSelect"
						options={providers.map((provider) => ({
							value: provider.numDoc,
							label: `${provider.nombre} - ${provider.numDoc}`,
						}))}
						value={selectedProvider}
						onChange={handleProviderChange}
						onInputChange={handleProviderInputChange}
						isClearable
						placeholder="Escribe para buscar un proveedor..."
						noOptionsMessage={() => "No se encontraron resultados"}
					/>
					{errors.provider && (
						<p className="text-red-500 text-sm mt-2">{errors.provider}</p>
					)}
				</div>

				{/* Seller Select */}
				<div className="mt-6">
					<label
						htmlFor="sellerSelect"
						className="block text-sm font-medium text-gray-700"
					>
						Seleccionar Vendedor
					</label>

					<Select
						id="sellerSelect"
						options={sellers.map((seller) => ({
							value: seller.identification,
							code: seller.siigo_id,
							label: `${seller.first_name} ${seller.last_name} - ${seller.identification}`,
						}))}
						value={selectedSeller}
						onChange={handleSellerChange}
						onInputChange={handleSellerInputChange}
						isClearable
						placeholder="Escribe para buscar un vendedor..."
						noOptionsMessage={() => "No se encontraron resultados"}
					/>
					{errors.seller && <p className="text-red-500 text-sm mt-2">{errors.seller}</p>}
				</div>

				{/* Product Select */}
				<div className="mt-6">
					<label
						htmlFor="productSelect"
						className="block text-sm font-medium text-gray-700"
					>
						Seleccionar Producto
					</label>

					<Select
						id="productSelect"
						options={products.map((product) => ({
							value: product.code,
							label: `${product.name} - ${product.code}`,
						}))}
						value={selectedProduct}
						onChange={handleProductChange}
						onInputChange={handleProductInputChange}
						isClearable
						placeholder="Escribe para buscar un producto..."
						noOptionsMessage={() => "No se encontraron resultados"}
					/>

					{selectedProduct && (
						<div className="mt-4">
							<label className="block text-sm font-medium text-gray-700">
								Cantidad
							</label>
							<input
								type="number"
								className="mt-1 block w-full border border-gray-300 rounded-md"
								value={quantity}
								onChange={(e) => setQuantity(parseInt(e.target.value))}
								min={1}
							/>

							<label className="block text-sm font-medium text-gray-700">
								Precio
							</label>
							<input
								type="number"
								className="mt-1 block w-full border border-gray-300 rounded-md"
								value={price}
								onChange={(e) => setPrice(parseFloat(e.target.value))}
								min={0}
							/>

							<button
								type="button"
								className="mt-4 bg-blue-500 text-white py-2 px-4 rounded"
								onClick={addProduct}
							>
								Agregar producto
							</button>
						</div>
					)}
					{errors.products && (
						<p className="text-red-500 text-sm mt-2">{errors.products}</p>
					)}
				</div>

				{/* Added Products List */}
				<div className="mt-6">
					<h2 className="text-lg font-medium">Productos agregados:</h2>
					{addedProducts.length > 0 ? (
						<ul className="mt-4 space-y-4">
							{addedProducts.map((product, index) => (
								<li
									key={index}
									className="flex justify-between items-center border p-2 rounded"
								>
									<div>
										<p>{product.label}</p>
										<p>Cantidad: {product.quantity}</p>
										<p>Precio: {product.price}</p>
									</div>
									<button
										type="button"
										className="bg-red-500 text-white py-1 px-2 rounded"
										onClick={() => removeProduct(index)}
									>
										Eliminar
									</button>
								</li>
							))}
						</ul>
					) : (
						<p>No se han agregado productos.</p>
					)}
				</div>

				{/* Payment Method Select */}
				<div className="mt-6">
					<label
						htmlFor="paymentMethodSelect"
						className="block text-sm font-medium text-gray-700"
					>
						Seleccionar Método de Pago
					</label>

					<Select
						id="paymentMethodSelect"
						options={paymentMethods.map((method) => ({
							value: method.id, // Assuming each payment method has an `id` field
							label: method.name, // Assuming each payment method has a `name` field
						}))}
						value={selectedPaymentMethod}
						onChange={handlePaymentMethodChange}
						isClearable
						placeholder="Escribe para buscar un método de pago..."
						noOptionsMessage={() => "No se encontraron resultados"}
					/>
					{errors.paymentMethod && (
						<p className="text-red-500 text-sm mt-2">{errors.paymentMethod}</p>
					)}
					{errors.paymentMismatch && (
						<p className="text-red-500 text-sm mt-2">{errors.paymentMismatch}</p>
					)}

					{selectedPaymentMethod && (
						<div className="mt-4">
							<h2 className="text-lg font-medium">Método de pago seleccionado:</h2>
							<p>{selectedPaymentMethod.label}</p>
							<label className="block text-sm font-medium text-gray-700 mt-4">
								Monto del pago
							</label>
							<input
								type="number"
								className="mt-1 block w-full border border-gray-300 rounded-md"
								value={paymentValue}
								onChange={(e) => setPaymentValue(parseFloat(e.target.value))}
								min={0}
							/>
							{errors.paymentValue && (
								<p className="text-red-500 text-sm mt-2">{errors.paymentValue}</p>
							)}

							<label className="block text-sm font-medium text-gray-700 mt-4">
								Fecha de vencimiento
							</label>
							<input
								type="date"
								className="mt-1 block w-full border border-gray-300 rounded-md"
								value={dueDate}
								onChange={(e) => setDueDate(e.target.value)}
							/>
							{errors.dueDate && (
								<p className="text-red-500 text-sm mt-2">{errors.dueDate}</p>
							)}
						</div>
					)}
				</div>

				{/* Checkbox for DIAN */}
				<div className="mt-6 flex gap-4">
					<input
						type="checkbox"
						className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
						checked={sendToDIAN}
						onChange={handleDIANChange}
					/>
					<label className="block text-sm font-medium text-gray-700">
						Enviar factura a la DIAN
					</label>
					{sendToDIAN && (
						<p className="mt-2 text-green-600">La factura será enviada a la DIAN.</p>
					)}
				</div>

				{/* Create Invoice Button */}
				<button
					className="rounded-md transition-all hover:shadow-md hover:scale-105 text-white text-center py-2 px-4 bg-green-600"
					onClick={createFv}
				>
					Crear factura
				</button>
				<ToastContainer />
			</div>
		</>
	)
}
