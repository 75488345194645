import propTypes from "prop-types"
import { cn } from "../../../utils/twMerge.js"

export default function FormSelectInput({
	id,
	label,
	value,
	options,
	className,
	onChange,
	disabled = false,
}) {
	options = [null, ...options]

	return (
		<div className={cn("relative", className)}>
			<select
				id={id}
				name={id}
				value={value}
				className="border-b w-full border-gray-300 py-1 focus:border-b-2 focus:border-[#006e25] transition-colors focus:outline-none peer bg-inherit"
				disabled={disabled}
				onChange={onChange}
			>
				{options.map((val) => (
					<option key={val} value={val}>
						{val}
					</option>
				))}
			</select>
			<label
				htmlFor={id}
				className={cn(
					"absolute w-full left-0 -z-10 -translate-y-4 cursor-text text-xs peer-focus:-translate-y-4 transition-all peer-focus:text-[#006e25] text-gray-500",
					!value && "translate-y-1 text-base"
				)}
			>
				{label}
			</label>
		</div>
	)
}

FormSelectInput.propTypes = {
	id: propTypes.string.isRequired,
	label: propTypes.string,
	value: propTypes.string,
	options: propTypes.array.isRequired,
	className: propTypes.string,
	onChange: propTypes.func,
	disabled: propTypes.bool,
}
