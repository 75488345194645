// Import Compoments
import { Table } from "../../table/table.jsx"

// Import Helpers
import { getDetailedData } from "../../../../utils/helpers"

// Import propTypes
import propTypes from "prop-types"

// Import Styles
import "./data_extraction.css"

export const DataExtraction = ({ show, fileData }) => {
	if (!show) {
		return null
	}
	return (
		<div className="data-table">
			<div className="">
				<h2>Extracción de Datos</h2>
				<div className="data-extraction">
					<Table
						Id={fileData.entity}
						CustomerName={fileData.customerName}
						CustomerAddress={fileData.customerAddress}
						CustomerId={fileData.customerId}
						CustomerCity={fileData.customerCity}
						DueDate={fileData.dueDate}
						IssueDate={fileData.issueDate}
						supplierId={fileData.supplierId}
						InvoiceTax={fileData.invoiceTax}
						InvoiceId={fileData.invoiceId}
						InvoiceTotal={fileData.invoiceTotal}
						Items={getDetailedData(fileData.items)}
						VendorAddress={fileData.vendorAddress}
						VendorName={fileData.vendorName}
						VendorNit={fileData.vendorNit}
						VendorCity={fileData.vendorCity}
					/>
				</div>
			</div>
		</div>
	)
}

DataExtraction.propTypes = {
	show: propTypes.bool.isRequired,
	fileData: propTypes.object.isRequired,
}
