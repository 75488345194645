// Import propTypes
import PropTypes from "prop-types";

const ProgressCircle = ({ progress = "0.75", size = "40" }) => {
  const angle = progress * 360;

  return (
    <div
      className="progress-circle"
      style={{
        background: `radial-gradient(white 55%, transparent 56%), conic-Gradient(from 0deg at 50% 50%, var(--graph-progress) 0deg ${angle}deg, var(--graph-remaining) ${angle}deg 360deg)`,
        borderRadius: "50%",
        width: `${size}px`,
        height: `${size}px`,
      }}
    ></div>
  );
};

ProgressCircle.propTypes = {
  progress: PropTypes.number,
  size: PropTypes.string,
};

export default ProgressCircle;
