import { useState } from "react"
import { Document, Page } from "react-pdf"
// Import validation
import PropTypes from "prop-types"

// Import Styles
import "react-pdf/dist/esm/Page/AnnotationLayer.css"
import "./pdfcomp.css"

export const PdfComp = ({ pdfFile }) => {
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [scale, setScale] = useState(1.0) // For zooming

	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages)
		setPageNumber(1) // Reset to first page when document loads
	}

	// Navigation functions
	const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1)
	const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1)

	// Zoom functions
	const zoomIn = () => setScale(scale + 0.5)
	const zoomOut = () => setScale(scale - 0.5 > 0 ? scale - 0.5 : 0.5)

	return (
		<div className="pdf-div mx-auto">
			<div className="controls">
				<button onClick={goToPrevPage} disabled={pageNumber <= 1}>
					Anterior
				</button>
				<button onClick={goToNextPage} disabled={pageNumber >= numPages}>
					Siguiente
				</button>
				<button onClick={zoomOut} disabled={scale <= 0.5}>
					-
				</button>
				<span>{(scale * 100).toFixed(0)}%</span>
				<button onClick={zoomIn} disabled={scale >= 3}>
					+
				</button>
			</div>
			<p className="page-info">
				Página {pageNumber} de {numPages}
			</p>
			<Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess} className="pdf-document">
				<Page
					pageNumber={pageNumber}
					scale={scale}
					renderTextLayer={false}
					renderAnnotationLayer={false}
					className="pdf-page"
				/>
			</Document>
		</div>
	)
}

PdfComp.propTypes = {
	pdfFile: PropTypes.string.isRequired,
}
