import PropTypes from "prop-types";
import Button from "../../Buttons/Button";
import { Each } from "../../../../utils/Each";

export const ToolOptionsPopUp = ({ options, active , onClose}) => {
  if (!active) return null;

  return (
    <div className={`popup ${!active ? "inactive" : "active"}`}>
      <Each
        data={options}
        render={(option, index) => (
          <>
            <div className="popup-inner">
              <Button key={index} text={option.text} onClick={option.onClick} />
            </div>
            <hr />
          </>
        )}
      />
    </div>
  );
};

ToolOptionsPopUp.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ).isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
