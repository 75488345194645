import { useState, useEffect } from "react"
import { DotLoader } from "react-spinners"

// import svg
import { MdErrorOutline } from "react-icons/md"

import propTypes from "prop-types"

import "./loader.css"

export const Loader = ({ text, handleError }) => {
	return (
		<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
			{!handleError ? (
				<>
					<DotLoader size={125} color={"#00b23e"} loading={true} />
					<p>Cargando...</p>
				</>
			) : (
				<>
					<MdErrorOutline />
					<p>{text}</p>
				</>
			)}
		</div>
	)
}

export const BarLoader = () => {
	const [progress, setProgress] = useState(0)
	const [currentTip, setCurrentTip] = useState(0)

	const financeTips = [
		"Diversify your portfolio to reduce risk.",
		"Invest for the long term to reap the benefits of compounding.",
		"Regularly review your investments to align with your financial goals.",
		"Consider low-cost index funds for a well-rounded investment.",
		"Emergency funds are crucial for financial security.",
		"Invest in your financial education to make informed decisions.",
	]

	useEffect(() => {
		const progressInterval = setInterval(() => {
			setProgress((prevProgress) => {
				const newProgress = prevProgress + 10
				return newProgress > 100 ? 100 : newProgress
			})
		}, 1000)

		const tipsInterval = setInterval(() => {
			setCurrentTip((prevTip) => (prevTip + 1) % financeTips.length)
		}, 4000) // Change the tip every 4 seconds

		return () => {
			clearInterval(progressInterval)
			clearInterval(tipsInterval)
		}
	}, [])

	return (
		<div className="loader-container">
			<div className="loader-bar-background">
				<div className="loader-bar-progress" style={{ width: `${progress}%` }} />
			</div>
			<p className="loader-tip">{financeTips[currentTip]}</p>
		</div>
	)
}

Loader.propTypes = {
	text: propTypes.string,
	handleError: propTypes.bool,
}
