import { useRef, useState } from "react";

// Import Components
import Button from "../../Buttons/Button";
import { toggleToast } from "../../../pages/modules/modules";

// Import Services
import { depositDebt } from "../../../../services/invoice";

// Import Prop Types
import PropTypes from "prop-types";

export const PayPopUp = ({ show, onClose, data }) => {
  const [form, setForm] = useState({
    amount: 0,
    paymentType: "",
    paymentDate: new Date().toISOString().split("T")[0],
  });
  const [errors, setErrors] = useState({});

  const popupContentRef = useRef(null);

  const handleBackdropClick = (e) => {
    if (
      popupContentRef.current &&
      !popupContentRef.current.contains(e.target)
    ) {
      e.stopPropagation(); 
      onClose();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handlePayment = async () => {
    try {
     
      await depositDebt(data.entity, form);
      toggleToast("Abono realizado con éxito");
      onClose();
    } catch (error) {
      setErrors({ response: "El valor abonado es mayor al de la deuda" });
      console.error("Error depositing debt:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!form.amount || form.amount <= 0) {
      newErrors.amount = "Please enter a valid amount.";
    }
    if (!form.paymentType) {
      newErrors.paymentType = "Please select a payment method.";
    }
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the amount and payment method
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});

    handlePayment(); // Added to trigger payment on submit
  };

  if (!show) {
    return null;
  }

  return (
    <div className="popup-backdrop" onClick={handleBackdropClick}>
      <div className="popup-content-no-click" ref={popupContentRef} onClick={(e) => e.stopPropagation()}>
        <h2>Abonar factura</h2>

        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="amount">Valor:</label>
            <input
              type="number"
              name="amount"
              value={form.amount}
              onChange={handleChange}
            />
            {errors.amount && (
              <span className="error-message">{errors.amount}</span>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="paymentType">Código:</label>
            <select
              name="paymentType"
              value={form.paymentType}
              onChange={handleChange}
            >
              <option value="">Seleccione un método de pago</option>
              <option value="CASH">Efectivo</option>
              <option value="BANK_TRANSFER">Transferencia Bancaria</option>
              <option value="CREDIT_CARD">Tarjeta de crédito</option>
              <option value="DEBIT_CARD">Tarjeta débito</option>
            </select>
            {errors.paymentType && (
              <span className="error-message">{errors.paymentType}</span>
            )}
          </div>
          <div className="input-group">
            <label htmlFor="paymentDate">Fecha de abono</label>
            <input
              type="date"
              name="paymentDate"
              value={form.paymentDate}
              onChange={handleChange}
            />
          </div>
          <div className="error-message" style={{ margin: "1rem 0" }}>
            {errors.response}
          </div>

          <Button text="Pagar" type="submit" />
        </form>
      </div>
    </div>
  );
};

PayPopUp.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object, // Marked as required
};
