
// websocketService.js
class WebSocketService {
    constructor(url) {
        this.url = url;
        this.websocket = null;
        this.messageHandlers = [];
        this.errorHandler = null;
    }

    connect() {
        this.websocket = new WebSocket(this.url);

        this.websocket.onmessage = (event) => {
            console.log("Event data:", event.data);
            try {
                const data = JSON.parse(event.data);
                this.messageHandlers.forEach((handler) => handler(data));
            } catch (error) {
                console.warn("Error parsing message, treating as plain text:", event.data);
                this.messageHandlers.forEach((handler) => handler({ message: event.data }));
            }
        };

        this.websocket.onerror = (error) => {
            if (this.errorHandler) this.errorHandler(error);
        };
    }

    sendMessage(message, fileContent = null, fileName = null) {
        if (this.websocket.readyState === WebSocket.OPEN) {
            const payload = { message };
            if (fileContent && fileName) {
                payload.file = fileContent;
                payload.fileName = fileName;
            }
            this.websocket.send(JSON.stringify(payload));
        } else {
            console.error("WebSocket is not open");
        }
    }

    onMessage(handler) {
        this.messageHandlers.push(handler);
    }

    onError(handler) {
        this.errorHandler = handler;
    }

    close() {
        if (this.websocket) {
            this.websocket.close();
        }
    }
}

const websocketService = new WebSocketService("wss://ws0nr9l7-8000.use2.devtunnels.ms/ws/chatbot");
export default websocketService;
