import { useRef, useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useNavigate } from "react-router-dom"

//import SVG
import { IoCloudUploadOutline, IoWarning } from "react-icons/io5"
import { FaCheck, FaTimes, FaBan } from "react-icons/fa"

//import services
import { uploadFile } from "../../../../services/file"

// Import Redux actions
import { useDispatch, useSelector } from "react-redux"
import { addInvoice, clearInvoices } from "../../../../redux/invoice.slice"

// Import Util Components
import { Each } from "../../../../utils/Each"
import Button from "../../Buttons/Button"

// Import PropTypes
import propTypes from "prop-types"
import "./dragNdrop.css" // We'll create this CSS file next for animations

export const DragNdrop = ({ show, onClose, setShowProgress, setFileStatuses }) => {
	const popupContentRef = useRef(null) // Create a ref for the popup content

	const dispatch = useDispatch()
	const navigation = useNavigate()

	const invoices = useSelector((state) => state.invoice.invoices)

	const handleBackdropClick = (e) => {
		// Check if the click is on the backdrop (and not on the popup content itself)
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			onClose() // Close the popup if the click is outside the popup content
		}
	}

	// Handle The PDF File Upload
	const handleFile = async (file) => {
		const formData = new FormData()
		formData.append("file", file)

		const fileType = file.type

		if (fileType === "application/zip" || fileType === "application/x-zip-compressed") {
			return handleZipFile(formData)
		} else {
			return {
				status: 415,
				message: "Tipo de archivo no soportado",
			}
		}
	}

	// Handles the file upload and error handling
	const handleZipFile = async (formData) => {
		try {
			// Upload the file
			const result = await uploadFile(formData)
			const { transactionEntity, invoice } = result.data

			return {
				status: result.status,
				path: transactionEntity.path,
				invoiceId: transactionEntity._id,
				data: invoice,
			}
		} catch (error) {
			console.error("Error in handleZipFile:", error)

			// Handle 400 error (error in the file)
			if (error.response.status === 400) {
				return {
					status: error.response.status,
					message: "Hay un error al subir el archivo",
				}
			}

			return {
				status: error.response ? error.response.status : 500,
				message: error.message || "An error occurred",
			}
		}
	}

	const onDrop = useCallback(
		(acceptedFiles) => {
			onClose()
			setShowProgress(true)
			acceptedFiles.forEach(async (file, index) => {
				setFileStatuses((prevStatuses) => [
					...prevStatuses,
					{ name: file.name, status: "loading" },
				])
				const result = await handleFile(file)
				switch (result.status) {
					case 200:
						result.message = "Archivo subido con exito"
						break
					case 201:
						result.message = "Archivo subido con una advertencia"
						break
					case 202:
						result.message = "Archivo requiere de atención"
						break
					case 409:
						result.message = "Archivo duplicado"
						break
					default:
						break
				}

				// Dispatch the result to the store
				dispatch(
					addInvoice({
						status: result.status,
						name: file.name,
						description: result.message,
						invoiceId: result.invoiceId,
					})
				)
				// Update the status of the file in the popup
				setFileStatuses((prevStatuses) => {
					const newStatuses = [...prevStatuses]
					newStatuses[index] = {
						...newStatuses[index],
						status: result.status,
						message: result.message || "Subido con exito",
						invoiceId: result.invoiceId,
					}
					return newStatuses
				})
			})
		},
		[setShowProgress, setFileStatuses, handleFile]
	)
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		multiple: true,
	})

	if (!show) {
		return null
	}

	return (
		<div className="popup-backdrop" onClick={handleBackdropClick}>
			{invoices.length > 0 ? (
				<div className="popup-content" ref={popupContentRef}>
					{/* File rendering */}
					<Each
						data={invoices}
						render={(invoice, index) => (
							<div key={index} className="files-loader">
								<h3 className="file-name">{invoice.name}</h3>
								{invoice.status === "loading" && (
									<PulseLoader
										size={10}
										color={"var(--primary-color)"}
										loading={true}
									/>
								)}
								{invoice.status === 200 && <FaCheck color="var(--primary-color)" />}
								{invoice.status === 201 && <IoWarning color="#e5be01" size={25} />}
								{invoice.status === 202 && <FaTimes color="red" size={25} />}
								{invoice.status === 409 && <FaBan color="red" size={25} />}
								<p>{invoice.description}</p>
								<Button
									text=">"
									onClick={() => navigation(`/get-report/${invoice.invoiceId}`)}
								/>
							</div>
						)}
					/>
					<Button text="Subir más facturas" onClick={() => dispatch(clearInvoices())} />
				</div>
			) : (
				<div className="popup-content" ref={popupContentRef}>
					<div
						{...getRootProps()}
						className={`dragNdrop ${isDragActive ? "is-drag-active" : ""}`}
					>
						{isDragActive && <div className="wave"></div>}
						<IoCloudUploadOutline />
						<p>Suelta el nombre de tu archivo acá</p>
					</div>
					<input {...getInputProps()} />
					<p>Suelta tus archivos acá o da clic para seleccionarlos</p>
				</div>
			)}
		</div>
	)
}

DragNdrop.propTypes = {
	show: propTypes.bool.isRequired,
	onClose: propTypes.func.isRequired,
	setShowProgress: propTypes.func.isRequired,
	setFileStatuses: propTypes.func.isRequired,
}
