import axios from "axios";
import Cookies from "js-cookie";

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/paymentTypes'; // Replace with your API base URL

export async function getAllPaymentTypes(){
    try {
        const response = await axios.get(`${VITE_BASE_URL}`, {
            headers: {
                Authorization: `Bearer ${Cookies.get("token")}`,
            },
        });
        return response.data;
    } catch (error) {
        console.error("Error reading paymentTypes:", error);
        throw error;
    }
}



