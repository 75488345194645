import { configureStore } from '@reduxjs/toolkit';

// Import Reducers
import userReducer from './userSlice';
import invoiceSlice from './invoice.slice';
import notificationsSlice from './notifications.slice';
import providersSlice from './providers.slice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        invoice: invoiceSlice,
        notifications: notificationsSlice,
        providers: providersSlice
    }
});