import propTypes from "prop-types"

//Import utils
import { Each } from "../../../utils/Each"

export const TableComponent = ({ data, ActionComponent, onClick }) => {
	if (!data || data.length === 0) {
		return <p>No hay información disponible</p>
	}
	const headers = Object.keys(data[0]).filter((header) => header !== "Url") //

	return (
		<table className="table" style={{ marginBottom: "1rem" }}>
			<thead>
				<tr>
					<Each
						data={headers}
						render={(header, headerIndex) => {
							return <th key={headerIndex}>{header}</th>
						}}
					/>
					{ActionComponent && <th></th>} {/* Add header for actions */}
				</tr>
			</thead>
			<tbody>
				<Each
					data={data}
					render={(item, itemIndex) => (
						<tr key={itemIndex} onClick={() => onClick(item)}>
							{headers.map((header, headerIndex) => (
								<td key={headerIndex}>{item[header]}</td>
							))}
							{ActionComponent && (
								<td>
									<ActionComponent item={item} />
								</td>
							)}
						</tr>
					)}
				/>
			</tbody>
		</table>
	)
}

TableComponent.propTypes = {
	data: propTypes.array.isRequired,
	ActionComponent: propTypes.elementType,
	onClick: propTypes.func,
}
