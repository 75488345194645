import { useState } from "react"
import { useEffect } from "react"
import Header from "../../common/Header/Header"
import PrivatePageLayout from "../../layouts/PrivatePageLayout"

export default function Orders() {
	const [orders, setOrders] = useState([{ id: 1, name: "order 1" }])
	const [loading, setLoading] = useState(false)

	useEffect(() => {}, [])

	return (
		<>
			<Header title={"Órdenes de compra"} />
			<div className="h-20" />
			<main className="px-12 w-full pt-[3px]">
				<section>
					{orders.length === 0 && !loading ? (
						<div className="flex justify-center items-center h-[50vh]">
							<h1 className="text-2xl text-neutral-800">No hay órdenes de compra</h1>
						</div>
					) : (
						<>
							{loading ? (
								<></>
							) : (
								<>
									{orders.map((order) => (
										<div key={order.id} className="flex flex-col gap-4">
											<div className="flex justify-between items-center">
												<h1 className="text-xl text-neutral-800">
													Orden de compra
												</h1>
												<h1 className="text-xl text-neutral-800">
													{order.id}
												</h1>
											</div>
										</div>
									))}
								</>
							)}
						</>
					)}
				</section>
			</main>
		</>
	)
}
