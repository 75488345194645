import propTypes from "prop-types"
import { cn } from "../../../utils/twMerge.js"

export default function FormTextInput({
	id,
	label,
	value,
	className,
	onChange,
	number = false,
	disabled = false,
}) {
	return (
		<div className={cn("relative", className)}>
			<input
				id={id}
				name={id}
				type={number ? "number" : "text"}
				placeholder=""
				value={value}
				className="border-b w-full border-gray-300 disabled:text-gray-500 py-1 focus:border-b-2 focus:border-[#006e25] transition-colors focus:outline-none peer bg-inherit"
				disabled={disabled}
				onChange={onChange}
			/>
			<label
				htmlFor={id}
				className="absolute w-full left-0 -translate-y-4 cursor-text text-xs peer-focus:-translate-y-4 transition-all peer-focus:text-[#006e25] peer-placeholder-shown:translate-y-1 text-gray-500 peer-placeholder-shown:text-base"
			>
				{label}
			</label>
		</div>
	)
}

FormTextInput.propTypes = {
	id: propTypes.string.isRequired,
	label: propTypes.string,
	value: propTypes.any,
	className: propTypes.string,
	number: propTypes.bool,
	disabled: propTypes.bool,
	onChange: propTypes.func,
}
