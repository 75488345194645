export const SucessMessage = () => {
  return (
    <div className="login-register">
      <div className="custom-container">
        <form>
          <h1>Email enviado de forma exitosa</h1>
          <p>Revisa tu Email, Hemos enviado un link para restablecer la contraseña</p>
        </form>
      </div>
    </div>
  );
};
