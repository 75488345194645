import axios from "axios"

const API_URL = import.meta.env.VITE_BASE_URL + "/api/user"

// Create a new user
export const createUser = async (userData) => {
	try {
		const response = await axios.post(`${API_URL}/create`, userData)
		return response
	} catch (error) {
		console.error("Error creating user:", error)
		throw error
	}
}

//Login user
export const loginUser = async (userData) => {
	const { email, password } = userData
	try {
		const response = await axios.post(`${API_URL}/login`, {
			email,
			password,
		})
		return response
	} catch (error) {
		console.error("Error authenticating user:", error)
		throw error
	}
}

// Verify user
export const authOTP = async (token, otp) => {
	try {
		const response = await axios.post(`${API_URL}/auth-otp`, {
			otp,
			token,
		})
		return response
	} catch (error) {
		console.error("Error authenticating user:", error)
		throw error
	}
}

//Authenticating user
export const authToken = async (token) => {
	try {
		const response = await axios.get(`${API_URL}/auth`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		return response
	} catch (error) {
		console.error("Error:", error)
	}
}

//Reset password
export const resetPassword = async (userData) => {
	const { email } = userData
	try {
		const response = await axios.post(`${API_URL}/reset-password`, {
			email,
		})
		return response
	} catch (error) {
		console.error("Error resetting password:", error)
		throw error
	}
}

// Update password
export const updatePassword = async (token, password) => {
	try {
		const response = await axios.post(`${API_URL}/update-password`, {
			token,
			password,
		})
		return response
	} catch (error) {
		console.error("Error updating password:", error)
		throw error
	}
}

// Get all users
export const getUsers = async () => {
	try {
		const response = await axios.get(API_URL)
		return response.data
	} catch (error) {
		console.error("Error getting users:", error)
		throw error
	}
}

// Get a user by ID
export const getUserById = async (userId) => {
	try {
		const response = await axios.get(`${API_URL}/${userId}`)
		return response.data
	} catch (error) {
		console.error("Error getting user:", error)
		throw error
	}
}

export const updatePreferences = async (token, preferences) => {
	try {
		const response = await axios.put(`${API_URL}/preferences`, preferences, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error updating preferences:", error)
		throw error
	}
}

// Update a user
export const updateUser = async (token, userData) => {
	try {
		const response = await axios.put(`${API_URL}/update`, userData, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
		return response.data
	} catch (error) {
		console.error("Error updating user:", error)
		throw error
	}
}
// Update a userNew
export const updateUserNew = async (token) => {
	try {
		const response = await axios.put(
			`${API_URL}/update`,
			{},
			{
				headers: {
					Authorization: `Bearer ${token}`,
				},
			}
		)
		return response
	} catch (error) {
		console.error("Error updating user:", error)
		throw error
	}
}

// Delete a user
export const deleteUser = async (userId) => {
	try {
		const response = await axios.delete(`${API_URL}/${userId}`)
		return response.data
	} catch (error) {
		console.error("Error deleting user:", error)
		throw error
	}
}

export const verifyUser = async (token) => {
	try {
		const response = await axios.get(`${API_URL}/verify/${token}`)
		return response
	} catch (error) {
		console.error("Error verifying user:", error)
		throw error
	}
}
