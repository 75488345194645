import propTypes from "prop-types"
import { cn } from "../../../utils/twMerge.js"
import { useState } from "react"

let cancelBlur = false

export default function Combobox({
	id,
	label,
	className,
	onSelect,
	value,
	number = false,
	disabled = false,
	values = [],
}) {
	const [filter, setFilter] = useState("")
	const [focus, setFocus] = useState(false)

	const changeHandler = (e) => {
		if (e.key === "Enter") {
			cancelBlur = true
			onSelect(e.target.value, true)
		} else if (e.key === "Escape") {
			setFilter("")
			setFocus(false)
			e.target.blur()
		}
	}

	const setVal = (val) => {
		setFilter(val)
		onSelect(val, false)
	}

	return (
		<div className={cn("relative", className)}>
			<input
				id={id}
				name={id}
				type={number ? "number" : "text"}
				placeholder=""
				value={filter}
				defaultValue={value}
				onChange={(e) => {
					setFilter(e.target.value)
					onSelect(e.target.value, false)
				}}
				className="border-b w-full border-gray-300 disabled:text-gray-500 py-1 focus:border-b-2 focus:border-[#006e25] transition-colors focus:outline-none peer bg-inherit"
				disabled={disabled}
				onKeyDown={changeHandler}
				onFocus={() => setFocus(true)}
				onBlur={(e) => {
					if (!cancelBlur && e.relatedTarget?.name !== "pucButton") {
						setFilter("")
						setFocus(false)
					} else {
						e.target.focus()
					}
					cancelBlur = false
				}}
			/>
			<label
				htmlFor={id}
				className="absolute w-full left-0 -translate-y-4 cursor-text text-xs peer-focus:-translate-y-4 transition-all peer-focus:text-[#006e25] peer-placeholder-shown:translate-y-1 text-gray-500 peer-placeholder-shown:text-base"
			>
				{label}
			</label>
			{focus && (
				<div className="absolute max-h-32 w-full flex flex-col bg-white shadow-lg translate-y-2 z-10 overflow-y-scroll">
					{values
						.filter((val) => val.startsWith(filter))
						.map((value) => (
							<div
								key={value}
								className="p-2 hover:bg-gray-100 cursor-pointer z-20 w-full"
							>
								<button
									name="pucButton"
									className="w-full"
									onClick={() => setVal(value)}
								>
									{value}
								</button>
							</div>
						))}
				</div>
			)}
		</div>
	)
}

Combobox.propTypes = {
	id: propTypes.string.isRequired,
	label: propTypes.string,
	value: propTypes.string,
	className: propTypes.string,
	number: propTypes.bool,
	disabled: propTypes.bool,
	onSelect: propTypes.func.isRequired,
	values: propTypes.array,
}
