import { useEffect, useState } from "react";
import OtpInputWithValidation, {ResetForm} from "./Reset-form";
import { ResetConfirmation } from "./Reset-confirmation";
import { useParams } from "react-router-dom";
import { authToken } from "../../../services/user";
import "./Reset.css";

export const ResetController = () => {
  const { token } = useParams();
  const [page, setPage] = useState("reset");

  useEffect(() => {
    const verifyToken = async () => {
      try {
        const verifyTokenData = await authToken(token);
        if (verifyTokenData.status === 200) {
          setPage("reset");
        }
      } catch (error) {
        console.log(error);
        setPage("invalid");
      }
    };
    verifyToken();
  }, [token]);

  function navigateComponent() {
    switch (page) {
      case "reset":
        return <OtpInputWithValidation setPage={setPage}/>;
      case "reset-form":
        return <ResetForm setPage={setPage}/>;
      case "confirmation":
        return <ResetConfirmation />;
      case "invalid":
        return <InvalidResetPage />;
      default:
        return <OtpInputWithValidation setPage={setPage}/>;
    }
  }

  return navigateComponent();
};

const InvalidResetPage = () => {
  return (
    <div className="login-register">
      <div className="custom-container">
        <form>
          <h1>Link Corrupto</h1>
          <p className="invalid-otp">Porfavor intenta de nuevo más tarde o contacta con el administrador</p>
        </form>
      </div>
    </div>
  );
};
