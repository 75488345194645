import axios from "axios"
import Cookies from "js-cookie"

const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + "/api/integrations" // Replace with your API base URL

export const createIntegration = async (integration) => {
    try {
        const response = await axios.post(`${VITE_BASE_URL}`, integration, {
            headers: {
                'Authorization': `Bearer ${Cookies.get('token')}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating item:', error);
        throw error;
    }
}

export const authorizeIntegration = async (integration) => {
	const { apiKey, apiSecret } = integration
	try {
		const response = await axios.post(
			`${VITE_BASE_URL}/authorize`,
			{
				apiKey,
				apiSecret,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")} `,
				},
			}
		)
		return response
	} catch (error) {
		console.error("Error creating item:", error)
		return error
	}
}

export const getIntegrationbyUID = async () => {
	try {
		const response = await axios.get(`${VITE_BASE_URL}`, {
			headers: { Authorization: `Bearer ${Cookies.get("token")}` },
		})
		return response.data
	} catch (error) {
		console.error("Error getting integrations:", error)
		throw error
	}
}

export const updateIntegration = async (integration) => {
	const { apiKey, apiSecret, name } = integration
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}`,
			{
				apiKey,
				apiSecret,
				name,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error updating item:", error)
		throw error
	}
}

export const updateInventoryIntegration = async (integration) => {
	const { apiKey, apiSecret, name } = integration
	try {
		const response = await axios.put(
			`${VITE_BASE_URL}/inventory`,
			{
				apiKey,
				apiSecret,
				name,
			},
			{
				headers: {
					Authorization: `Bearer ${Cookies.get("token")}`,
				},
			}
		)
		return response.data
	} catch (error) {
		console.error("Error updating item:", error)
		throw error
	}
}
