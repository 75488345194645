import PropTypes from "prop-types";

export const Pagination = ({
  totalItems,
  itemsPerPage,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (page) => {
    if (page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const generatePageNumbers = () => {
    const pageNumbers = [];
    const pageLimit = 6;

    if (totalPages <= pageLimit) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      const startPages = [1, 2];
      const endPages = [totalPages - 2, totalPages - 1, totalPages];

      if (currentPage > 4 && currentPage < totalPages - 3) {
        const middlePages = [currentPage - 1, currentPage, currentPage + 1];
        pageNumbers.push(
          ...startPages,
          "...",
          ...middlePages,
          "...",
          ...endPages
        );
      } else if (currentPage <= 4) {
        pageNumbers.push(...startPages, "...", ...endPages);
      } else if (currentPage >= totalPages - 3) {
        pageNumbers.push(...startPages, "...", ...endPages);
      }
    }

    return pageNumbers;
  };

  const pageNumbers = generatePageNumbers();

  return (
    <div className="pagination">
      <button
        onClick={() => handleClick(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {pageNumbers.map((page, index) => (
        <button
          key={index}
          onClick={() => handleClick(page)}
          className={currentPage === page ? "active" : ""}
          disabled={page === "..."}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handleClick(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

Pagination.propTypes = {
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};
