import { Outlet, Navigate } from "react-router-dom";
import Cookies from "js-cookie";

export const PrivateRoutes = () => {
  const token = Cookies.get("token");
  return token ? <Outlet /> : <Navigate to="/login" />;
};

export const PublicRoutes = () => {
  const token = Cookies.get("token");
  return token ? <Navigate to="/" /> : <Outlet />;
}

