import { BrowserRouter, Route, Routes } from "react-router-dom"
import PrivatePageLayout from "../components/layouts/PrivatePageLayout"
import AccountVerification from "../components/pages/accountVerification/AccountVerification"
import ActualInventory from "../components/pages/actualInventory/ActualInventory"
import Chatbot from "../components/pages/chatbot/Chatbot"
import DebtSummary from "../components/pages/debtSummary/DebtSummary"
import FinancialTriage from "../components/pages/financialTriage/FinancialTriage"
import { ForgotPassword } from "../components/pages/Forgot_password/ForgotPassword"
import Inventory from "../components/pages/inventory/Inventory"
import Invoices from "../components/pages/invoices/Invoices"
import { Login } from "../components/pages/login/Login"
import { Modules } from "../components/pages/modules/modules"
import Notifications from "../components/pages/notifications/Notifications"
import Orders from "../components/pages/orders/Orders"
import Profile from "../components/pages/profile/Profile"
import ProviderBySales from "../components/pages/providerBySales/providerBySales"
import ProvidersCRUD from "../components/pages/providers/ProvidersCRUD"
import { ResetController } from "../components/pages/resetPassword/Reset"
import SalesForm from "../components/pages/saleForm/SaleForm"
import Sales from "../components/pages/sales/Sales"
import { TransactionEntity } from "../components/pages/transactionEntity/TransactionEntity"
import { WorkSpaceEntities } from "../components/pages/work_space_entities/workSpaceEntities"
import { PrivateRoutes, PublicRoutes } from "../utils/PrivateRoute"

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				{/* Imported the public Routes */}
				<Route element={<PublicRoutes />}>
					<Route path="/login" element={<Login />} />
					{/* <Route path="/register" element={<RegisterController />} /> */}
					<Route path="/forgot-password" element={<ForgotPassword />} />
					<Route path="/password-reset/:token" element={<ResetController />} />
					<Route path="/verify/:token" element={<AccountVerification />} />
				</Route>
				{/* Imported the private Routes */}
				<Route
					element={
						<PrivatePageLayout>
							<PrivateRoutes />
						</PrivatePageLayout>
					}
				>
					<Route path="/" element={<FinancialTriage />} />

					{/* Workflow Routes */}
					<Route path="/modules" element={<Modules />} />
					<Route path="/modules/:id" element={<WorkSpaceEntities />} />
					<Route path="/modules/data/:module/:id" element={<ActualInventory />} />

					<Route path="/debt-summary" element={<DebtSummary />} />

					<Route path="/get-report/:id" element={<TransactionEntity />} />

					<Route path="/chatbot" element={<Chatbot />} />
					<Route path="/providers" element={<ProvidersCRUD />} />
					<Route path="/profile" element={<Profile />} />
					<Route path="/inventory" element={<Inventory />} />
					<Route path="/notifications" element={<Notifications />} />
					<Route path="/orders" element={<Orders />} />
					<Route path="/sales" element={<ProviderBySales />} />
					<Route path="/sales/:id" element={<Sales />} />
					<Route path="/sales/form" element={<SalesForm />} />
					<Route path="/invoices" element={<Invoices />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default Routing
