import axios from 'axios';
import Cookies from 'js-cookie';


const VITE_BASE_URL = import.meta.env.VITE_BASE_URL + '/api/modules';

// Create
export const createModule = async (name, code) => {
  try {
    const response = await axios.post(`${VITE_BASE_URL}`, {
      name,
      code
    }, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};

// Get all workspaces
export const getModules = async () => {
  try {
    const response = await axios.get(`${VITE_BASE_URL}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    return response.data;
  }
  catch (error) {
    console.error('Error getting workspaces:', error);
    throw error;
  }
}


//Update a module
export const updateModule = async (id, name, code) => {
  try {
    const response = await axios.put(`${VITE_BASE_URL}/${id}`, {
      name,
      code
    }, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    return response.data;
  }
  catch (error) {
    console.error('Error updating module:', error);
    throw error;
  }
}

// Delete a module
export const deleteModule = async (id) => {
  try {
    const response = await axios.delete(`${VITE_BASE_URL}/${id}`, {
      headers: {
        'Authorization': `Bearer ${Cookies.get('token')}`
      }
    });
    return response.data;
  }
  catch (error) {
    console.error('Error deleting module:', error);
    throw error;
  }
}