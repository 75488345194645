import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import Cookies from "js-cookie"

import Header from "../../common/Header/Header"

// Import SVGs
import { MdOutlinePointOfSale } from "react-icons/md"
import { RiFolderUploadLine } from "react-icons/ri"
import { FaRegClock } from "react-icons/fa"

// Import Components
import StatBox from "../../common/Progress/StatBox"
import BarChart from "../../common/barchart/Barchart"
import { UserObjective } from "../../common/popUps/userObjective/userObjective"

// Import Services
import { updateUserNew } from "../../../services/user"
import { getInvoiceCount, getWeeklyInvoiceSummary } from "../../../services/dashboard"

// Import Utils
import { ToastContainer } from "react-toastify"

// Import Styles
import "react-toastify/dist/ReactToastify.css"
import "./financialTriage.css"

function FinancialTriage() {
	const user = useSelector((state) => state.user)
	const [show, setShow] = useState(user.newUser)
	const [timeSaved, setTimeSaved] = useState(0)
	const [data, setData] = useState(null)
	const [stats, setStats] = useState({
		count: 0,
		uploaded: 0,
	})

	useEffect(() => {
		setShow(user.newUser)
	}, [user.newUser])

	useEffect(() => {
		setUpStats()
		weekleyInfoChart()
	}, [])

	const setUpStats = async () => {
		try {
			const { count, uploaded } = await getInvoiceCount() // Obtiene el número de facturas
			setStats({
				count: count,
				uploaded: uploaded,
			})
			setTimeSaved(4 * count)
		} catch (error) {
			console.error("Error setting up stats:", error)
		}
	}

	const updateNewUser = async () => {
		try {
			const response = await updateUserNew(Cookies.get("token"))
			return response
		} catch (error) {
			console.error("Error updating user:", error)
			throw error
		}
	}

	const weekleyInfoChart = async () => {
		try {
			const response = await getWeeklyInvoiceSummary()
			console.log("response: ", response)
			setData(response)
			return response
		} catch (error) {
			console.error("Error fetching weekly invoice summary:", error)
			throw error
		}
	}

	return (
		<>
			<Header title={"Panel de datos"} />
			<div className="h-20" />
			<UserObjective
				show={show}
				onClose={() => {
					updateNewUser()
					setShow(false)
				}}
			/>
			<div>
				<ToastContainer />
				<div className="holder p-4">
					<div className="w-full flex justify-between mt-4 gap-8">
						<StatBox
							icon={<RiFolderUploadLine />}
							data={stats.count}
							title={"Facturas subidas a Bucks"}
							subtitle={""}
							progress={0}
						/>
						<StatBox
							icon={<MdOutlinePointOfSale />}
							data={stats.uploaded}
							title={"Facturas causadas"}
							subtitle={""}
							progress={0}
						/>
						<StatBox
							icon={<FaRegClock />}
							data={timeSaved}
							title={"Tiempo Ahorrado en minutos"}
							subtitle={""}
							progress={0}
						/>
					</div>
					<div className="shadow-md w-full flex flex-col gap-4">
						<div className="content w-full p-4 grid xl:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 grid-rows-1 gap-4">
							<div className="item flex flex-col justify-center items-center gap-4 p-4 rounded-lg w-full">
								<BarChart data={data} />
							</div>
							<div className="item flex flex-col justify-center items-center gap-4 p-4 rounded-lg w-full bg-[--tertiary]">
								<h1>Proximamente</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default FinancialTriage
