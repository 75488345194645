import { useNavigate } from "react-router-dom"
import { useRef } from "react"

// Import Loader
import { PulseLoader } from "react-spinners"

// Import SVG
import { FaCheck, FaTimes, FaBan } from "react-icons/fa"
import { IoWarning } from "react-icons/io5"

// Import components
import Button from "../../Buttons/Button"

// Import Utils
import { Each } from "../../../../utils/Each"

// Import PropTypes
import propTypes from "prop-types"

// Import Styles
import "./filesprogress.css"

export const FilesProgress = ({ show, onClose, data, setData }) => {
	const popupContentRef = useRef(null) // Create a ref for the popup content
	const navigation = useNavigate()
	const handleBackdropClick = (e) => {
		// Check if the click is on the backdrop (and not on the popup content itself)
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			setData([])
			onClose()
			// Close the popup if the click is outside the popup content
		}
	}

	if (!show) {
		return null
	}

	return (
		<div className="popup-backdrop" onClick={handleBackdropClick}>
			<div className="popup-content" ref={popupContentRef}>
				<Each
					data={data}
					render={(file, index) => (
						<div key={index} className="files-loader">
							<h3 className="file-name">{file.name}</h3>
							{file.status === "loading" && (
								<PulseLoader
									size={10}
									color={"var(--primary-color)"}
									loading={true}
								/>
							)}
							{file.status === 200 && <FaCheck color="var(--primary-color)" />}
							{file.status === 201 && <IoWarning color="#e5be01" size={25} />}
							{file.status === 202 && <FaTimes color="red" size={25} />}
							{file.status === 409 && <FaBan color="red" size={25} />}
							<p>{file.message}</p>
							<Button
								text=">"
								onClick={() => navigation(`/get-report/${file.invoiceId}`)}
							/>
						</div>
					)}
				/>
			</div>
		</div>
	)
}

FilesProgress.propTypes = {
	show: propTypes.bool.isRequired,
	onClose: propTypes.func.isRequired,
	data: propTypes.array.isRequired,
	setData: propTypes.func.isRequired,
}
