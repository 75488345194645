import { useState } from "react";
import { ForgotPasswordForm } from "./ForgotPass-form";
import { SucessMessage } from "./ForgotPass-confirmation";

export const ForgotPassword = () => {
  const [page, setPage] = useState("forgot-password");

  function navigateComponent() {
    switch (page) {
      case "forgot-password":
        return (
          <ForgotPasswordForm setPage={setPage}/>
        );
      case "confirmation":
        return <SucessMessage />;
      default:
        return <ForgotPasswordForm setPage={setPage}/>;
    }
  }


  return (
    navigateComponent()
  );
};
