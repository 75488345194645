import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useNavigate, useParams } from "react-router-dom"
// Services
import { getSalesInvoices } from "../../../services/sales"

import { TableComponent } from "../../common/tableComponent/tableComponent"
import Header from "../../common/Header/Header"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user
)

const frequencyTranslations = {
	monthly: "Mensual",
	annual: "Anual",
	fortnightly: "Quincenal",
}

export default function Sales() {
	const user = useSelector(selectUser)
	let formattedDate = "Sin fecha registrada"
	const { id } = useParams()
	const [preferences, setPreferences] = useState({
		lastPull: "---",
		pullFrequency: "---",
	})
	const [paginationPreferences, setPaginationPreferences] = useState({
		currentPage: 1,
		limit: 10,
		customer: id,
	})
	const [salesInvoices, setSalesInvoices] = useState([])
	const [totalPages, setTotalPages] = useState(1)

	const transformSalesInvoices = (salesInvoices) => {
		return salesInvoices.map((invoice) => {
			return {
				Número: invoice.name,
				Fecha: new Date(invoice.date).toLocaleDateString("es-ES"),
				Cliente: invoice.customer,
				Total: invoice.total,
				Url: invoice.public_url,
			}
		})
	}
	const getSales = async () => {
		try {
			const response = await getSalesInvoices(paginationPreferences)
			if (response && response.salesInvoices) {
				setSalesInvoices(response.salesInvoices)
				setTotalPages(Math.ceil(response.total / paginationPreferences.limit))
			} else {
				setSalesInvoices([])
				setTotalPages(1)
			}
		} catch (error) {
			console.error("Error getting sales invoices:", error)
			setSalesInvoices([])
			setTotalPages(1)
		}
	}

	const handleLimitChange = (event) => {
		const newLimit = parseInt(event.target.value, 10)
		setPaginationPreferences((prev) => ({
			...prev,
			limit: newLimit,
			currentPage: 1, // Reiniciar a la primera página al cambiar el límite
		}))
	}

	const handlePreviousPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.max(prev.currentPage - 1, 1),
		}))
	}
	const handleRowClick = (item) => {
		console.log("Row clicked:", item)
		if (item.Url) {
			window.open(item.Url, "_blank")
		}
	}

	const handleNextPage = () => {
		setPaginationPreferences((prev) => ({
			...prev,
			currentPage: Math.min(prev.currentPage + 1, totalPages),
		}))
	}

	useEffect(() => {
		const lastSalesPull = user.preferences?.lastSalesPull
		let frequency = user.preferences?.salesPullConcurrency || "---"
		if (lastSalesPull) {
			const date = new Date(lastSalesPull)
			if (!isNaN(date.getTime())) {
				formattedDate = date.toLocaleString("es-ES", {
					year: "numeric",
					month: "long",
					day: "numeric",
				})
			}
		}
		const translatedFrequency = frequencyTranslations[frequency] || "---"

		setPreferences({
			lastPull: formattedDate,
			pullFrequency: translatedFrequency,
		})
	}, [user])
	useEffect(() => {
		getSales()
	}, [paginationPreferences])

	return (
		<>
			<Header />
			<div className="h-20" />
			<div className="px-12 w-full pt-[3px]">
				<div className="flex flex-col gap-4 w-full ">
					<div className="flex justify-between items-center h-16 ">
						<h1 className="text-[2rem] text-neutral-800">Ventas</h1>
					</div>
					<hr className="border-black" />
				</div>
				<div className="flex flex-col gap-4 m-4 shadow-lg focus:border-2 border-gray-300 p-5 rounded-xl transition-all ">
					<div className="flex flex-col gap-4">
						<h2>Última recolección de información: {preferences.lastPull}</h2>
						<h2>
							Frecuencia de recolección de información: {preferences.pullFrequency}
						</h2>
					</div>
					<div className="w-full flex items-center gap-4">
						<div className="w-auto">
							<label
								htmlFor="limit"
								className="block text-sm font-medium text-gray-700"
							>
								Límite de registros por página:
							</label>
							<select
								id="limit"
								value={paginationPreferences.limit}
								onChange={handleLimitChange}
								className="mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
								style={{ width: "120px" }}
							>
								<option value={5}>5</option>
								<option value={10}>10</option>
								<option value={15}>15</option>
								<option value={20}>20</option>
								<option value={25}>25</option>
							</select>
						</div>
					</div>
				</div>
				<div className="mt-4">
					{salesInvoices.length > 0 ? (
						<TableComponent
							data={transformSalesInvoices(salesInvoices)}
							onClick={handleRowClick}
						/>
					) : (
						<p>No hay facturas disponibles.</p>
					)}
				</div>
				<div className="flex justify-center items-center mt-4 gap-4">
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === 1
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handlePreviousPage}
						disabled={paginationPreferences.currentPage === 1}
					>
						Anterior
					</button>
					<span>
						Página {paginationPreferences.currentPage} de {totalPages}
					</span>
					<button
						className={`bg-[--complementary-color] text-white px-4 py-2 rounded-md cursor-pointer ${
							paginationPreferences.currentPage === totalPages
								? "cursor-not-allowed bg-gray-300"
								: ""
						}`}
						onClick={handleNextPage}
						disabled={paginationPreferences.currentPage === totalPages}
					>
						Siguiente
					</button>
				</div>
			</div>
		</>
	)
}
