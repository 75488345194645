import { useEffect, useRef } from "react"
import Button from "../../Buttons/Button"
import PropTypes from "prop-types"
import Cookies from "js-cookie"
import "./headerPop.css"
import { useNavigate } from "react-router-dom"

export const HeaderPop = ({ trigger, setTriggers }) => {
	const menuRef = useRef(null)
	const navigate = useNavigate()

	const btn = document.getElementById("showProfile")

	useEffect(() => {
		const handler = (e) => {
			if (menuRef.current) {
				if (!menuRef.current.contains(e.target) && !btn.contains(e.target)) {
					setTriggers((prevTriggers) => ({
						...prevTriggers,
						header: false,
					}))
				}
			}
		}
		document.addEventListener("mousedown", handler)
		return () => {
			document.removeEventListener("mousedown", handler)
		}
	}, [])

	return (
		<div className={`popup ${trigger ? "active" : "inactive"}`} id="header" ref={menuRef}>
			<div className="popup-inner">
				<Button
					text="Perfil"
					onClick={() => {
						window.scrollTo({ top: 0 })
						navigate("/profile")
						setTriggers((prevTriggers) => ({
							...prevTriggers,
							header: false,
						}))
					}}
				/>
			</div>
			<hr />
			<div className="popup-inner">
				<Button
					text="Logout"
					onClick={() => {
						Cookies.remove("token")
						window.location.reload()
						window.scrollTo({ top: 0 })
					}}
				/>
			</div>
		</div>
	)
}

HeaderPop.propTypes = {
	trigger: PropTypes.bool,
	setTriggers: PropTypes.func,
}
