import propTypes from "prop-types"
import { TbAlertTriangle, TbExclamationCircle, TbInfoCircle } from "react-icons/tb"

export default function Notification({ notification }) {
	return (
		<div
			className="m-4 flex w-[90%] rounded-lg bg-white p-4 text-gray-500 shadow transition-transform"
			id="toast-warning"
		>
			{notification.priority === "info" && (
				<div className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-blue-300/50">
					<TbInfoCircle className="text-blue-500 h-5 w-5" />
				</div>
			)}
			{notification.priority === "warning" && (
				<div className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-orange-300/50">
					<TbAlertTriangle className="text-orange-500 h-5 w-5" />
				</div>
			)}
			{notification.priority === "error" && (
				<div className="inline-flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-lg bg-red-300/50">
					<TbExclamationCircle className="text-red-500 h-5 w-5" />
				</div>
			)}
			<div className="flex flex-col ml-3 text-sm font-normal gap-1 w-[70%]">
				<h1 className="text-black">{notification.name}</h1>
				<p className="max-h-10 overflow-hidden truncate">{notification.description}</p>
			</div>
		</div>
	)
}

Notification.propTypes = {
	notification: propTypes.object.isRequired,
}
