import { useRef, useEffect, useState, useCallback } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { createSelector } from "reselect"
import { useSelector } from "react-redux"
import { FaBars, FaTimes } from "react-icons/fa"
import { FaClipboardList, FaFileInvoiceDollar, FaMoneyBills, FaTag } from "react-icons/fa6"
import {
	TbTriangleInvertedFilled,
	TbHomeFilled,
	TbCoinFilled,
	TbUserFilled,
	TbArchiveFilled,
	TbShoppingCartFilled,
	TbBookmarksFilled,
} from "react-icons/tb"
import logo from "/logo.svg"

import Button from "../Buttons/Button"
import "./Sidebar.css"
import { cn } from "../../../utils/twMerge"

const selectUser = createSelector(
	(state) => state.user,
	(user) => user
)

export const Sidebar = () => {
	const [toggle, setToggle] = useState(false)
	const location = useLocation()
	const [toggleCausacion, setToggleCausacion] = useState(false)

	const navRef = useRef()
	const activeBtn = useRef()
	const navigate = useNavigate()
	const user = useSelector(selectUser)

	const showNavBar = useCallback(() => {
		navRef.current.classList.toggle("responsive_side")
		activeBtn.current.classList.toggle("desactive")
	}, [])

	useEffect(() => {
		if (window.innerWidth <= 1024) {
			navRef.current.classList.toggle("responsive_side")
			activeBtn.current.classList.toggle("desactive")
			setToggle(true)
		}
	}, [toggle])

	return (
		<div className="sidebarholder sticky top-0">
			<div className="responsive-btns">
				<div className="burger" ref={activeBtn}>
					<Button svg={<FaBars />} onClick={showNavBar} />
				</div>
			</div>
			<div
				className="sidebar h-full bg-[--secondary] px-8 py-8 overflow-y-scroll"
				ref={navRef}
			>
				<div className="responsive-btns">
					<div className="close">
						<Button svg={<FaTimes />} onClick={showNavBar} />
					</div>
				</div>
				<title className="mb-6 text-4xl text-white flex gap-4 items-center font-[FoundersGrotesk]">
					<img src={logo} alt="Bucks" className="w-16 h-16" />
					<span className="mt-3">Bucks</span>
				</title>
				<div className="flex flex-col gap-2 text-center">
					<h2 className="text-[--primary] text-2xl">¡Hola {user.name}!</h2>
					<h3 className="text-xl text-[--tertiary] text-balance">
						¿En qué vamos a trabajar hoy?
					</h3>
					<hr className="border-[--separator] mb-4 mx-auto w-2/3" />
				</div>
				<nav className="flex flex-col gap-4 mt-8">
					{/* Existing sidebar items */}
					<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
						<TbHomeFilled size={20} />
						<button
							className={cn(
								"px-2 hover:border-white/30 border rounded-full -m-px",
								location.pathname === "/"
									? "border-gradient hover:border-transparent"
									: "border-transparent"
							)}
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/")
							}}
						>
							Inicio
						</button>
					</div>
					<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
						<TbCoinFilled size={20} />
						<button
							className={cn(
								"px-2 hover:border-white/30 border rounded-full -m-px",
								location.pathname === "/modules"
									? "border-gradient hover:border-transparent"
									: "border-transparent"
							)}
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/modules")
							}}
						>
							Causación
						</button>
						<button
							className="p-1 rounded-md z-10"
							onClick={() => setToggleCausacion(!toggleCausacion)}
						>
							<TbTriangleInvertedFilled
								size={12}
								className={`transition-transform ${toggleCausacion ? "rotate-0" : "-rotate-90"}`}
							/>
						</button>
					</div>
					{toggleCausacion && (
						<div className="border-b-black flex flex-col ml-5 px-2 hover:border-white/30 gap-4">
							<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
								<FaMoneyBills size={20} />
								<button
									className={cn(
										"px-2 hover:border-white/30 border rounded-full -m-px",
										location.pathname === "/debt-summary"
											? "border-gradient hover:border-transparent"
											: "border-transparent"
									)}
									onClick={() => {
										window.scrollTo({ top: 0 })
										navigate("/debt-summary")
									}}
								>
									Deudas
								</button>
							</div>
							<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
								<FaFileInvoiceDollar size={20} />
								<button
									className={cn(
										"px-2 hover:border-white/30 border rounded-full -m-px",
										location.pathname === "/invoices"
											? "border-gradient hover:border-transparent"
											: "border-transparent"
									)}
									onClick={() => {
										window.scrollTo({ top: 0 })
										navigate("/invoices")
									}}
								>
									Facturas no enviadas
								</button>
							</div>
						</div>
					)}
					<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
						<TbUserFilled size={20} />
						<button
							className={cn(
								"px-2 hover:border-white/30 border rounded-full -m-px",
								location.pathname === "/providers"
									? "border-gradient hover:border-transparent"
									: "border-transparent"
							)}
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/providers")
							}}
						>
							Gestión de proveedores
						</button>
					</div>
					<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
						<TbArchiveFilled size={20} />
						<button
							className={cn(
								"px-2 hover:border-white/30 border rounded-full -m-px",
								location.pathname === "/inventory"
									? "border-gradient hover:border-transparent"
									: "border-transparent"
							)}
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/inventory")
							}}
						>
							Gestión de inventario
						</button>
					</div>
					<div className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center">
						<TbShoppingCartFilled size={20} />
						<button
							className={cn(
								"px-2 hover:border-white/30 border rounded-full -m-px",
								location.pathname === "/sales"
									? "border-gradient hover:border-transparent"
									: "border-transparent"
							)}
							onClick={() => {
								window.scrollTo({ top: 0 })
								navigate("/sales")
							}}
						>
							Ventas
						</button>
					</div>
					<div
						className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center cursor-not-allowed"
						title="Coming Soon!"
					>
						<FaTag className="text-gray-400 cursor-not-allowed" size={20} />
						<button
							className="px-2 hover:border-white/30 text-gray-400 cursor-not-allowed"
							disabled
							onClick={() => navigate("/orders")}
						>
							Órdenes de compra
						</button>
					</div>
					<div
						className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center cursor-not-allowed"
						title="Coming Soon!"
					>
						<FaClipboardList className="text-gray-400 cursor-not-allowed" size={20} />
						<button
							className="px-2 hover:border-white/30 text-gray-400 cursor-not-allowed"
							disabled
							onClick={() => {}}
						>
							Nómina
						</button>
					</div>

					<div
						className="rounded-xl flex py-1 text-[--tertiary] gap-2 items-center cursor-not-allowed"
						title="Coming Soon!"
					>
						<TbBookmarksFilled className="text-gray-400 cursor-not-allowed" size={20} />
						<button
							className="px-2 hover:border-white/30 text-gray-400 cursor-not-allowed"
							disabled
							onClick={() => {}}
						>
							Estados financieros
						</button>
					</div>
				</nav>
			</div>
		</div>
	)
}
