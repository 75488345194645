import { useRef, useState, useEffect } from "react"
import propTypes from "prop-types"
import Button from "../../Buttons/Button"
import { getInvoicePayments } from "../../../../services/invoice"
import { TableComponent } from "../../tableComponent/tableComponent"
import moment from "moment"

export const PaymentHistoryPopUp = ({ show, data, onClose }) => {
	const [historyData, setHistoryData] = useState([])
	const popupContentRef = useRef(null)

	useEffect(() => {
		if (show && data) {
			// Fetch payment history data
			const fetchHistoryData = async () => {
				try {
					const response = await getInvoicePayments(data.entity)
					const flattenData = response.map((item) => ({
						"Fecha": moment(item.date).format("DD/MM/YYYY"),
						"Cuenta Puc credito": item.paymentCodes.credit,
						"Cuenta Puc debito": item.paymentCodes.debit,
						"Medio de pago": item.paymentType,
						"Cantidad abonada": item.amount,
					}))
					setHistoryData(flattenData)
				} catch (error) {
					console.error("Error fetching payment history:", error)
				}
			}

			fetchHistoryData()
		}
	}, [show, data])

	const handleBackdropClick = (e) => {
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			e.stopPropagation()
			onClose()
		}
	}

	return show ? (
		<div className="popup-backdrop" onClick={handleBackdropClick}>
			<div
				className="popup-content-no-click"
				ref={popupContentRef}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="flex flex-col gap-2 items-center">
					<h2>Historial de pagos</h2>

					{historyData.length > 0 ? (
						<TableComponent data={historyData} />
					) : (
						<p>No payment history available.</p>
					)}
				</div>
			</div>
		</div>
	) : null
}

PaymentHistoryPopUp.propTypes = {
	show: propTypes.bool.isRequired,
	data: propTypes.object.isRequired,
	onClose: propTypes.func.isRequired,
}
