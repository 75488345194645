import { useRef } from "react"
import Button from "../../Buttons/Button"

import propTypes from "prop-types"

export const ConfirmationOptionPopUp = ({ show, confirmation, message, onClose }) => {
	const popupContentRef = useRef(null)
	// Comment out handleBackdropClick for testing
	const handleBackdropClick = (e) => {
		if (popupContentRef.current && !popupContentRef.current.contains(e.target)) {
			onClose()
		}
	}

	if (!show) {
		return null
	}

	return (
		<div className="popup-backdrop" onClick={handleBackdropClick}>
			<div className="popup-content" ref={popupContentRef}>
				<h2>{message}</h2>
				<div className="options">
					<Button text="Confirmar" onClick={confirmation} />
					<Button text="Cancelar" onClick={onClose} />
				</div>
			</div>
		</div>
	)
}

ConfirmationOptionPopUp.propTypes = {
	show: propTypes.bool,
	confirmation: propTypes.func,
	message: propTypes.string,
	onClose: propTypes.func,
}
