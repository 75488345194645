import { useState } from "react"
import Button from "../../common/Buttons/Button"
import { resetPassword } from "../../../services/user"
import propTypes from "prop-types"
import { TbMail } from "react-icons/tb"

export const ForgotPasswordForm = ({ setPage }) => {
	const [forgotPasswordForm, setForgotPasswordForm] = useState({
		email: "",
	})
	const [formError, setFormError] = useState(null)

	const handleFormChange = (e) => {
		setForgotPasswordForm({
			...forgotPasswordForm,
			[e.target.name]: e.target.value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		// Check for blank spaces
		if (forgotPasswordForm.email.trim() === "") {
			setFormError("La dirección de correo no puede estar vacia")
			return
		}

		// Email format validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
		if (!emailRegex.test(forgotPasswordForm.email)) {
			setFormError("Porfavor introduce una dirección de correo valida.")
			return
		}

		// Send email
		handleForgotPassword()

		// Reset form error
		setFormError(null)
	}

	const handleForgotPassword = async () => {
		try {
			const res = await resetPassword(forgotPasswordForm)
			if (res.status === 200) {
				setPage("confirmation")
			} else {
				setFormError("Hubo un problema enviando el email. Porfavor intenta más tarde.")
			}
		} catch (error) {
			setFormError("Hubo un problema enviando el email. Porfavor intenta más tarde.")
			console.error(error)
		}
	}

	return (
		<div className="h-screen relative">
			<div className="p-8 border border-neutral-300 rounded-lg absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 shadow-sm">
				<form
					className="flex flex-col justify-center items-center gap-4"
					onSubmit={handleSubmit}
				>
					<h1>Olvidé mi contraseña</h1>
					<div className="flex flex-col gap-2">
						<label htmlFor="forgot-pass" className="text-sm font-medium text-gray-700">Email</label>
            <div className="relative border border-gray-200 rounded-lg p-2">
              <input
                id="forgot-pass"
                name="email"
                type="text"
                className="w-full pl-10 pr-10 outline-none"
                placeholder="Correo electrónico"
                onChange={handleFormChange}
              />
							<TbMail className="absolute left-2.5 top-2 h-6 w-6 text-gray-400" />
						</div>
					</div>
					{formError && <p className="error-message">{formError}</p>}
					<Button text="Enviar" />
				</form>
			</div>
		</div>
	)
}

ForgotPasswordForm.propTypes = {
	setPage: propTypes.func.isRequired,
}
