import { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import PropTypes from "prop-types"

const BarChart = ({ data = [] }) => {
	const [transformedData, setTransformedData] = useState([])

	useEffect(() => {
		if (!Array.isArray(data) || data.length === 0) {
			return
		}
		const transformData = () => {
			const daysOfWeek = [
				"Domingo",
				"Lunes",
				"Martes",
				"Miércoles",
				"Jueves",
				"Viernes",
				"Sábado",
			]

			const invoicesPerDay = Array(7).fill(0)

			data.forEach((invoice) => {
				const dayIndex = invoice.dayOfWeek - 1
				invoicesPerDay[dayIndex] = invoice.totalInvoices
			})

			const result = daysOfWeek.map((day, index) => ({
				x: day,
				y: invoicesPerDay[index],
			}))

			setTransformedData(result)
		}

		transformData()
	}, [data])

	const options = {
		chart: {
			type: "bar",
			height: "100%", // Makes chart height responsive
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "45%",
				endingShape: "rounded",
			},
		},
		dataLabels: {
			enabled: true,
			formatter: function (value) {
				return value
			},
		},
		xaxis: {
			categories: transformedData.map((item) => item.x),
			labels: {
				rotate: -30,
				style: {
					fontSize: "12px",
				},
			},
		},
		yaxis: {
			labels: {
				formatter: function (value) {
					return value.toFixed(0)
				},
			},
			title: {
				text: "Número de Facturas por día",
			},
			min: 0,
		},
		colors: ["#006e25", "#00b23e"],
		// Responsive breakpoints
		responsive: [
			{
				breakpoint: 1000, // Adjust settings for screens < 1000px wide
				options: {
					plotOptions: {
						bar: {
							columnWidth: "60%", // Adjust column width on smaller screens
						},
					},
					xaxis: {
						labels: {
							rotate: -45, // Rotate labels more on smaller screens
							style: {
								fontSize: "10px", // Smaller font size for x-axis labels
							},
						},
					},
				},
			},
			{
				breakpoint: 600, // Further adjustment for smaller screens (mobile)
				options: {
					plotOptions: {
						bar: {
							columnWidth: "80%", // Make bars wider on small screens
						},
					},
					xaxis: {
						labels: {
							rotate: -60, // Rotate labels even more for readability
							style: {
								fontSize: "8px", // Even smaller font size
							},
						},
					},
					dataLabels: {
						enabled: false, // Disable data labels on very small screens
					},
				},
			},
		],
	}

	const series = [
		{
			name: "Facturas Subidas",
			data: transformedData.map((item) => item.y),
		},
	]

	return (
		<div className="w-full h-full p-1">
			<div className="chart">
				<Chart options={options} series={series} type="bar" />
			</div>
		</div>
	)
}

BarChart.propTypes = {
	data: PropTypes.array,
}

export default BarChart
