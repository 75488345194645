import { useContext, useEffect, useState } from "react"
import {
	TbAlertTriangle,
	TbCircleOff,
	TbFilter,
	TbInboxOff,
	TbX,
	TbInfoCircle,
	TbExclamationCircle,
	TbReload,
	TbChevronDown,
	TbChevronUp,
} from "react-icons/tb"
import { ReactReduxContext, useDispatch } from "react-redux"
import { clearNotifications, syncNotifications } from "../../../redux/notifications.slice"
import {
	getAllNotificationsByFilter,
	setAllNotificationsAsCanceled,
	setNotificationsAsCanceled,
} from "../../../services/notifications"
import NotificationsFilterPopUp from "../../common/popUps/notificationsPopup/NotificationsFilterPopup"

import { useNavigate } from "react-router-dom"
import PrivatePageLayout from "../../layouts/PrivatePageLayout"
import Header from "../../common/Header/Header"

export default function Notifications() {
	const dispatch = useDispatch()
	const { store } = useContext(ReactReduxContext)
	const [showConfirm, setShowConfirm] = useState(false)
	const [notifications, setNotifications] = useState(store.getState().notifications.notifications)
	const [expandedIds, setExpandedIds] = useState([]) // Track expanded notifications

	const navigate = useNavigate()

	const defaultFilters = {
		type: { provider: true, inventory: true },
		status: { pending: true, done: false, dismissed: false },
		date: { from: "", to: "" },
		priority: { info: true, warning: true, error: true },
	}

	const [showFilter, setShowFilter] = useState(false)
	const [filters, setFilters] = useState(defaultFilters)

	const fetchNotifications = async () => {
		const filter = {
			type: filters.type,
			status: filters.status,
			date: {
				from: filters.date.from ? filters.date.from : null,
				to: filters.date.to ? filters.date.to : null,
			},
			priority: {
				info: filters.priority.info,
				warning: filters.priority.warning,
				error: filters.priority.error,
			},
		}

		const newNotifications = await getAllNotificationsByFilter(filter)
		console.log("newNotifications", newNotifications)
		setNotifications(newNotifications)
	}

	useEffect(() => {
		fetchNotifications()
	}, [dispatch, filters, showConfirm])

	// Toggle the expanded state of a notification
	const toggleExpand = (id) => {
		setExpandedIds((prev) =>
			prev.includes(id) ? prev.filter((itemId) => itemId !== id) : [...prev, id]
		)
	}

	const handleStepCompletion = async (notificationId, stepId) => {
		try {
			const notification = notifications.find((n) => n._id === notificationId)

			const step = notification.steps.find((s) => s._id === stepId)

			switch (step.type) {
				case "provider":
					navigate("/providers", { state: { provider: step.data.supplier } })
					break

				case "puc":
					navigate("/providers", { state: { provider: step.data.supplier } })
					break

				case "item":
					navigate("/inventory", { state: { item: step.data.item } })
					break

				default:
					console.error("Unknown step type")
					return
			}

			const updatedSteps = notification.steps.map((step) =>
				step._id === stepId ? { ...step, completed: true } : step
			)

			const updatedNotification = { ...notification, steps: updatedSteps }
			// await updateNotificationStep(notificationId, updatedSteps)

			setNotifications((prev) =>
				prev.map((n) => (n._id === notificationId ? updatedNotification : n))
			)
		} catch (error) {
			console.error("Error completing step:", error)
		}
	}

	return (
		<>
			<Header title={"Notificaciones"} />
			<div className="h-20" />
			<main className="w-[95%] xl:w-2/3 2xl:w-1/2 mx-auto">
				<div
					className={`${showConfirm ? "block" : "hidden"} fixed w-screen h-screen bg-neutral-800/10 top-0 left-0 z-20`}
				>
					<div className="relative bg-white border border-neutral-300 p-4 rounded-lg top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col gap-8 w-1/4">
						<button
							className="absolute top-2 right-2"
							onClick={() => setShowConfirm(false)}
						>
							<TbX className="text-black hover:text-neutral-700" size={24} />
						</button>
						<h1 className="text-center">¿Estás seguro?</h1>
						<div className="flex flex-col gap-4 text-center">
							<p>¿Estás seguro de que quieres borrar todas las notificaciones?</p>
						</div>
						<div className="flex gap-4 justify-center text-center">
							<button
								className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg hover:bg-green-500/30 w-28"
								onClick={() => {
									setAllNotificationsAsCanceled()
									dispatch(clearNotifications())
									setShowConfirm(false)
									setFilters(defaultFilters)
								}}
							>
								Confirmar
							</button>
							<button
								className="bg-red-500/20 text-red-700 border border-red-500/25 rounded-lg hover:bg-red-500/30 w-28"
								onClick={() => setShowConfirm(false)}
							>
								Cancelar
							</button>
						</div>
					</div>
				</div>
				<div></div>
				<section className="mt-8 rounded-lg border border-neutral-300 min-h-[70svh] shadow">
					<div className="flex gap-4 justify-evenly border-b border-neutral-300 py-2">
						<button
							className="flex gap-2 items-center px-2 py-1 hover:bg-neutral-100 rounded-md"
							onClick={async () => {
								fetchNotifications()
								dispatch(
									syncNotifications(
										await getAllNotificationsByFilter({
											type: { provider: true, inventory: true },
											status: { pending: true },
											priority: { info: true, warning: true, error: true },
										})
									)
								)
							}}
						>
							<TbReload />
							Sincronizar
						</button>
						<div className="relative flex flex-col">
							<button
								id="filterDialogBtn"
								className="flex gap-2 items-center px-2 py-1 hover:bg-neutral-100 rounded-md"
								onClick={() => {
									setShowFilter(!showFilter)
								}}
							>
								<TbFilter />
								Filtrar
							</button>
							<NotificationsFilterPopUp
								show={showFilter}
								setShow={setShowFilter}
								filters={filters}
								setFilters={setFilters}
							/>
						</div>
						<button
							className="flex gap-2 items-center px-2 py-1 hover:bg-neutral-100 rounded-md"
							onClick={() => setShowConfirm(true)}
						>
							<TbCircleOff />
							Borrar todo
						</button>
					</div>
					{JSON.stringify(filters) !== JSON.stringify(defaultFilters) && (
						<div className="border-b border-neutral-300 py-2 flex px-2 gap-2 flex-wrap">
							{!filters.type.provider && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Proveedores ocultos</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												type: {
													...filters.type,
													provider: defaultFilters.type.provider,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{!filters.type.inventory && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Productos ocultos</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												type: {
													...filters.type,
													inventory: defaultFilters.type.inventory,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{!filters.status.pending && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Pendientes ocultas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												status: {
													...filters.status,
													pending: defaultFilters.status.pending,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{filters.status.done && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Terminadas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												status: {
													...filters.status,
													done: defaultFilters.status.done,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{filters.status.dismissed && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Borradas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												status: {
													...filters.status,
													dismissed: defaultFilters.status.dismissed,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{filters.date.from && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Desde {filters.date.from}</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												date: {
													...filters.date,
													from: defaultFilters.date.from,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{filters.date.to && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Hasta {filters.date.to}</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												date: {
													...filters.date,
													to: defaultFilters.date.to,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{!filters.priority.info && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Información ocultas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												priority: {
													...filters.priority,
													info: defaultFilters.priority.info,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{!filters.priority.warning && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Advertencia ocultas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												priority: {
													...filters.priority,
													warning: defaultFilters.priority.warning,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
							{!filters.priority.error && (
								<div className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-full px-2 hover:bg-green-500/30 flex gap-2 text-xs">
									<span>Error ocultas</span>
									<button
										onClick={() =>
											setFilters({
												...filters,
												priority: {
													...filters.priority,
													error: defaultFilters.priority.error,
												},
											})
										}
									>
										<TbX className="stroke-[3]" />
									</button>
								</div>
							)}
						</div>
					)}

					{notifications.length === 0 ? (
						<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
							<TbInboxOff className="h-40 w-40 text-neutral-400 mx-auto" />
							<p className="text-neutral-600 text-center mt-4">
								No tienes notificaciones
							</p>
						</div>
					) : (
						notifications.map((val) => (
							<div key={val._id}>
								<div className="flex p-2 border-b border-neutral-300 items-center justify-between hover:bg-neutral-50">
									<div className="flex gap-4 items-center">
										{val.priority === "info" && (
											<div className="p-1 bg-blue-300/50 rounded-md">
												<TbInfoCircle className="text-blue-500 h-6 w-6" />
											</div>
										)}
										{val.priority === "warning" && (
											<div className="p-1 bg-orange-300/50 rounded-md">
												<TbAlertTriangle className="text-orange-500 h-6 w-6" />
											</div>
										)}
										{val.priority === "error" && (
											<div className="p-1 bg-red-300/50 rounded-md">
												<TbExclamationCircle className="text-red-500 h-6 w-6" />
											</div>
										)}
										<div className="flex flex-col gap-1">
											<p className="text-neutral-500 text-sm">
												<span>{val.date.split("T")[0]}</span>
											</p>
											<p>{val.description}</p>
										</div>
									</div>
									<div className="text-right text-neutral-500 flex gap-4 items-center">
										{val.steps?.length > 0 && (
											<button onClick={() => toggleExpand(val._id)}>
												{expandedIds.includes(val._id) ? (
													<TbChevronUp className="text-neutral-500 h-5 w-5" />
												) : (
													<TbChevronDown className="text-neutral-500 h-5 w-5" />
												)}
											</button>
										)}
										{val.status === "pending" && (
											<button
												onClick={async () => {
													setNotifications(
														notifications.filter(
															(notification) =>
																notification._id !== val._id
														)
													)
													await setNotificationsAsCanceled(val._id)
													dispatch(
														syncNotifications(
															await getAllNotificationsByFilter({
																type: {
																	provider: true,
																	inventory: true,
																},
																status: { pending: true },
															})
														)
													)
												}}
											>
												<TbCircleOff className="h-5 w-5 text-red-400 hover:text-red-500" />
											</button>
										)}
									</div>
								</div>
								{expandedIds.includes(val._id) && val.steps?.length > 0 && (
									<div className="py-2 pl-10 border-b border-neutral-300">
										<p className="text-sm font-semibold">Correcciones:</p>
										{val.steps.map((step) => (
											<div
												key={step._id}
												className="mt-2 flex justify-between gap-12"
											>
												<p className="w-full"> - {step.description}</p>
												{step.completed ? (
													<p className="text-green-500">Completado</p>
												) : (
													<p className="text-red-500">Pendiente</p>
												)}
												<button
													className="bg-green-500/20 text-green-700 border border-green-500/25 rounded-lg px-4 hover:bg-green-500/30 mx-2"
													onClick={() =>
														handleStepCompletion(val._id, step._id)
													}
												>
													&gt;
												</button>
											</div>
										))}
									</div>
								)}
							</div>
						))
					)}
				</section>
			</main>
		</>
	)
}
