import propTypes from "prop-types"
import { TbLock } from "react-icons/tb"
import { useNavigate } from "react-router-dom"
import { cn } from "../../../../utils/twMerge"
import Button from "../../Buttons/Button"

export default function ForbiddenPopUp({ className }) {
	const navigate = useNavigate()

	return (
		<div
			className={cn(
				"flex flex-col gap-8 px-12 py-8 rounded-lg bg-neutral-100 border border-neutral-200 shadow",
				className
			)}
		>
			<div className="rounded-full px-3 py-1 bg-neutral-300 flex items-center gap-1 w-min">
				<TbLock />
				Standard
			</div>
			<h1 className="text-2xl">¡Actualiza tu plan de Bucks!</h1>
			<p className="text-neutral-700">
				Para usar esta funcionalidad necesita mínimo un{" "}
				<span className="text-black font-bold">Plan Standard</span>. Actualiza tu plan para
				disfrutar de estas funcionalidades y muchas más.
			</p>
			<Button
				text="Ver planes"
				onClick={() => navigate("/profile", { state: { scrollTo: "plans" } })}
			/>
		</div>
	)
}

ForbiddenPopUp.propTypes = {
	className: propTypes.string,
}
