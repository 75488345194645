import { useEffect, useState, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import moment from "moment"
import propTypes from "prop-types"
import PrivatePageLayout from "../../layouts/PrivatePageLayout"

// Import components
import Button from "../../common/Buttons/Button"
import { PayPopUp } from "../../common/popUps/payPopUp/payPopUp"
import { FaMoneyCheck } from "react-icons/fa"
import { GoHistory } from "react-icons/go"
import { TableComponent } from "../../common/tableComponent/tableComponent"
import { PaymentHistoryPopUp } from "../../common/popUps/paymentsHistoryPopUp/paymentHistoryPopUp"
import { ToastContainer } from "react-toastify"

// Import Services
import { getInvoiceUploadedByVendorId } from "../../../services/invoice"
import { getTransactionEntityById } from "../../../services/transactionEntity"

// Import Utils
import { formatPrice } from "../../../utils/helpers"

// Import Styles
import "./actualInventory.css"
import Header from "../../common/Header/Header"

const ActionButtons = ({ item }) => {
	const [showAbonarPopUp, setShowAbonarPopUp] = useState(false)
	const [showHistoryPopUp, setShowHistoryPopUp] = useState(false)
	return (
		<div className="flex gap-1">
			<PayPopUp
				show={showAbonarPopUp}
				data={item}
				onClose={() => setShowAbonarPopUp(false)}
			/>

			<PaymentHistoryPopUp
				show={showHistoryPopUp}
				data={item}
				onClose={() => setShowHistoryPopUp(false)}
			/>

			<div className="tooltip">
				<Button
					svg={<FaMoneyCheck />}
					onClick={(e) => {
						e.stopPropagation()
						setShowAbonarPopUp(true)
					}}
				/>
				<span className="tooltiptext">Abonar</span>
			</div>
			<div className="tooltip">
				<Button
					svg={<GoHistory />}
					onClick={(e) => {
						e.stopPropagation()
						setShowHistoryPopUp(true)
					}}
				/>
				<span className="tooltiptext">Historial</span>
			</div>
			<ToastContainer />
		</div>
	)
}

ActionButtons.propTypes = {
	item: propTypes.object.isRequired,
}

const ActualInventory = () => {
	const [data, setData] = useState([])
	const [originalData, setOriginalData] = useState([])
	const { id } = useParams()
	const firstLoad = useRef(true)

	const navigation = useNavigate()

	const [filter, setFilter] = useState({
		contado: false,
		credito: false,
		vencidas: false,
		porVencer: false,
		pagadas: false,
		from: "",
		to: "",
	})

	const handleFilterChange = (e) => {
		const { name, value, type, checked } = e.target
		setFilter((prev) => ({
			...prev,
			[name]: type === "checkbox" ? checked : value,
		}))
	}

	const isAnyFilterSelected = () => {
		const { contado, credito, vencidas, porVencer, pagadas, from, to } = filter
		return contado || credito || vencidas || porVencer || pagadas || from !== "" || to !== ""
	}

	const getFilteredData = (dataToFilter) => {
		if (!isAnyFilterSelected()) {
			return dataToFilter
		}
		console.log(filter)
		return dataToFilter.filter((item) => {
			const itemDate = moment(item.date) // Usar la fecha original en lugar de la formateada
			if (filter.contado && item.longTermPayment) return false // Verifica directamente en `longTermPayment`
			if (filter.credito && !item.longTermPayment) return false
			if (filter.vencidas && itemDate.isAfter(moment(), "day")) return false
			if (
				filter.porVencer &&
				!itemDate.isBetween(moment(), moment().add(7, "days"), "day", "[]")
			)
				return false
			if (filter.pagadas && item.remaining > 0) return false // Usar `remaining` original, no formateado
			if (filter.from && itemDate.isBefore(moment(filter.from), "day")) return false
			if (filter.to && itemDate.isAfter(moment(filter.to), "day")) return false
			return true
		})
	}
	const getConsolidatedData = async () => {
		try {
			const response = await getInvoiceUploadedByVendorId(id)

			// Guardar los datos originales sin transformarlos
			setOriginalData(response)

			// Transforma los datos para mostrarlos en la tabla
			const transformedData = response.map((item) => ({
				"Nit": item.nit,
				"Razón Social": item.name,
				"Factura": item.invoice,
				"Fecha": moment(item.date).format("DD/MM/YYYY"),
				"Total": formatPrice(item.total),
				"Retenciones": formatPrice(item.tax),
				"Valor a cancelar": formatPrice(item.net),
				"Abonos": formatPrice(item.deposits),
				"Restante": formatPrice(item.remaining),
				"Tipo de pago": item.longTermPayment ? "Credito" : "Contado",
				"erpName": item.erpName,
			}))

			setData(getFilteredData(transformedData))
		} catch (error) {
			console.error("Error getting consolidated data:", error)
		}
	}

	useEffect(() => {
		if (firstLoad.current) {
			getConsolidatedData()
			firstLoad.current = false
		}
	}, [])

	useEffect(() => {
		setData(
			getFilteredData(originalData).map((item) => ({
				"Nit": item.nit,
				"Razón Social": item.name,
				"Factura": item.invoice,
				"Fecha": moment(item.date).format("DD/MM/YYYY"),
				"Total": formatPrice(item.total),
				"Retenciones": formatPrice(item.tax),
				"Valor a cancelar": formatPrice(item.net),
				"Abonos": formatPrice(item.deposits),
				"Restante": formatPrice(item.remaining),
				"Tipo de pago": item.longTermPayment ? "Credito" : "Contado",
				"erpName": item.erpName,
			}))
		)
	}, [filter, originalData])

	// Encuentra el item original con el mismo invoiceId
	const findOriginalItem = (invoiceId) => {
		return originalData.find((item) => item.invoice === invoiceId.Factura)
	}

	const onClick = async (item) => {
		try {
			const realItem = findOriginalItem(item)
			const transactionEntity = await getTransactionEntityById(realItem.entity)
			console.log(realItem)
			navigation(
				`/get-report/${transactionEntity.extension}/${transactionEntity.blobID}/${transactionEntity._id}`
			)
		} catch (error) {
			console.error("Error getting transaction entity:", error)
		}
	}

	return (
		<>
			<Header />
			<div className="h-20" />
			<div className="custom-container">
				<div className="holder">
					<div className="workflows">
						<div className="page-header">
							<h1>Información Consolidada</h1>
						</div>
						<div className="filters">
							<h2>Filtrar</h2>
							<div className="date-filters">
								<label>
									Desde
									<input
										type="date"
										name="from"
										value={filter.from}
										onChange={handleFilterChange}
									/>
								</label>
								<label>
									Hasta
									<input
										type="date"
										name="to"
										value={filter.to}
										onChange={handleFilterChange}
									/>
								</label>
							</div>
							<div className="checkbox-filters">
								<label>
									<input
										type="checkbox"
										name="contado"
										checked={filter.contado}
										onChange={handleFilterChange}
									/>
									<span className="checkmark"></span>
									Contado
								</label>
								<label>
									<input
										type="checkbox"
										name="credito"
										checked={filter.credito}
										onChange={handleFilterChange}
									/>
									<span className="checkmark"></span>
									Crédito
								</label>
								<label>
									<input
										type="checkbox"
										name="vencidas"
										checked={filter.vencidas}
										onChange={handleFilterChange}
									/>
									<span className="checkmark"></span>
									Vencidas
								</label>
								<label>
									<input
										type="checkbox"
										name="porVencer"
										checked={filter.porVencer}
										onChange={handleFilterChange}
									/>
									<span className="checkmark"></span>
									Por Vencer
								</label>
								<label>
									<input
										type="checkbox"
										name="pagadas"
										checked={filter.pagadas}
										onChange={handleFilterChange}
									/>
									<span className="checkmark"></span>
									Pagadas
								</label>
							</div>
						</div>
						{data.length === 0 ? (
							<p>No se encontró información consolidada</p>
						) : (
							<div className="consolidate-data">
								<TableComponent
									data={data}
									onClick={onClick}
									ActionComponent={(props) => {
										const originalItem = findOriginalItem(props.item)
										return <ActionButtons {...props} item={originalItem} />
									}}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	)
}

export default ActualInventory
